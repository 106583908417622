import React from 'react';
import { Page, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import './Config.scss';
import { Link } from 'react-router-dom';

function Config() {
  const { t } = useTranslation();

  return (
    <Page className="config-wrapper">
      <PageTitle className="page-title">{t('시스템 설정')}</PageTitle>
      <PageContent>
        <ul>
          <li>
            <Link to="/configs/holidays">
              <div>
                <div className="icon">
                  <i className="fal fa-gifts" />
                </div>
                <div className="text">휴일 관리</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/configs/boards">
              <div>
                <div className="icon">
                  <i className="fal fa-bullhorn" />
                </div>
                <div className="text">공지 관리</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/configs/cs">
              <div>
                <div className="icon">
                  <i className="fal fa-bullhorn" />
                </div>
                <div className="text">문의사항 관리</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/configs/system">
              <div>
                <div className="icon">
                  <i className="fal fa-server" />
                </div>
                <div className="text">시스템 관리</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/configs/links">
              <div>
                <div className="icon">
                  <i className="fal fa-server" />
                </div>
                <div className="text">링크</div>
              </div>
            </Link>
          </li>
        </ul>
      </PageContent>
    </Page>
  );
}

export default Config;
