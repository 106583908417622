import React, { useEffect, useState } from 'react';
import { Block, BlockRow, CheckBox, Form, Input, Label, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import BoardService from '@/services/BoardService';
import './EditBoard.scss';
import TextArea from '@/components/TextArea/TextArea';
import moment from 'moment';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';

const labelMinWidth = '100px';

function EditBoard({ type }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const [board, setBoard] = useState({
    id: null,
    subject: '',
    content: '',
    enabled: true,
    noticeYn: false,
    closingDate: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && type === 'edit') {
      BoardService.getBoard(id, info => {
        const next = { ...info };
        if (next.closingDate) {
          next.closingDate = moment(info.closingDate, 'YYYY-MM-DD').format('YYYYMMDD');
        }
        setBoard(next);
      });
    }
  }, [type, id]);

  const onSubmit = e => {
    e.preventDefault();

    if (board.noticeYn && !board.closingDate) {
      dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '게시 마감일 미등록', '공지 글의 경우 공지 마감일을 입력해야합니다.');
      return;
    }

    if (board.closingDate) {
      if (!moment(board.closingDate, 'YYYYMMDD').isValid()) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '날짜 형식 오류', '마감일을 YYYYMMDD 형식으로 입력해주세요.');
        return;
      }
    }

    const next = {
      ...board,
    };

    if (board.closingDate) {
      next.closingDate = moment(board.closingDate, 'YYYYMMDD').format('YYYY-MM-DD');
    }

    if (type === 'new') {
      BoardService.createBoard(next, () => {
        navigate('/configs/boards');
      });
    } else if (type === 'edit') {
      BoardService.updateBoard(id, next, () => {
        navigate('/configs/boards');
      });
    }
  };

  return (
    <Page className="edit-board-wrapper">
      <PageTitle>{t('새 글')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="block">
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('제목')}
              </Label>
              <Input
                type="text"
                size="md"
                placeholder="제목을 입력해주세요."
                value={board.subject}
                onChange={val =>
                  setBoard({
                    ...board,
                    subject: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('내용')}
              </Label>
              <TextArea
                value={board.content}
                rows={12}
                onChange={val => {
                  setBoard({
                    ...board,
                    content: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('마감일')}
              </Label>
              <Input
                type="text"
                size="md"
                placeholder="YYYYMMDD"
                value={board.closingDate}
                onChange={val =>
                  setBoard({
                    ...board,
                    closingDate: val,
                  })
                }
                minLength={8}
                maxLength={8}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('활성화')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={board.enabled}
                onChange={val =>
                  setBoard({
                    ...board,
                    enabled: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('공지')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={board.noticeYn}
                onChange={val =>
                  setBoard({
                    ...board,
                    noticeYn: val,
                  })
                }
              />
            </BlockRow>
          </Block>
          <PageButtons
            onCancel={() => {
              navigate('/configs/boards');
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditBoard.defaultProps = {
  type: 'new',
};

EditBoard.propTypes = {
  type: PropTypes.string,
};

export default EditBoard;
