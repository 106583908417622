import * as request from '@/utils/request';

const BatchService = {};

BatchService.selectJobList = (successHandler, failHandler) => {
  return request.get(
    '/api/batches/jobs',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.selectJobInfo = (jobId, successHandler, failHandler) => {
  return request.get(
    `/api/batches/jobs/${jobId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.createJobInfo = (job, successHandler, failHandler) => {
  return request.post(
    '/api/batches/jobs',
    job,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.updateJobInfo = (jobId, job, successHandler, failHandler) => {
  return request.put(
    `/api/batches/jobs/${jobId}`,
    job,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.deleteJobInfo = (jobId, successHandler, failHandler) => {
  return request.del(
    `/api/batches/jobs/${jobId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.executeJobInfo = (jobId, successHandler, failHandler) => {
  return request.post(
    `/api/batches/jobs/${jobId}/execute`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.selectBatchHistoryList = (successHandler, failHandler) => {
  return request.get(
    '/api/batches/histories',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.selectBatchJobHistoryList = (jobId, batchStatus, lastId, successHandler, failHandler) => {
  return request.get(
    `/api/batches/jobs/${jobId}/histories`,
    { batchStatus, lastId },
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.selectBatchHistoryInfo = (historyId, successHandler, failHandler) => {
  return request.get(
    `/api/batches/histories/${historyId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BatchService.deleteBatchHistoryInfo = (historyId, successHandler, failHandler) => {
  return request.del(
    `/api/batches/histories/${historyId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default BatchService;
