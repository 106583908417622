import { createContext } from 'react';
import UserStore from '@/stores/UserStore';
import ControlStore from '@/stores/ControlStore';
import ConditionStore from '@/stores/ConditionStore';
import ConfigStore from '@/stores/ConfigStore';

class RootStore {
  constructor() {
    this.userStore = new UserStore();
    this.controlStore = new ControlStore();
    this.conditionStore = new ConditionStore();
    this.configStore = new ConfigStore();
  }
}

const rootStore = new RootStore();

const rootStoreContext = createContext(rootStore);

export { rootStoreContext, rootStore };
