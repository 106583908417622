import React from 'react';
import PropTypes from 'prop-types';
import './PageContent.scss';

function PageContent({ className, children, border, padding }) {
  return <div className={`page-content-wrapper ${className} ${border ? 'has-border' : ''} ${padding ? 'has-padding' : ''}`}>{children}</div>;
}

PageContent.defaultProps = {
  className: '',
  border: false,
  children: '',
  padding: false,
};

PageContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  border: PropTypes.bool,
  padding: PropTypes.bool,
};

export default PageContent;
