import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import UserService from '@/services/UserService';
import moment from 'moment';

const labelMinWidth = '100px';

function User() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    UserService.getAdminUserInfo(id, info => {
      setUser(info);
    });
  }, []);

  const deleteUser = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '사용자 데이터 삭제',
      <div>
        <div>
          <span className="g-tag">{user.name}</span>
        </div>
        <div>사용자 및 모든 관련 정보를 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        UserService.getDeleteUserInfo(id, () => {
          navigate('/configs/users');
        });
      },
      () => {},
    );
  };

  return (
    <Page className="user-wrapper">
      <PageTitle className="page-title" links={[<Link to="/configs/users">목록</Link>]}>
        {user.name}
      </PageTitle>
      <PageContent padding>
        <Block>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('이름')}
            </Label>
            <Text>{user.name}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('벤더')}
            </Label>
            <Text>{user.vendor}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('벤더 이메일')}
            </Label>
            <Text>{user.vendorEmail}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('UUID')}
            </Label>
            <Text>{user.uuid}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('IP ADDRESS')}
            </Label>
            <Text>{user.ipAddress}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('이메일')}
            </Label>
            <Text>{user.email}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('전화번호')}
            </Label>
            <Text>{user.phone}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('지역')}
            </Label>
            <Text>{user.country}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('언어')}
            </Label>
            <Text>{user.language}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('권한')}
            </Label>
            <Text>{user.roleCode}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('적용 권한')}
            </Label>
            <Text>{user.activeRoleCode}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('회원 등급')}
            </Label>
            <Text>{user.grade}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('활성화')}
            </Label>
            <Text>{user.useYn ? 'Y' : 'N'}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('마지막 로그인')}
            </Label>
            <Text>{user.lastLoginDate ? moment(user.lastLoginDate).format('YYYY년 MM월 DD일 HH시mm분') : ''}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('로그인 횟수')}
            </Label>
            <Text>{user.loginCount}</Text>
          </BlockRow>
        </Block>
        <PageButtons
          onDelete={deleteUser}
          onList={() => {
            navigate('/configs/users');
          }}
          onEdit={() => {
            navigate(`/configs/users/${id}/edit`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default User;
