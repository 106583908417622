import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import useStores from '@/hooks/useStores';
import { getOption, setOption } from '@/utils/storageUtil';
import ExitButton from '../ExitButton/ExitButton';

import './UserGrade.scss';

function UserGrade({ className }) {
  const {
    userStore: { isLogin, grade },
  } = useStores();

  const [skip, setSkip] = useState(getOption('user', 'info', 'skip-user-grade'));

  return (
    <div className={`user-grade-wrapper ${className} ${grade} ${skip || grade === 'GOLD' ? 'skip' : ''}`}>
      <div className="message">
        {!isLogin && (
          <div>
            <div>미인증 회원 (최대 10분 지연)</div>
            <div>
              <Link to="/users/login">로그인</Link> 하시면 보다 최신의 데이터를 확인할 수 있습니다.
            </div>
          </div>
        )}
        {isLogin && grade === 'SILVER' && (
          <div>
            <div>실버 회원 (최대 5분 지연)</div>
            <div>
              <Link to="/users/grade">골드 회원</Link>이 되시면 실시간 데이터를 확인할 수 있습니다.
            </div>
          </div>
        )}
      </div>
      <div className="grade-button">
        <ExitButton
          onClick={() => {
            setOption('user', 'info', 'skip-user-grade', true);
            setSkip(true);
          }}
        />
      </div>
    </div>
  );
}

UserGrade.defaultProps = {
  className: '',
};

UserGrade.propTypes = {
  className: PropTypes.string,
};

export default observer(UserGrade);
