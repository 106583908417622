import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import BatchService from '@/services/BatchService';
import moment from 'moment';
import './BatchHistory.scss';

const labelMinWidth = '100px';

function BatchHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jobId, historyId } = useParams();

  const [batchHistory, setBatchHistory] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    BatchService.selectBatchHistoryInfo(historyId, info => {
      setBatchHistory(info);
    });
  }, [historyId]);

  const deleteBatchHistory = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '배치 이력 삭제',
      <div>
        <div>배치 이력을 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        BatchService.deleteBatchHistoryInfo(historyId, () => {
          navigate(`/batches/${jobId}/histories`);
        });
      },
    );
  };

  return (
    <Page className="batch-history-wrapper">
      <PageTitle className="page-title" links={[<Link to={`/batches/${jobId}/histories`}>배치 이력 목록</Link>]}>
        {batchHistory.batchStatus}
      </PageTitle>
      <PageContent padding>
        <Block>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 클래스 명')}
            </Label>
            <Text>{batchHistory.batchClassName}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 시작 시간')}
            </Label>
            <Text>{moment(batchHistory.batchStartTime).format('YYYY-MM-DD HH:mm:ss')}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 종료 시간')}
            </Label>
            <Text>{moment(batchHistory.batchEndTime).format('YYYY-MM-DD HH:mm:ss')}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('수행시간')}
            </Label>
            <Text>{batchHistory.duration}초</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('처리건수')}
            </Label>
            <Text>{batchHistory.batchCount}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('결과')}
            </Label>
            <Text>{batchHistory.batchStatus}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('메세지')}
            </Label>
            <Text className="message">{batchHistory.batchMessage}</Text>
          </BlockRow>
        </Block>
        <PageButtons
          onDelete={deleteBatchHistory}
          onList={() => {
            navigate(`/batches/${jobId}/histories`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default BatchHistory;
