import React, { useEffect, useState } from 'react';
import { Block, BlockRow, CheckBox, Form, Input, Label, Page, PageButtons, PageContent, PageTitle, RadioButton } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import dialogUtil from '@/utils/dialogUtil';
import { COUNTRIES, LANGUAGES, MESSAGE_CATEGORY, USER_GRADE, USER_ROLE } from '@/constants/constants';
import UserService from '@/services/UserService';
import './EditUser.scss';

const labelMinWidth = '100px';

function EditUser() {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const [user, setUser] = useState({
    id: '',
    email: '',
    name: '',
    phone: '',
    country: '',
    language: '',
    roleCode: '',
    activeRoleCode: '',
    grade: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      UserService.getAdminUserInfo(id, info => {
        setUser(info);
      });
    }
  }, [id]);

  const onSubmit = e => {
    e.preventDefault();

    if (user.phone) {
      const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      if (!regPhone.test(user.phone)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '전화번호 형식 오류', '000-0000-0000 형식의 전화번호를 입력해주세요');
        return;
      }
    }

    UserService.updateAdminUserInfo(id, user, () => {
      navigate('/configs/users');
    });
  };

  return (
    <Page className="edit-user-wrapper">
      <PageTitle>{t('사용자 정보 변경')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="pt-0">
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm" required>
                {t('이름')}
              </Label>
              <Input
                type="text"
                size="md"
                value={user.name}
                onChange={val =>
                  setUser({
                    ...user,
                    name: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('이메일')}
              </Label>
              <Input
                value={user.email}
                onChange={val =>
                  setUser({
                    ...user,
                    email: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('전화번호')}
              </Label>
              <Input
                value={user.phone}
                onChange={val =>
                  setUser({
                    ...user,
                    phone: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow className="language">
              <Label size="sm" minWidth={labelMinWidth}>
                {t('언어')}
              </Label>
              <RadioButton
                className="radio"
                size="sm"
                items={Object.keys(LANGUAGES).map(key => {
                  return { key, value: LANGUAGES[key] };
                })}
                value={user?.language}
                onClick={val => {
                  setUser({
                    ...user,
                    language: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow className="location">
              <Label size="sm" minWidth={labelMinWidth}>
                {t('지역')}
              </Label>
              <RadioButton
                className="radio"
                size="sm"
                items={Object.keys(COUNTRIES).map(key => {
                  return { key, value: COUNTRIES[key] };
                })}
                value={user?.country}
                onClick={val => {
                  setUser({
                    ...user,
                    country: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('권한')}
              </Label>
              <RadioButton
                className="radio"
                size="sm"
                items={Object.keys(USER_ROLE).map(key => {
                  return { key, value: USER_ROLE[key] };
                })}
                value={user?.roleCode}
                onClick={val => {
                  setUser({
                    ...user,
                    roleCode: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('적용 권한')}
              </Label>
              <RadioButton
                className="radio"
                size="sm"
                items={Object.keys(USER_ROLE).map(key => {
                  return { key, value: USER_ROLE[key] };
                })}
                value={user?.activeRoleCode}
                onClick={val => {
                  setUser({
                    ...user,
                    activeRoleCode: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('회원 등급')}
              </Label>
              <RadioButton
                className="radio"
                size="sm"
                items={Object.keys(USER_GRADE).map(key => {
                  return { key, value: USER_GRADE[key] };
                })}
                value={user?.grade}
                onClick={val => {
                  setUser({
                    ...user,
                    grade: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('활성화')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={user.useYn}
                onChange={val =>
                  setUser({
                    ...user,
                    useYn: val,
                  })
                }
              />
            </BlockRow>
          </Block>
          <PageButtons
            onCancel={() => {
              navigate('/configs/users');
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditUser.defaultProps = {};

EditUser.propTypes = {};

export default EditUser;
