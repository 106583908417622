import React from 'react';
import { Page, PageButtons, PageContent, PageTitle } from '@/components';
import { Link, useNavigate } from 'react-router-dom';

function OAuthFail() {
  const navigate = useNavigate();

  return (
    <Page className="oauth-fail-wrapper">
      <PageTitle className="page-title">로그인 오류</PageTitle>
      <PageContent padding>
        <p>간편 로그인이 정상적으로 처리되지 않았습니다.</p>
        <p>
          잠시 후 다시 시도해보시거나, 현상이 지속되면 <Link to="/users/cs">고객 센터</Link>를 통해 문의 부탁드립니다.
        </p>
        <PageButtons
          onBack={() => {
            navigate(-1);
          }}
          onList={() => {
            navigate('/');
          }}
          onListText="메인"
        />
      </PageContent>
    </Page>
  );
}

export default OAuthFail;
