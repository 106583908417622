import * as request from '@/utils/request';

const BoardService = {};

BoardService.getBoards = (successHandler, failHandler) => {
  return request.get(
    '/api/boards',
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BoardService.getNotices = (lastSeen, successHandler, failHandler) => {
  return request.get(
    '/api/boards/notices',
      lastSeen,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BoardService.getBoard = (id, successHandler, failHandler) => {
  return request.get(
    `/api/boards/${id}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BoardService.deleteBoard = (id, successHandler, failHandler) => {
  return request.del(
    `/api/boards/${id}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BoardService.createBoard = (holiday, successHandler, failHandler) => {
  return request.post(
    '/api/boards',
    holiday,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

BoardService.updateBoard = (id, holiday, successHandler, failHandler) => {
  return request.put(
    `/api/boards/${id}`,
    holiday,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default BoardService;
