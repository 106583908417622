import React, { useEffect, useRef, useState } from 'react';
import { BatchHistoryList, Page, PageContent, PageTitle, Tabs } from '@/components';
import { Link, useNavigate } from 'react-router-dom';
import './BatchHistories.scss';
import BatchService from '@/services/BatchService';
import { useParams } from 'react-router';
import { debounce } from 'lodash';

const tabs = [
  {
    key: '',
    value: '전체',
  },
  {
    key: 'DONE',
    value: '완료',
  },
  {
    key: 'ERROR',
    value: '에러',
  },
  {
    key: 'STARTED',
    value: '시작됨',
  },
  {
    key: 'TERMINATED',
    value: '중지됨',
  },
];

function BatchHistories() {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [batchJob, setBatchJob] = useState({});
  const [lastId, setLastId] = useState(null);
  const [batchHistories, setBatchHistories] = useState([]);
  const [tab, setTab] = useState('');
  const element = useRef(null);

  useEffect(() => {
    BatchService.selectJobInfo(jobId, info => {
      setBatchJob(info);
    });

    BatchService.selectBatchJobHistoryList(jobId, tab, null, list => {
      setBatchHistories(list);
      if (list && list.length > 20) {
        setLastId(list[list.length - 1].id);
      } else {
        setLastId(null);
      }
    });
  }, [jobId, tab]);

  const onScroll = () => {
    if (lastId && element?.current) {
      if (element.current.offsetHeight - (window.scrollY + window.innerHeight) < 100) {
        BatchService.selectBatchJobHistoryList(jobId, tab, lastId, list => {
          const next = batchHistories.slice(0);
          setBatchHistories(next.concat(list));
          if (list && list.length > 20) {
            setLastId(list[list.length - 1].id);
          } else {
            setLastId(null);
          }
        });
      }
    }
  };

  const onScrollDebounced = React.useMemo(() => debounce(onScroll, 100), [lastId, batchHistories]);

  useEffect(() => {
    document.addEventListener('scroll', onScrollDebounced);
    return () => {
      onScrollDebounced.cancel();
      document.removeEventListener('scroll', onScrollDebounced);
    };
  });

  return (
    <Page className="batch-histories-wrapper">
      <PageTitle className="page-title" links={[<Link to="/batches">배치 잡 목록</Link>]}>
        <div className="text">
          <div className="batch-job-name">{batchJob.batchJobName}</div>
          <div className="title-label">배치 이력</div>
        </div>
      </PageTitle>
      <PageContent padding>
        <div ref={element}>
          <Tabs className="tab" tabs={tabs} value={tab} onSelect={setTab} />
          <BatchHistoryList
            batchHistories={batchHistories}
            onClick={historyId => {
              navigate(`/batches/${jobId}/histories/${historyId}`);
            }}
          />
        </div>
      </PageContent>
    </Page>
  );
}

export default BatchHistories;
