import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Club, Clubs, EditClub, Message } from '@/pages';

function ClubsRoutes() {
  return (
    <Routes>
      <Route path="/new" element={<EditClub />} />
      <Route path="/:id/edit" element={<EditClub type="edit" />} />
      <Route path="/:id" element={<Club />} />
      <Route path="/" element={<Clubs />} />
      <Route path="*" element={<Message code="404" />} />
    </Routes>
  );
}

export default ClubsRoutes;
