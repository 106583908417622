import React, { useEffect, useRef, useState } from 'react';
import { Block, BlockRow, Form, Info, Input, Label, Liner, Logo, Page, PageButtons, PageContent, Text, Title } from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import useStores from '@/hooks/useStores';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import dialogUtil from '@/utils/dialogUtil';
import './OAuthRegister.scss';
import { getOption } from '@/utils/storageUtil';

const labelMinWidth = '100px';

function OAuthRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tel2 = useRef(null);
  const tel3 = useRef(null);

  const [userInfo, setUserInfo] = useState({});

  const {
    userStore: { user },
  } = useStores();

  useEffect(() => {
    if (user.id) {
      UserService.getUserInfo(user.id, info => {
        let tel1No = '';
        let tel2No = '';
        let tel3No = '';
        if (info.phone) {
          const tels = info.phone.split('-');
          if (tels?.length === 3) {
            [tel1No, tel2No, tel3No] = tels;
          }
        }

        setUserInfo({ ...info, password: '', passwordConfirm: '', tel1: tel1No, tel2: tel2No, tel3: tel3No, recommendationInfo: getOption('user', 'info', 'recommendation') || '' });
      });
    }
  }, [user]);

  const onSubmit = e => {
    e.preventDefault();

    const next = { ...userInfo };

    if (!next.hasEmail) {
      if (userInfo.email && (!next.password || !next.passwordConfirm)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '비밀번호 필요', '입력하신 이메일로 로그인하기 위한 비밀번호를 입력해주세요.');
        return;
      }

      if (next.password && next.passwordConfirm && next.password !== next.passwordConfirm) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '비밀번호 불일치', '입력하신 비밀번호가 일치하지 않습니다.');
        return;
      }
    }

    if (next.tel1 || next.tel2 || next.tel3) {
      next.phone = `${next.tel1}-${next.tel2}-${next.tel3}`;
      const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      if (!regPhone.test(next.phone)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '전화번호 형식 오류', '올바른 전화번호 형식이 아닙니다.');
        return;
      }
    }

    UserService.updateOAuthUserInfo(user.id, next, () => {
      navigate('/');
    });
  };

  return (
    <Page className="oauth-register-wrapper">
      <PageContent padding>
        <div className="logo">
          <Logo size="sm" />
        </div>
        <Form className="form" onSubmit={onSubmit}>
          <div className="register-content">
            <Title className="title" type="h2">
              추가 정보 등록
            </Title>
            <Info className="info" icon={false}>
              추가적인 사용자 정보를 입력하여, 다양한 방법으로 공티에 로그인할 수 있습니다.
            </Info>
            <div className="skip-link">
              <Link to="/">지금 등록 안함</Link>
            </div>
            <Block className="block">
              <BlockRow className="social">
                <Label className="label social-label" minWidth={labelMinWidth} size="sm">
                  {t('소셜 로그인 계정')}
                </Label>
                <Text className="text" white>
                  {userInfo.vendor && <span className={`vendor ${userInfo.vendor}`}>{userInfo.vendor}</span>}
                  <span>{userInfo.vendorEmail}</span>
                </Text>
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이메일')}
                </Label>
                {userInfo.hasEmail && (
                  <Text white>
                    <span>{userInfo.email}</span>
                  </Text>
                )}
                {!userInfo.hasEmail && (
                  <Input
                    type="email"
                    size="md"
                    placeholder="이메일"
                    required
                    value={userInfo.email}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        email: val,
                      })
                    }
                    minLength={1}
                  />
                )}
              </BlockRow>
              {!userInfo.hasEmail && (
                <>
                  <BlockRow>
                    <Label size="sm" minWidth={labelMinWidth}>
                      {t('비밀번호')}
                    </Label>
                    <Input
                      type="password"
                      size="md"
                      placeholder="비밀번호"
                      required
                      value={userInfo.password}
                      onChange={val =>
                        setUserInfo({
                          ...userInfo,
                          password: val,
                        })
                      }
                      minLength={1}
                    />
                  </BlockRow>
                  <BlockRow>
                    <Label size="sm" minWidth={labelMinWidth}>
                      {t('비밀번호')}
                    </Label>
                    <Input
                      type="password"
                      size="md"
                      placeholder="비밀번호 확인"
                      required
                      value={userInfo.passwordConfirm}
                      onChange={val =>
                        setUserInfo({
                          ...userInfo,
                          passwordConfirm: val,
                        })
                      }
                      minLength={1}
                    />
                  </BlockRow>
                </>
              )}
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('닉네임')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  placeholder="닉네임"
                  required
                  value={userInfo.nickname}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      nickname: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이름')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  placeholder="이름"
                  required
                  value={userInfo.name}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      name: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('전화번호')}
                </Label>
                <div className="tel">
                  <Input
                    type="text"
                    className="tel-1"
                    size="md"
                    placeholder="000"
                    value={userInfo.tel1}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel1: val,
                      });

                      if (val?.length === 3 && tel2.current) {
                        tel2.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={3}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel2.current = e;
                    }}
                    type="text"
                    className="tel-2"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel2}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel2: val,
                      });

                      if (val?.length === 4 && tel3.current) {
                        tel3.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={4}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel3.current = e;
                    }}
                    type="text"
                    className="tel-3"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel3}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        tel3: val,
                      })
                    }
                    minLength={1}
                    maxLength={4}
                  />
                </div>
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('추천인 코드')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  placeholder="추천인 코드"
                  value={userInfo.recommendationInfo}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      recommendationInfo: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
            </Block>
            <PageButtons
              onList={() => {
                navigate('/');
              }}
              onListText="지금 안함"
              onSubmit={() => {}}
            />
            <div className="links">
              <Link
                to="/"
                onClick={e => {
                  e.preventDefault();
                  UserService.updateSkipOAuthUserInfo(user.id, () => {
                    navigate('/');
                  });
                }}
              >
                이 페이지를 다시 보지 않습니다.
              </Link>
            </div>
          </div>
        </Form>
      </PageContent>
    </Page>
  );
}

export default observer(OAuthRegister);
