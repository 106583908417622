import React from 'react';
import PropTypes from 'prop-types';
import '@/components/RequestProgress/RequestProgress.scss';
import GolfBall from '@/components/GolfBall/GolfBall';

function RequestProgress({ className, recommendClubRequestingInfo }) {
  return (
    <div className={`request-progress-wrapper progress ${className}`}>
      <div>
        <div className="title">티 정보 정보를 가져오고 있습니다.</div>
        <div className="info">
          <div className="ball">
            <GolfBall />
          </div>
          {recommendClubRequestingInfo.totalCount && (
            <div className="progress">
              <div className="count">
                <span className="number start">{recommendClubRequestingInfo.currentCount}</span>
                <span className="slash">/</span>
                <span className="number end">{recommendClubRequestingInfo.totalCount}</span>
              </div>
              <div className="bar">
                <div
                  className="item"
                  style={{
                    width: `${(recommendClubRequestingInfo.currentCount / recommendClubRequestingInfo.totalCount) * 100}%`,
                  }}
                />
                <div className="club">
                  <div>{recommendClubRequestingInfo.club}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

RequestProgress.defaultProps = {
  className: '',
  recommendClubRequestingInfo: {},
};

RequestProgress.propTypes = {
  className: PropTypes.string,
  recommendClubRequestingInfo: PropTypes.shape({
    totalCount: PropTypes.number,
    currentCount: PropTypes.number,
    club: PropTypes.string,
  }),
};

export default RequestProgress;
