import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NoticePropTypes } from '@/proptypes';
import './NoticePopup.scss';
import { ExitButton, Liner } from '@/components';
import moment from 'moment';

function NoticePopup({ className, notices, setOpen }) {
  const [notice, setNotice] = useState(null);

  useEffect(() => {
    if (notices?.length > 0) {
      setNotice(notices[0]);
    }
  }, [notices]);

  const inx = notices.findIndex(d => d.id === notice?.id);

  return (
    <div className={`notice-popup-wrapper ${className}`}>
      <div>
        <div>
          <div className="notice-header">
            {notices?.length > 1 && (
              <div className="count-index">
                <span>{inx + 1}</span>
                <span>/</span>
                <span>{notices?.length}</span>
              </div>
            )}
            <div className="subject">
              {notice?.subject}
              <div className="creation-date">{moment(notice?.creationDate).format('YYYY년 MM월 DD일')}</div>
            </div>
            <div className="exit-button">
              <ExitButton
                onClick={() => {
                  setOpen(false, false);
                }}
              />
            </div>
          </div>

          <div className="content" dangerouslySetInnerHTML={{ __html: notice?.content }} />
          {notices?.length > 0 && (
            <div className="buttons">
              <div className="ignore-all">
                <span
                  onClick={() => {
                    setOpen(false, true);
                  }}
                >
                  모두 다시보지 않기
                </span>
              </div>
              <div
                className="move"
                onClick={() => {
                  setOpen(false, false);
                }}
              >
                <span>닫기</span>
              </div>
              {inx > 0 && (
                <>
                  <Liner display="inline-block" width="1px" height="10px" color="light" margin="0" />
                  <div
                    className="move"
                    onClick={() => {
                      setNotice(notices[inx - 1]);
                    }}
                  >
                    이전
                  </div>
                </>
              )}
              {inx < notices.length - 1 && (
                <>
                  <Liner display="inline-block" width="1px" height="10px" color="light" margin="0" />
                  <div
                    className="move"
                    onClick={() => {
                      if (notices[inx + 1]) {
                        setNotice(notices[inx + 1]);
                      }
                    }}
                  >
                    다음
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

NoticePopup.defaultProps = {
  className: '',
  notices: null,
};

NoticePopup.propTypes = {
  className: PropTypes.string,
  notices: PropTypes.arrayOf(NoticePropTypes),
  setOpen: PropTypes.func.isRequired,
};

export default NoticePopup;
