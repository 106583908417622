import * as request from '@/utils/request';

const CustomerInquiryService = {};

CustomerInquiryService.createCustomerInquiry = (inquiry, successHandler, failHandler) => {
  return request.post(
    '/api/customer/inquiries',
    inquiry,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.selectMyCustomerInquiryList = (successHandler, failHandler) => {
  return request.get(
    '/api/customer/inquiries/my',
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.selectCustomerInquiryInfo = (inquiryId, successHandler, failHandler) => {
  return request.get(
    `/api/customer/inquiries/${inquiryId}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.createCustomerInquiryAnswer = (inquiryId, content, successHandler, failHandler) => {
  return request.post(
    `/api/customer/inquiries/${inquiryId}/answer`,
    content,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.createAdditionalCustomerInquiry = (inquiryId, content, successHandler, failHandler) => {
  return request.put(
    `/api/customer/inquiries/${inquiryId}`,
    content,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.selectAllCustomerInquiryList = (successHandler, failHandler) => {
  return request.get(
    '/api/customer/inquiries',
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

CustomerInquiryService.deleteCustomerInquiry = (inquiryId, successHandler, failHandler) => {
  return request.del(
    `/api/customer/inquiries/${inquiryId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default CustomerInquiryService;
