import React, { useEffect } from 'react';
import { Page, PageContent, PageTitle } from '@/components';
import './Links.scss';

function Links() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page className="links-wrapper">
      <PageTitle className="page-title">링크</PageTitle>
      <PageContent padding>
        <ul>
          <li>
            <a rel="noreferrer" href="https://analytics.google.com/analytics/web/?authuser=2#/p321757110/reports/intelligenthome?params=_u..nav%3Dmaui" target="_blank">
              구글 애널리틱스
            </a>
          </li>
          <li>
            <a rel="noreferrer" href="https://sentry.io/organizations/teamkim/issues/?project=6559580" target="_blank">
              SENTRY (API)
            </a>
          </li>
          <li>
            <a rel="noreferrer" href="https://sentry.io/organizations/teamkim/issues/?project=6559528" target="_blank">
              SENTRY (APP)
            </a>
          </li>
          <li>
            <a rel="noreferrer" href="https://developers.naver.com/apps/#/myapps/ecR3gqnQnEopGx9DdEZX/overview" target="_blank">
              네이버 검색 API 사용량 확인
            </a>
          </li>
        </ul>
      </PageContent>
    </Page>
  );
}

export default Links;
