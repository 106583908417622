import React, { useEffect, useState } from 'react';
import { Button, Page, PageContent, PageTitle } from '@/components';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import ConfigService from '@/services/ConfigService';
import './System.scss';
import { Link } from 'react-router-dom';

function System() {
  const [info, setInfo] = useState({});

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getInfo = () => {
    ConfigService.selectCodeList('SYSTEM', list => {
      setSettings(list);
    });

    ConfigService.selectSystemInfo(d => {
      const next = {};

      if (d.db0) {
        const list = d.db0.split(',');
        list.forEach(item => {
          if (item.indexOf('keys') >= 0) {
            const [, value] = item.split('=');
            next.keyCount = value;
          }
        });
      }

      next.maxMemory = d.maxmemory_human;
      next.peakMemory = d.used_memory_peak_human;
      next.usedMemory = d.used_memory_human;
      next.usedPercent = Math.round((d.used_memory / d.maxmemory) * 10000) / 100;
      next.peakPercent = Math.round((d.used_memory_peak / d.maxmemory) * 10000) / 100;
      setInfo(next);
    });
  };

  useEffect(() => {
    getInfo();
  }, []);

  const flushAllRedis = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '레디스 초기화',
      <div>
        <div>레디스에 저장된 모든 데이터가 삭제되고, 현재 로그인 중인 모든 사용자의 세션 정보가 삭제됩니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        ConfigService.deleteRedis(() => {
          window.location.href = '/';
        });
      },
    );
  };

  const deleteAllTees = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '캐시 데이터 삭제',
      <div>
        <div>레디스에 저장된 티, 날짜별 요약 정보, 실시간 캐시 정보, 클럽 캐시 데이터가 삭제됩니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        ConfigService.deleteRedisTees(() => {
          getInfo();
        });
      },
    );
  };

  return (
    <Page className="system-wrapper">
      <PageTitle className="page-title">시스템 관리</PageTitle>
      <PageContent padding>
        <div className="system-info-box">
          <div className="title">
            <div>설정 정보</div>
            <div>
              <Link to="/configs/system/codes/new">새 설정</Link>
            </div>
          </div>
          <div className="content">
            {settings.length < 1 && <div className="no-data">설정 정보가 없습니다.</div>}
            {settings.length > 0 &&
              settings.map(code => {
                return (
                  <div className="code" key={`${code.type}-${code.code}`}>
                    <div>
                      <Link to={`/configs/system/codes/${code.code}/types/${code.type}`}>{code.label}</Link>
                    </div>
                    <div>
                      <div>{code.value}</div>
                      <div className="description">{code.description}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="system-info-box">
          <div className="title">
            <div>레디스 정보</div>
            <div>
              <Button rounded type="button" size="sm" outline onClick={getInfo}>
                <i className="fal fa-retweet" />
              </Button>
            </div>
          </div>
          <div className="content">
            <div>
              <div>키 개수</div>
              <div>{info.keyCount}</div>
            </div>
            <div>
              <div>메모리 사용율</div>
              <div>
                {info.usedMemory} / {info.maxMemory} ({info.usedPercent}%)
              </div>
            </div>
            <div>
              <div>피크 메모리</div>
              <div>
                {info.peakMemory} ({info.peakPercent}%)
              </div>
            </div>
            <div className="management">
              <div>관리</div>
              <div>
                <div>
                  <Button type="button" size="xs" color="danger" onClick={flushAllRedis}>
                    레디스 초기화
                  </Button>
                  <div className="description">레디스의 모든 정보를 삭제합니다.</div>
                </div>
                <div>
                  <Button type="button" size="xs" color="danger" onClick={deleteAllTees}>
                    데이터 삭제
                  </Button>
                  <div className="description">티, 데일리 요약, 캐시 데이터를 삭제합니다.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </Page>
  );
}

export default System;
