function getShortPrice(price) {
  if (Number.isNaN(price)) {
    return price;
  }

  if (price > 0) {
    if (price > 10000) {
      return `${Math.round(price / 1000) / 10}만`;
    }
  }

  return price;
}

function dummy() {
  return null;
}

export { getShortPrice, dummy };
