import { rootStore } from '@/stores';
import { MESSAGE_CATEGORY } from '@/constants/constants';

function getAddressText(address, area) {
  return address?.region[area]?.alias || address?.region[area]?.name || '';
}

function degreesToRadians(degrees) {
  const radians = (degrees * Math.PI) / 180;
  return radians;
}

function computeDistance(startCoords, destCoords) {
  if (!startCoords || !destCoords) {
    return 0;
  }
  const startLatRads = degreesToRadians(startCoords.latitude);
  const startLongRads = degreesToRadians(startCoords.longitude);
  const destLatRads = degreesToRadians(destCoords.latitude);
  const destLongRads = degreesToRadians(destCoords.longitude);

  const Radius = 6371; // 지구의 반경(km)
  const distance = Math.acos(Math.sin(startLatRads) * Math.sin(destLatRads) + Math.cos(startLatRads) * Math.cos(destLatRads) * Math.cos(startLongRads - destLongRads)) * Radius;

  return distance;
}

function getPosition(handler) {
  if (navigator.permissions && navigator.permissions.query) {
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      const permission = result.state;
      if (permission === 'granted' || permission === 'prompt') {
        navigator.geolocation.getCurrentPosition(position => {
          handler(position.coords.longitude, position.coords.latitude);
        });
      } else if (permission === 'denied') {
        rootStore.controlStore.setMessage(MESSAGE_CATEGORY.WARNING, '위치 정보 접근 권한 없음', '브라우저 상단의 위치 정보를 활성화해주세요.');

        navigator.geolocation.getCurrentPosition(position => {
          handler(position.coords.longitude, position.coords.latitude);
        });
      }
    });
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      handler(position.coords.longitude, position.coords.latitude);
    });
  }
}

export { getAddressText, computeDistance, getPosition };
