import React, { useEffect, useState } from 'react';
import './EditClub.scss';
import { Block, BlockRow, Button, CheckBox, Form, ImageUploader, Img, Input, Label, Page, PageButtons, PageContent, PageTitle, Selector } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ClubService from '@/services/ClubService';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { getImageUrl } from '@/utils/commonUtil';
import moment from 'moment';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import TextArea from '@/components/TextArea/TextArea';
import ConfigService from '@/services/ConfigService';

const labelMinWidth = '100px';

function EditClub({ type }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const [file, setFile] = useState(null);

  const [weatherCodeList, setWeatherCodeList] = useState([]);

  const navigate = useNavigate();

  const [club, setClub] = useState({
    imagePath: '',
    name: '',
    location: '',
    locationShortName: '',
    homepageUrl: '',
    reservationUrl: '',
    token: '',
    rating: 0,
    minCost: 0,
    maxCost: 0,
    scanServiceKey: '',
    enabled: true,
    permissionDate: '',
    note: '',
    naverPlaceId: '',
    weatherCode: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && type === 'edit') {
      ClubService.selectClubInfo(id, info => {
        const data = { ...info };
        if (data.permissionDate) {
          data.permissionDate = moment(data.permissionDate, 'YYYY-MM-DD').format('YYYYMMDD');
        }
        setClub(data);
      });

      ConfigService.selectCodeList('WEATHER_LOCATION', list => {
        setWeatherCodeList(list);
      });
    }
  }, [type, id]);

  const getClubData = data => {
    const result = { ...data };
    if (result.permissionDate) {
      result.permissionDate = moment(club.permissionDate, 'YYYYMMDD').format('YYYY-MM-DD');
    }

    return result;
  };

  const onSubmit = e => {
    e.preventDefault();

    if (club.permissionDate) {
      if (!moment(club.permissionDate, 'YYYYMMDD', true).isValid()) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '날짜 형식 오류', '허가일자를 YYYYMMDD 형식으로 입력해주세요.');
        return;
      }
    }

    if (type === 'new') {
      if (file?.file) {
        const formData = new FormData();
        formData.append('file', file.file);

        ClubService.createImage(
          formData,
          d => {
            const data = { ...club };
            data.imagePath = d.path;
            ClubService.createClub(data, () => {
              navigate('/clubs');
            });
          },
          null,
          null,
          null,
          true,
        );
      } else {
        ClubService.createClub(getClubData(club), () => {
          navigate('/clubs');
        });
      }
    } else if (type === 'edit') {
      if (file?.file) {
        const formData = new FormData();
        formData.append('file', file.file);

        ClubService.createImage(
          formData,
          d => {
            const data = { ...club };
            data.imagePath = d.path;

            ClubService.updateClub(getClubData(data), () => {
              navigate('/clubs');
            });
          },
          null,
          null,
          null,
          true,
        );
      } else {
        ClubService.updateClub(getClubData(club), () => {
          navigate('/clubs');
        });
      }
    }
  };

  return (
    <Page className="edit-club-wrapper">
      <PageTitle links={[<Link to="/clubs/new">새 클럽</Link>]}>{t('새 클럽')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="pt-0">
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm">
                {t('이미지')}
              </Label>
              {type === 'new' && file && (
                <div className="image-info">
                  <Img className="image-preview" src={file.preview} alt="" />
                  <div className="image-delete-button">
                    <Button
                      outline
                      size="md"
                      onClick={() => {
                        setFile(null);
                        setClub({
                          ...club,
                          imagePath: '',
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              )}
              {type === 'new' && !file && (
                <ImageUploader
                  onChangeImage={f => {
                    setFile({
                      preview: URL.createObjectURL(f),
                      file: f,
                    });
                  }}
                />
              )}
              {type === 'edit' && club.imagePath && !file && (
                <div className="image-info">
                  <Img className="image-preview" src={getImageUrl(club.imagePath)} alt="" />
                  <div className="image-delete-button">
                    <Button
                      outline
                      size="md"
                      onClick={() => {
                        setFile(null);
                        setClub({
                          ...club,
                          imagePath: '',
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              )}
              {type === 'edit' && !club.imagePath && file && (
                <div className="image-info">
                  <div className="image-preview">
                    <img src={file.preview} alt="" />
                  </div>
                  <div className="image-delete-button">
                    <Button
                      outline
                      size="md"
                      onClick={() => {
                        setFile(null);
                        setClub({
                          ...club,
                          imagePath: '',
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              )}
              {type === 'edit' && !club.imagePath && !file && (
                <ImageUploader
                  onChangeImage={f => {
                    setFile({
                      preview: URL.createObjectURL(f),
                      file: f,
                    });
                  }}
                />
              )}
            </BlockRow>
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm" required>
                {t('클럽 이름')}
              </Label>
              <Input
                type="text"
                size="md"
                value={club.name}
                onChange={val =>
                  setClub({
                    ...club,
                    name: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('주소')}
              </Label>
              <Input
                value={club.location}
                onChange={val =>
                  setClub({
                    ...club,
                    location: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('지역')}
              </Label>
              <Input
                value={club.locationShortName}
                onChange={val =>
                  setClub({
                    ...club,
                    locationShortName: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('평점')}
              </Label>
              <Input
                value={club.rating}
                onChange={val =>
                  setClub({
                    ...club,
                    rating: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('네이버 플레이스 ID')}
              </Label>
              <Input
                value={club.naverPlaceId || ''}
                onChange={val =>
                  setClub({
                    ...club,
                    naverPlaceId: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('홈페이지')}
              </Label>
              <Input
                value={club.homepageUrl}
                onChange={val =>
                  setClub({
                    ...club,
                    homepageUrl: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('예약 페이지')}
              </Label>
              <Input
                value={club.reservationUrl}
                onChange={val =>
                  setClub({
                    ...club,
                    reservationUrl: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('토큰')}
              </Label>
              <Input
                value={club.token}
                onChange={val =>
                  setClub({
                    ...club,
                    token: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('최저 가격')}
              </Label>
              <Input
                value={club.minCost}
                onChange={val =>
                  setClub({
                    ...club,
                    minCost: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('최대 가격')}
              </Label>
              <Input
                value={club.maxCost}
                onChange={val =>
                  setClub({
                    ...club,
                    maxCost: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('스캔 서비스 키')}
              </Label>
              <Input
                value={club.scanServiceKey || ''}
                onChange={val =>
                  setClub({
                    ...club,
                    scanServiceKey: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('허가일자')}
              </Label>
              <Input
                value={club.permissionDate || ''}
                placeholder="YYYYMMDD"
                onChange={val =>
                  setClub({
                    ...club,
                    permissionDate: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('날씨 지역 코드')}
              </Label>
              <Selector
                className="selector"
                outline
                size="md"
                items={weatherCodeList.map(d => {
                  return {
                    key: d.code,
                    value: d.value,
                  };
                })}
                value={club.weatherCode}
                onChange={val => {
                  setClub({
                    ...club,
                    weatherCode: val,
                  });
                }}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('활성화')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={club.enabled}
                onChange={val =>
                  setClub({
                    ...club,
                    enabled: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('조회 중지')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={club.paused}
                onChange={val =>
                  setClub({
                    ...club,
                    paused: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('연속 중지 카운트')}
              </Label>
              <Input
                value={club.pausedCount || ''}
                onChange={val =>
                  setClub({
                    ...club,
                    pausedCount: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('마지막 중지 시간')}
              </Label>
              <Input
                value={club.lastPauseDate || ''}
                placeholder="YYYY-MM-DDTHH:mm:ss"
                onChange={val =>
                  setClub({
                    ...club,
                    lastPauseDate: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('타임아웃')}
              </Label>
              <Input
                value={club.connectionTimeOut || ''}
                onChange={val =>
                  setClub({
                    ...club,
                    connectionTimeOut: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('노트')}
              </Label>
              <TextArea
                value={club.note || ''}
                rows={4}
                onChange={val => {
                  setClub({
                    ...club,
                    note: val,
                  });
                }}
              />
            </BlockRow>
          </Block>
          <PageButtons
            onCancel={() => {
              navigate('/clubs');
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditClub.defaultProps = {
  type: 'new',
};

EditClub.propTypes = {
  type: PropTypes.string,
};

export default EditClub;
