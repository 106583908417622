import React, { useEffect, useState } from 'react';
import { BatchJobList, Page, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BatchService from '@/services/BatchService';

function Batches() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    BatchService.selectJobList(list => {
      setBatches(list);
    });
  }, []);

  return (
    <Page className="batches-wrapper">
      <PageTitle className="page-title" links={[<Link to="/batches/new">새 배치 잡</Link>]}>
        {t('배치 잡 리스트')}
      </PageTitle>
      <PageContent>
        <BatchJobList
          batches={batches}
          onClick={jobId => {
            navigate(`/batches/${jobId}`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default Batches;
