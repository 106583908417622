import React from 'react';
import PropTypes from 'prop-types';
import './DayWeather.scss';

const summaryCount = 3;

function DayWeather({ className, list }) {
  /*
    date: "2022-08-20"
precipitationForm: 1
rain: 60
rainfall: "9.0mm"
sky: 4
temperature: 25
time: "00:00:00"
     */

  const summaryList = React.useMemo(() => {
    const result = [];
    const precipitationForms = [];
    const rains = [];
    const skys = [];
    const temperatures = [];

    list.forEach((d, inx) => {
      if ((inx !== 0 && inx % summaryCount === 0) || inx === list.length - 1) {
        result.push({
          precipitationForm: Math.max(...precipitationForms),
          rain: Math.max(...rains),
          sky: Math.max(...skys),
          temperature: Math.max(...temperatures),
        });

        precipitationForms.length = 0;
        rains.length = 0;
        skys.length = 0;
        temperatures.length = 0;

        precipitationForms.push(d.precipitationForm);
        rains.push(d.rain);
        skys.push(d.sky);
        temperatures.push(d.temperature);
      } else {
        precipitationForms.push(d.precipitationForm);
        rains.push(d.rain);
        skys.push(d.sky);
        temperatures.push(d.temperature);
      }
    });

    return result;
  }, [list]);

  return (
    <div className={`${className} day-weather-wrapper`}>
      {summaryList.map((d, inx) => {
        return (
          <div key={inx}>
            {d.rain >= 50 && (
              <span className="rainy">
                <i className="fas fa-cloud-showers" />
              </span>
            )}
            {d.rain < 50 && d.sky === 1 && (
              <span className="sunny">
                <i className="fas fa-sun" />
              </span>
            )}
            {d.rain < 50 && d.sky !== 1 && (
              <span className="cloudy">
                <i className="fas fa-cloud" />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

DayWeather.defaultProps = {
  className: '',
  list: [],
};

DayWeather.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      precipitationForm: PropTypes.number,
      rain: PropTypes.number,
      rainfall: PropTypes.string,
      sky: PropTypes.number,
      temperature: PropTypes.number,
      time: PropTypes.string,
    }),
  ),
};

export default DayWeather;
