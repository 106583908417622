import React, { useEffect, useState } from 'react';
import './DailySummary.scss';
import TeeService from '@/services/TeeService';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExitButton from '@/components/ExitButton/ExitButton';
import useStores from '@/hooks/useStores';
import { useNavigate } from 'react-router-dom';

function DailySummary({ className, max, setOpen, daily, onClick, selectedDate, isOpen }) {
  const {
    configStore: { holidays },
  } = useStores();

  const [dailyCountInfo, setDailyCountInfo] = useState(null);

  useEffect(() => {
    const start = moment().startOf('day');
    const end = moment(start).add(max, 'day').endOf('day');

    const calendarStart = moment(start).startOf('month');
    const calendarEnd = moment(end).endOf('month');

    if (daily) {
      TeeService.selectDailyTeeList(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), list => {
        const map = {};
        list.forEach(d => {
          map[d.date] = d.count;
        });

        const cursorDay = moment(calendarStart);

        const calendar = [];
        let inx = 0;

        while (cursorDay.isSameOrBefore(calendarEnd)) {
          const month = cursorDay.format('MM');
          if (!calendar[month]) {
            calendar[month] = [];
            inx = 0;
          }

          if (!calendar[month][inx]) {
            calendar[month][inx] = [];
          }

          calendar[month][inx].push({
            date: moment(cursorDay),
            count: map[cursorDay.format('YYYY-MM-DD')] || 0,
          });

          if (cursorDay.days() === 6) {
            inx += 1;
          }

          cursorDay.add(1, 'day');
        }

        setDailyCountInfo({
          today: moment(),
          start,
          end,
          calendar,
        });
      });
    } else {
      const cursorDay = moment(calendarStart);

      const calendar = [];
      let inx = 0;

      while (cursorDay.isSameOrBefore(calendarEnd)) {
        const month = cursorDay.format('MM');
        if (!calendar[month]) {
          calendar[month] = [];
          inx = 0;
        }

        if (!calendar[month][inx]) {
          calendar[month][inx] = [];
        }

        calendar[month][inx].push({
          date: moment(cursorDay),
        });

        if (cursorDay.days() === 6) {
          inx += 1;
        }

        cursorDay.add(1, 'day');
      }

      setDailyCountInfo({
        today: moment(),
        start,
        end,
        calendar,
      });
    }
  }, [daily]);

  const navigate = useNavigate();

  return (
    <div className={`daily-summary-wrapper ${className} ${isOpen ? 'open' : ''}`}>
      <div>
        <div className="popup-title">
          <div
            className="icon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>
              <i className="fal fa-chevron-left" />
            </span>
          </div>
          <div className="text">캘린더</div>
          <div>
            <ExitButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="popup-content">
          <div>
            {Object.keys(dailyCountInfo?.calendar || {}).map(month => {
              return (
                <div key={month} className="month-block">
                  <div className="month-title">{month}월</div>
                  <div className="days">
                    <div className="day-0">일</div>
                    <div className="day-1">월</div>
                    <div className="day-2">화</div>
                    <div className="day-3">수</div>
                    <div className="day-4">목</div>
                    <div className="day-5">금</div>
                    <div className="day-6">토</div>
                  </div>
                  <div className="month-content">
                    {dailyCountInfo.calendar[month].map((weeks, wnx) => {
                      return (
                        <div key={wnx} className={`week-block ${wnx === 0 ? 'first-week' : ''} ${weeks.length - 1 === wnx ? 'last-week' : ''}`}>
                          {weeks.map((day, dnx) => {
                            const isValid = day.date.isSameOrAfter(dailyCountInfo.start) && day.date.isSameOrBefore(dailyCountInfo.end);
                            const isHoliday = day.date.days() === 0 || holidays.includes(day.date.format('MMDD')) || holidays.includes(day.date.format('YYYYMMDD'));
                            const today = dailyCountInfo.today.format('YYYY-MM-DD') === day.date.format('YYYY-MM-DD');
                            const isSelectedDate = selectedDate?.format('YYYY-MM-DD') === day.date.format('YYYY-MM-DD');
                            return (
                              <div
                                key={dnx}
                                className={`day-item ${isValid ? 'valid' : 'in-valid'}`}
                                onClick={() => {
                                  if (isValid) {
                                    onClick(day.date);
                                  }
                                }}
                              >
                                {today && <div className="today-label">TODAY</div>}
                                <div className={`day ${isSelectedDate ? 'selected' : ''} ${today ? 'today' : ''} ${isHoliday ? 'holiday' : ''} day-${day.date.days()}`}>
                                  <span>{day.date.format('D')}</span>
                                </div>
                                {isValid && day.count > 0 && <div className="count">{day.count > 999 ? '999+' : day.count}티</div>}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

DailySummary.defaultProps = {
  className: '',
  max: 30,
  daily: false,
  selectedDate: null,
  isOpen: false,
};

DailySummary.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  setOpen: PropTypes.func.isRequired,
  daily: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(moment),
  isOpen: PropTypes.bool,
};

export default DailySummary;
