import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { MENUS } from '@/constants/menu';
import useStores from '@/hooks/useStores';
import { observer } from 'mobx-react';
import './Navigator.scss';

function Navigator({ className }) {
  const location = useLocation();

  const {
    userStore: { isLogin },
  } = useStores();

  return (
    <div className={`${className} navigator-wrapper`}>
      <ul>
        {MENUS.filter(d => !d.admin)
          .filter(d => {
            return !(d.login !== undefined && d.login !== isLogin);
          })
          .map((d, inx) => {
            return (
              <li key={inx} className={location.pathname === d.to ? 'selected' : ''}>
                <Link to={d.to}>
                  <div>
                    <div className="icon">
                      <span>{d.icon}</span>
                    </div>
                    <div>{d.name}</div>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

Navigator.defaultProps = {
  className: '',
};

Navigator.propTypes = {
  className: PropTypes.string,
};

export default observer(Navigator);
