import * as request from '@/utils/request';

const TalkService = {};

TalkService.selectTalkList = (lastId, successHandler, failHandler) => {
  return request.get(
    '/api/talks/{lastId}',
    { lastId },
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

TalkService.createTalk = (talk, successHandler, failHandler) => {
  return request.post(
    '/api/talks',
    talk,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default TalkService;
