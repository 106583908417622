import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  ClubReview,
  ClubSummaryList,
  DailySummary,
  NaverMap,
  RegionalSummaryBarList,
  RequestProgress,
  ScrollCalendar,
  SimpleClubList,
  SocketClient,
  StartPosition,
  Tabs,
  TagSelector,
  Title,
  UserGrade,
} from '@/components';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {debounce} from 'lodash';
import useStores from '@/hooks/useStores';
import {observer} from 'mobx-react';
import TeeService from '@/services/TeeService';
import {computeDistance} from '@/utils/mapUtil';
import TeeInfoPopup from '@/components/TeeInfoPopup/TeeInfoPopup';
import RegionalInfoPopup from '@/components/RegionalInfoPopup/RegionalInfoPopup';
import {useSearchParams} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './Main.scss';

// const tags = ['3인 가능', '잔디 상태 좋은', '경치 좋은', '인기 많은', '가까운', '평점이 높은', '신규 골프장'];
const tags = [
  {
    key: 'distance',
    value: '가까운',
  },
  {
    key: 'rating',
    value: '평점이 높은',
  },
  {
    key: 'fresh',
    value: '신규 골프장',
  },
];

const tabs = [
  {
    key: 'all',
    value: '전체',
  },
  {
    key: 'dawn',
    value: '새벽',
  },
  {
    key: 'am',
    value: '오전',
  },
  {
    key: 'pm',
    value: '오후',
  },
  {
    key: 'night',
    value: '야간',
  },
];

const orders = [
  {
    key: 'distance',
    value: '거리순',
  },
  {
    key: 'teeTime',
    value: '티 시간순',
  },
  {
    key: 'price',
    value: '가격순',
  },
  {
    key: 'rating',
    value: '평점순',
  },
  {
    key: 'name',
    value: '이름순',
  },
];

const regions = [
  {
    list: ['서울', '경기', '인천'],
    code: 's',
    name: '서울/경기',
  },
  {
    list: ['강원'],
    code: 'g',
    name: '강원',
  },
  {
    list: ['충북', '충남'],
    code: 'c',
    name: '충청',
  },
  {
    list: ['경북', '경남'],
    code: 'k',
    name: '경상',
  },
  {
    list: ['전북', '전남'],
    code: 'j',
    name: '전라',
  },
  {
    list: ['제주', '제주'],
    code: 'jj',
    name: '제주',
  },
];

const minLoading = 500;

function Main() {
  const { t } = useTranslation();

  const [params, setParams] = useSearchParams();

  const socket = useRef(null);

  const [recommendClubRequesting, setRecommendClubRequesting] = useState(false);

  const counter = useRef(0);

  const [recommendClubRequestingInfo, setRecommendClubRequestingInfo] = useState({});

  const tempList = useRef([]);

  const [selectedTagInfo, setSelectedTagInfo] = useState({ distance: true });

  const [recommendClubList, setRecommendClubList] = useState(null);

  const [simpleClubList, setSimpleClubList] = useState(null);

  const [timeCategory, setTimeCategory] = useState('all');

  const [order, setOrder] = useState('distance');

  const [orderDirAsc, setOrderDirAsc] = useState(true);

  const [regionalSummaryList, setRegionalSummaryList] = useState([]);

  const [connectTried, setConnectTried] = useState(false);

  const [openMap, setOpenMap] = useState(false);

  const [circleInfo, setCircleInfo] = useState({
    circle1: {
      width: 300,
      height: 300,
      top: -100,
      left: -100,
    },
    circle2: {
      width: 400,
      height: 400,
      top: -200,
      right: -100,
    },
  });

  useEffect(() => {
    setCircleInfo({
      circle1: {
        width: 300,
        height: 300,
        top: -100,
        left: -100 + (Math.floor(Math.random() * 2) % 2 === 1 ? 1 : -1) * Math.floor(Math.random() * 100),
      },
      circle2: {
        width: 400,
        height: 400,
        top: -200,
        right: -100 + (Math.floor(Math.random() * 2) % 2 === 1 ? 1 : -1) * Math.floor(Math.random() * 100),
      },
    });
  }, []);

  const openDailySummary = params.get('calendar') === 'Y';
  const openRegionPopup = params.get('region') === 'Y';
  const openClubPopup = params.get('club') === 'Y';
  const openReviewPopup = params.get('review') === 'Y';

  const openPopup = openDailySummary || openRegionPopup || openClubPopup || openReviewPopup;

  const changeOrder = val => {
    if (val === order) {
      setOrderDirAsc(!orderDirAsc);
    } else {
      setOrder(val);
    }
  };

  const [teeInfo, setTeeInfo] = useState({
    title: '',
    date: null,
    teeList: [],
    reservationUrl: null,
  });

  const [regionalInfo, setRegionalInfo] = useState({
    title: '',
    date: null,
    clubTees: [],
  });

  const [reviewInfo, setReviewInfo] = useState('');

  const {
    conditionStore: { date, coords, setDate },
    userStore: { user },
    controlStore: { setHideHeader, hideHeader },
  } = useStores();

  const setClubReview = clubName => {
    params.set('review', 'Y');
    setParams(params);
    setReviewInfo(clubName);
  };

  const selectTeeList = (pdate, pselectedTagInfo, pcoords, pUser) => {
    setRecommendClubRequesting(true);
    TeeService.selectTeeList(
      pdate.format('YYYY-MM-DD'),
      clubTeeResult => {
        const clubTee = clubTeeResult.filter(d => {
          return !(pUser.setting?.excludes && pUser.setting?.excludes[d.club.id]);
        });

        setTimeout(() => {
          setRecommendClubRequesting(false);
          setRecommendClubRequestingInfo({});
          tempList.current = [];
        }, minLoading);

        const list = [];

        regions.forEach(region => {
          list.push({
            name: region.name,
            code: region.code,
            list: [],
          });
        });

        clubTee.forEach(row => {
          const [sido] = row.club.locationShortName.split(' ');
          const regionName = (regions.find(d => d.list.includes(sido)) || {}).name;
          const item = list.find(d => d.name === regionName);
          if (item) {
            item.list.push(row);
          } else {
            Sentry.captureException(`클럽 설정에 오류가 있습니다. (${row?.club?.name})`);
          }
        });

        setRegionalSummaryList(list);

        clubTee.forEach(row => {
          const next = row;
          next.club.distance = computeDistance(pcoords, {
            latitude: next.club.latitude,
            longitude: next.club.longitude,
          });

          next.club.date = pdate;
          next.club.minCost = Math.min(
            ...next.tees.map(tee => {
              return Math.min(tee.memberGreenFee, tee.nonMemberGreenFee);
            }),
          );
          next.club.maxCost = Math.max(
            ...next.tees.map(tee => {
              return Math.max(tee.memberGreenFee, tee.nonMemberGreenFee);
            }),
          );
        });

        clubTee.forEach(row => {
          row.tees.sort((a, b) => {
            if (moment(a.teeOffTime, 'HH:mm:ss').isSame(moment(b.teeOffTime, 'HH:mm:ss'))) {
              return 0;
            }

            return moment(a.teeOffTime, 'HH:mm:ss').isAfter(moment(b.teeOffTime, 'HH:mm:ss')) ? 1 : -1;
          });
        });

        setSimpleClubList(clubTee.slice(0));

        let filteredClubTee = clubTee.sort((a, b) => {
          return a.club.distance - b.club.distance;
        });
        if (pselectedTagInfo.distance) {
          const limit = Infinity;
          filteredClubTee = clubTee.filter(d => d.club.distance < limit);
        }

        if (pselectedTagInfo.rating) {
          filteredClubTee.sort((a, b) => {
            return b.club.rating - a.club.rating;
          });
        }

        if (pselectedTagInfo.fresh) {
          filteredClubTee.sort((a, b) => {
            if (a.club.permissionDate && !b.club.permissionDate) {
              return -1;
            }

            if (!a.club.permissionDate && b.club.permissionDate) {
              return 1;
            }

            if (!a.club.permissionDate && !b.club.permissionDate) {
              return 0;
            }

            return moment(b.club.permissionDate, 'YYYY-MM-DD').isAfter(moment(a.club.permissionDate, 'YYYY-MM-DD')) ? 1 : -1;
          });
        }

        setRecommendClubList(filteredClubTee);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      },
      () => {
        setTimeout(() => {
          setRecommendClubRequesting(false);
          setRecommendClubRequestingInfo({});
          tempList.current = [];
        }, minLoading);
      },
    );
  };

  const selectTeeListDebounced = useRef(debounce(selectTeeList, 100)).current;

  useEffect(() => {
    if (connectTried) {
      selectTeeListDebounced(date, selectedTagInfo, coords, user);
    }
  }, [connectTried, date, selectedTagInfo, coords, user]);

  useEffect(() => {
    if (params.get('day')) {
      setDate(moment(params.get('day'), 'YYYY-MM-DD'));
    }
    params.set('club', 'N');
    params.set('calendar', 'N');
    params.set('region', 'N');
    setParams(params);
  }, []);

  useEffect(() => {
    if (openPopup) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = null;
    }

    return () => {
      document.querySelector('body').style.overflow = null;
    };
  }, [openPopup]);

  const onTagSelect = tagKey => {
    const next = { ...selectedTagInfo };
    if (next[tagKey]) {
      delete next[tagKey];
    } else {
      next[tagKey] = true;
    }
    setSelectedTagInfo(next);
  };

  const onMessage = useCallback(
    info => {
      const {
        data: { type, data },
      } = info;

      switch (type) {
        case 'SEARCHING-START': {
          tempList.current.push({
            name: data.name,
            done: false,
          });
          counter.current = 0;
          setRecommendClubRequestingInfo({
            totalCount: data.clubCount,
            currentCount: 0,
          });
          break;
        }

        case 'SEARCHING-CLUB-DONE': {
          const next = { ...recommendClubRequestingInfo };
          counter.current += 1;
          next.currentCount = counter.current;
          next.club = data.club;
          setRecommendClubRequestingInfo(next);
          break;
        }

        case 'SEARCHING-END': {
          break;
        }

        default: {
          break;
        }
      }
    },
    [recommendClubRequestingInfo],
  );

  const onScroll = () => {
    if (window.scrollY > 100) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
  };

  const onScrollDebounced = React.useMemo(() => debounce(onScroll, 20), []);

  useEffect(() => {
    document.addEventListener('scroll', onScrollDebounced);
    return () => {
      document.removeEventListener('scroll', onScrollDebounced);
      setHideHeader(false);
    };
  }, []);

  return (
    <div className={`main-wrapper ${openPopup ? 'hide' : ''}`}>
      {user.token && (
        <SocketClient
          topics={[`/sub/tokens/${user.token}`]}
          onMessage={onMessage}
          onConnect={() => {
            setConnectTried(true);
          }}
          onDisconnect={() => {
            setConnectTried(true);
          }}
          setRef={client => {
            socket.current = client;
          }}
        />
      )}
      <UserGrade />
      <div className={`start-position ${hideHeader ? 'move-up' : ''}`}>
        <StartPosition setOpenMap={setOpenMap} />
      </div>
      <div className={`scroll-calendar ${hideHeader ? 'move-up' : ''}`}>
        <ScrollCalendar
          className={`${openPopup ? 'hide' : ''}`}
          onMonthClick={() => {
            params.set('calendar', 'Y');
            setParams(params);
          }}
        />
      </div>
      <div className="club-by-tag">
        <div className="rect" />
        <div
          className="circle-1"
          style={{
            width: `${circleInfo.circle1.width}px`,
            height: `${circleInfo.circle1.height}px`,
            top: `${circleInfo.circle1.top}px`,
            left: `${circleInfo.circle1.left}px`,
          }}
        />
        <div
          className="circle-2"
          style={{
            width: `${circleInfo.circle2.width}px`,
            height: `${circleInfo.circle2.height}px`,
            top: `${circleInfo.circle2.top}px`,
            right: `${circleInfo.circle2.right}px`,
          }}
        />
        <div className="max-content">
          <Title className="title" type="h2">
            {t('태그로 골프장 선택')}
          </Title>
          <TagSelector
            className="tag-selector"
            tags={tags}
            selectedTagInfo={selectedTagInfo}
            onSelect={onTagSelect}
            onClear={() => {
              setSelectedTagInfo({});
            }}
          />
          <ClubSummaryList
            className="club-summary-list"
            clubTeeList={recommendClubList}
            onClubNameClick={setClubReview}
            setTeeInfo={d => {
              params.set('club', 'Y');
              setParams(params);
              setTeeInfo(d);
            }}
            permissionDate={selectedTagInfo.fresh}
          />
        </div>
      </div>
      <div className="club-by-region">
        <div className="max-content">
          <Title type="h2">{t('지역별 티타임')}</Title>
          <Tabs tabs={tabs} value={timeCategory} onSelect={setTimeCategory} />
          <RegionalSummaryBarList
            className="regional-summary-bar"
            regionalSummaryList={regionalSummaryList}
            timeCategory={timeCategory}
            onClick={d => {
              params.set('region', 'Y');
              setParams(params);
              setRegionalInfo({
                title: d.name,
                date,
                clubTees: d.list,
              });
            }}
          />
        </div>
      </div>
      <div className="club-by-sort">
        <div className="max-content">
          <Title type="h2">{t('모든 골프장')}</Title>
          <div className="sort-order">
            <div
              className="order"
              onClick={() => {
                setOrderDirAsc(!orderDirAsc);
              }}
            >
              {orderDirAsc && <i className="fal fa-sort-amount-up" />}
              {!orderDirAsc && <i className="fal fa-sort-amount-down-alt" />}
            </div>
            <div className="values">
              <Tabs tabs={orders} value={order} onSelect={changeOrder} />
            </div>
          </div>
          <SimpleClubList
            clubTeeList={simpleClubList}
            onClubNameClick={setClubReview}
            setTeeInfo={d => {
              params.set('club', 'Y');
              setParams(params);
              setTeeInfo(d);
            }}
            order={order}
            orderDirAsc={orderDirAsc}
          />
        </div>
      </div>
      <TeeInfoPopup
        isOpen={openClubPopup}
        setOpen={val => {
          params.set('club', val ? 'Y' : 'N');
          setParams(params);
        }}
        teeInfo={teeInfo}
        setTeeInfo={setTeeInfo}
      />
      <RegionalInfoPopup
        isOpen={openRegionPopup}
        setOpen={val => {
          params.set('region', val ? 'Y' : 'N');
          setParams(params);
        }}
        regionalInfo={regionalInfo}
        setRegionalInfo={setRegionalInfo}
        timeCategory={timeCategory}
      />
      {recommendClubRequesting && <RequestProgress recommendClubRequestingInfo={recommendClubRequestingInfo} />}
      <DailySummary
        maxData={30}
        setOpen={val => {
          params.set('calendar', val ? 'Y' : 'N');
          setParams(params);
        }}
        selectedDate={date}
        daily
        onClick={d => {
          setDate(d);
          params.set('calendar', 'N');
          setParams(params);
        }}
        isOpen={openDailySummary}
      />
      {openMap && <NaverMap setOpen={setOpenMap} />}
      <ClubReview
        setOpen={val => {
          params.set('review', val ? 'Y' : 'N');
          setParams(params);
          setReviewInfo('');
        }}
        name={reviewInfo}
        isOpen={openReviewPopup}
      />
    </div>
  );
}

export default observer(Main);
