import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import './Holiday.scss';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import ConfigService from '@/services/ConfigService';
import moment from 'moment';

const labelMinWidth = '100px';

function Holiday() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [holiday, setHoliday] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ConfigService.getHoliday(id, info => {
      setHoliday(info);
    });
  }, []);

  const deleteHoliday = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '휴일 데이터 삭제',
      <div>
        <div>
          <span className="g-tag">{holiday.name}</span>
        </div>
        <div>휴일 정보를 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        ConfigService.deleteHoliday(id, () => {
          navigate('/configs/holidays');
        });
      },
      () => {},
    );
  };

  return (
    <Page className="holiday-wrapper">
      <PageTitle className="page-title" links={[<Link to="/configs/holidays">휴일 목록</Link>]}>
        휴일 정보
      </PageTitle>
      <PageContent>
        <Block>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('이름')}
            </Label>
            <Text>{holiday.name}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('날짜')}
            </Label>
            <Text>{holiday.regularYn ? moment(holiday.date, 'MMDD').format('MM월 DD일') : moment(holiday.date, 'YYYYMMDD').format('YYYY년 MM월 DD일')}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('타입')}
            </Label>
            <Text>{holiday.regularYn ? '정기휴일' : '임시휴일'}</Text>
          </BlockRow>
        </Block>
      </PageContent>
      <PageButtons
        onDelete={deleteHoliday}
        onList={() => {
          navigate('/configs/holidays');
        }}
        onEdit={() => {
          navigate(`/configs/holidays/${id}/edit`);
        }}
      />
    </Page>
  );
}

export default Holiday;
