import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

export default class ConditionStore {

  date = moment().hour(0).minute(0).second(0).millisecond(0);

  address = null;

  coords = null;

  constructor() {
    makeObservable(this, {
      date: observable,
      address : observable,
      coords : observable,
      setDate: action,
      setAddress: action,
      setCoords: action,
    });
  }

  setDate = date => {
    this.date = date;
  };

  setAddress = address => {
    this.address = address;
  };

  setCoords = coords => {
    this.coords = coords;
  };
}
