import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Board, Boards, Config, CSList, CustomerServiceInfo, EditBoard, EditCode, EditHoliday, EditUser, Holiday, Holidays, Links, Message, User, Users } from '@/pages';
import System from '@/pages/configs/System';

function ConfigsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Config />} />
      <Route path="/holidays" element={<Holidays />} />
      <Route path="/holidays/new" element={<EditHoliday />} />
      <Route path="/holidays/:id/edit" element={<EditHoliday type="edit" />} />
      <Route path="/holidays/:id" element={<Holiday />} />
      <Route path="/boards" element={<Boards />} />
      <Route path="/boards/new" element={<EditBoard />} />
      <Route path="/boards/:id/edit" element={<EditBoard type="edit" />} />
      <Route path="/boards/:id" element={<Board />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/:id/edit" element={<EditUser />} />
      <Route path="/users/:id" element={<User />} />
      <Route path="/system/codes/:code/types/:type" element={<EditCode type="edit" />} />
      <Route path="/system/codes/new" element={<EditCode type="new" />} />
      <Route path="/system" element={<System />} />
      <Route path="/links" element={<Links />} />
      <Route path="/cs" element={<CSList />} />
      <Route path="cs/:id" element={<CustomerServiceInfo />} />
      <Route path="*" element={<Message code="404" />} />
    </Routes>
  );
}

export default ConfigsRoutes;
