import React, { useEffect, useState } from 'react';
import { Button, CheckBox, Form, Info, Input, Logo, Page, PageContent, Title } from '@/components';
import KakaoLib from '@/vendor/kakao.min';
import siteImage from '@/images/sites';
import loginUtil from '@/utils/loginUtil';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import useStores from '@/hooks/useStores';
import { setOption } from '@/utils/storageUtil';
import './Login.scss';

function Login() {
  const { userStore } = useStores();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [info, setInfo] = useState({
    email: '',
    password: '',
    autoLogin: true,
  });

  useEffect(() => {
    if (!KakaoLib.isInitialized()) KakaoLib.init(loginUtil.getClientId('kakao'));
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    UserService.login(info, data => {
      if (info.autoLogin && data.uuid) {
        setOption('user', 'info', 'uuid', data.uuid);
      }

      userStore.setUser(data);
      navigate('/');
    });
  };

  const onSocialLogin = vendor => {
    if (info.autoLogin) {
      setOption('user', 'info', 'autoLogin', true);
    }

    loginUtil.socialLogin(vendor);
  };

  return (
    <Page className="login-wrapper">
      <PageContent padding>
        <div className="logo">
          <Logo size="sm" />
        </div>
        <Form className="form" onSubmit={onSubmit}>
          <div className="login-content">
            <Title className="title" type="h2">
              로그인
            </Title>
            <Info className="message" icon={false}>
              공티 회원은 보다 최신의 예약 정보를 확인할 수 있습니다.
            </Info>
            <div className="email">
              <Input
                required
                type="text"
                size="md"
                placeholder="이메일"
                value={info.email}
                onChange={val =>
                  setInfo({
                    ...info,
                    email: val,
                  })
                }
                minLength={1}
              />
            </div>
            <div>
              <Input
                required
                type="password"
                size="md"
                placeholder="비밀번호"
                value={info.password}
                onChange={val =>
                  setInfo({
                    ...info,
                    password: val,
                  })
                }
                minLength={1}
              />
            </div>
            <div className="checked">
              <CheckBox
                size="sm"
                type="checkbox"
                value={info.autoLogin}
                onChange={val =>
                  setInfo({
                    ...info,
                    autoLogin: val,
                  })
                }
                label="자동 로그인"
              />
            </div>
            <div className="login-button">
              <Button
                size="md"
                outline
                color="white"
                onClick={() => {
                  navigate(-1);
                }}
              >
                취소
              </Button>
              <Button size="md" type="submit" outline color="white">
                로그인
              </Button>
            </div>
            <div className="links">
              <Link to="/users/join">회원 가입</Link>
            </div>
          </div>
        </Form>
      </PageContent>
      <div className="simple-login">
        <div>
          <div className="line">
            <div>간편 로그인</div>
          </div>
          <div className="sns-buttons">
            <div>
              <Button
                className="kakao"
                onClick={() => {
                  onSocialLogin('kakao');
                }}
              >
                <div>
                  <span className="vendor">
                    <img src={siteImage.kakao} alt="kakao" />
                  </span>
                  <span className="text">카카오</span>
                </div>
              </Button>
              <Button
                className="naver"
                onClick={() => {
                  onSocialLogin('naver');
                }}
              >
                <div>
                  <span className="vendor">
                    <img src={siteImage.naver} alt="naver" />
                  </span>
                  <span className="text">네이버</span>
                </div>
              </Button>
            </div>
            <div>
              <Button
                className="facebook"
                onClick={() => {
                  onSocialLogin('facebook');
                }}
              >
                <div>
                  <span>
                    <img src={siteImage.facebook} alt="facebook" />
                  </span>
                  <span className="text">페이스북 로그인</span>
                </div>
              </Button>
              <Button
                className="google"
                onClick={() => {
                  onSocialLogin('google');
                }}
              >
                <div>
                  <span>
                    <img src={siteImage.google} alt="google" />
                  </span>
                  <span className="text">구글 로그인</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

Login.defaultProps = {};

Login.propTypes = {};

export default Login;
