import React from 'react';
import PropTypes from 'prop-types';
import './EmptyContent.scss';

function EmptyContent({ className, icon, children }) {
  return (
    <div className={`empty-content-wrapper ${className}`}>
      <div>
        <div className="icon">{icon}</div>
        <div className="text">{children}</div>
      </div>
    </div>
  );
}

EmptyContent.defaultProps = {
  className: '',
  icon: <i className="fal fa-receipt" />,
  children: '',
};

EmptyContent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default EmptyContent;
