import React from 'react';
import PropTypes from 'prop-types';
import { ClubInfoListPropTypes } from '@/proptypes';
import './UserInfoList.scss';
import EmptyContent from '@/components/EmptyContent/EmptyContent';
import moment from 'moment';
import Liner from '@/components/Liner/Liner';

function UserInfoList({ className, users, onUserClick }) {
  return (
    <div className={`user-info-list-wrapper ${className}`}>
      <div>
        {users?.length < 1 && <EmptyContent>검색된 사용자가 없습니다</EmptyContent>}
        {users?.length > 0 && (
          <ul>
            {users.map((user, inx) => {
              return (
                <li
                  key={inx}
                  className={user.useYn ? '' : 'disabled'}
                  onClick={() => {
                    if (onUserClick) {
                      onUserClick(user.id);
                    }
                  }}
                >
                  <div className="name">
                    <div className="text">{user.name}</div>
                    {(user.roleCode === 'ADMIN' || user.activeRoleCode === 'ADMIN') && (
                      <div className="role">
                        <span>{user.activeRoleCode}</span>
                        <span>{user.roleCode}</span>
                      </div>
                    )}
                  </div>
                  <div className="vendor-info">
                    <div>
                      {user.vendor && (
                        <>
                          <div className={`vendor ${user.vendor}`}>{user.vendor}</div>
                          <div className="email">{user.vendorEmail}</div>
                        </>
                      )}
                      {!user.vendor && <div className="email">{user.email}</div>}
                    </div>
                    <div className="last">
                      <div className="last-login-info">
                        <div className="tag last-login">LAST LOGIN</div>
                        <div className="email">{user.lastLoginDate ? moment(user.lastLoginDate).format('YYYY년 MM월 DD일 HH시mm분') : ''}</div>
                      </div>
                      <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                      <div className={`grade ${user.grade}`}>
                        <span>{user.grade}</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

UserInfoList.defaultProps = {
  className: '',
  users: [],
  onUserClick: null,
};

UserInfoList.propTypes = {
  className: PropTypes.string,
  users: ClubInfoListPropTypes,
  onUserClick: PropTypes.func,
};

export default UserInfoList;
