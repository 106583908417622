import React, { useEffect, useState } from 'react';
import './EditBatch.scss';
import { PageContent, Block, BlockRow, CheckBox, Form, Input, Label, Page, PageButtons, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BatchService from '@/services/BatchService';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';

const labelMinWidth = '100px';

function EditBatch({ type }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const [batch, setBatch] = useState({
    id: null,
    batchJobClass: '',
    batchJobDescription: '',
    batchJobName: '',
    batchJobPackage: 'com.teamkim.everyonegolf.biz.batch.job.',
    cronExpression: '0 30 2 * * ?',
    useYn: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && type === 'edit') {
      BatchService.selectJobInfo(id, info => {
        setBatch(info);
      });
    }
  }, [type, id]);

  const onSubmit = e => {
    e.preventDefault();

    console.log(batch);

    if (type === 'new') {
      BatchService.createJobInfo(batch, () => {
        navigate('/batches');
      });
    } else if (type === 'edit') {
      BatchService.updateJobInfo(id, batch, () => {
        navigate(`/batches/${id}`);
      });
    }
  };

  return (
    <Page className="edit-batch-wrapper">
      <PageTitle links={[<Link to="/batches">배치 목록</Link>]}>{t('배치 목록')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="pt-0">
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm" required>
                {t('배치 명')}
              </Label>
              <Input
                type="text"
                size="md"
                value={batch.batchJobName}
                onChange={val =>
                  setBatch({
                    ...batch,
                    batchJobName: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('배치 잡 클래스')}
              </Label>
              <Input
                value={batch.batchJobClass}
                onChange={val =>
                  setBatch({
                    ...batch,
                    batchJobClass: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('배치 잡 패키지')}
              </Label>
              <Input
                value={batch.batchJobPackage}
                onChange={val =>
                  setBatch({
                    ...batch,
                    batchJobPackage: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('CRON')}
              </Label>
              <Input
                value={batch.cronExpression}
                onChange={val =>
                  setBatch({
                    ...batch,
                    cronExpression: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('설명')}
              </Label>
              <Input
                value={batch.batchJobDescription || ''}
                onChange={val =>
                  setBatch({
                    ...batch,
                    batchJobDescription: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('사용 여부')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={batch.useYn}
                onChange={val =>
                  setBatch({
                    ...batch,
                    useYn: val,
                  })
                }
              />
            </BlockRow>
          </Block>
          <PageButtons
            onCancel={() => {
              navigate(`/batches/${id}`);
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditBatch.defaultProps = {
  type: 'new',
};

EditBatch.propTypes = {
  type: PropTypes.string,
};

export default EditBatch;
