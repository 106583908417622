import React, { useEffect, useState } from 'react';
import { Page, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BoardService from '@/services/BoardService';
import './Boards.scss';

function Boards() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [notices, setNotices] = useState([]);

  useEffect(() => {
    BoardService.getBoards(d => {
      setNotices(d);
    });
  }, []);

  return (
    <Page className="boards-wrapper">
      <PageTitle className="page-title" links={[<Link to="/configs/boards/new">새 글</Link>]}>
        {t('게시물 리스트')}
      </PageTitle>
      <PageContent padding>
        <div className="content">
          <ul>
            {notices.map(board => {
              return (
                <li
                  key={board.id}
                  onClick={() => {
                    navigate(`/configs/boards/${board.id}`);
                  }}
                >
                  <div className="subject">{board.subject}</div>
                  <div className="info">
                    {!board.enabled && <span>비활성</span>}
                    {board.noticeYn && <span className="notice-yn">공지</span>}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </PageContent>
    </Page>
  );
}

export default Boards;
