import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function TagSelector({ className, tags, selectedTagInfo, onSelect, onClear }) {
  return (
    <div className={`tag-selector-wrapper ${className}`}>
      <div>
        {tags?.length > 0 && (
          <ul>
            {Object.keys(selectedTagInfo).length > 0 && (
              <li className="clear-button" onClick={onClear}>
                <span className="icon">
                  <i className="fal fa-retweet" />
                </span>
                <span>초기화</span>
                <span className="count">
                  <span>{Object.keys(selectedTagInfo).length}</span>
                </span>
              </li>
            )}
            {tags.map((tag, inx) => {
              return (
                <li
                  key={inx}
                  className={`${selectedTagInfo[tag.key] ? 'selected' : ''}`}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(tag.key);
                    }
                  }}
                >
                  {tag.value}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

TagSelector.defaultProps = {
  className: '',
  tags: [],
  selectedTagInfo: {},
  onSelect: null,
  onClear: null,
};

TagSelector.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedTagInfo: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
};

export default TagSelector;
