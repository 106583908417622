import React, { useEffect, useState } from 'react';
import { EmptyContent, Liner, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import './MyCustomerServiceList.scss';
import CustomerInquiryService from '@/services/CustomerInquiryService';
import moment from 'moment';

function MyCustomerServiceList() {
  const navigate = useNavigate();

  const [customerInquiries, setCustomerInquiries] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    CustomerInquiryService.selectMyCustomerInquiryList(list => {
      setCustomerInquiries(list);
    });
  }, []);

  return (
    <Page className="my-customer-service-list-wrapper">
      <PageTitle links={[<Link to="/users/cs/new">1:1 문의</Link>]}>1:1 문의 내역</PageTitle>
      <PageContent className="page-content" padding>
        {customerInquiries?.length < 1 && <EmptyContent>문의 이력이 없습니다.</EmptyContent>}
        {customerInquiries?.length > 0 && (
          <ul>
            {customerInquiries.map(customerInquiry => {
              return (
                <li
                  key={customerInquiry.id}
                  onClick={() => {
                    navigate(`/users/cs/${customerInquiry.id}`);
                  }}
                >
                  <div className="subject">
                    <Link to={`/users/cs/${customerInquiry.id}`}>{customerInquiry.subject}</Link>
                  </div>
                  <div className="others">
                    <div className="creation-date">{moment(customerInquiry.creationDate).format('YYYY-MM-DD HH:mm')}</div>
                    <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 1rem" />
                    <div className={`answered ${customerInquiry.answered ? 'done' : 'none'}`}>
                      <span>{customerInquiry.answered ? '답변 완료' : '미답변'}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        <PageButtons
          onBack={() => {
            navigate(-1);
          }}
          onEdit={() => {
            navigate('/users/cs/new');
          }}
          onEditText="1:1 문의"
        />
      </PageContent>
    </Page>
  );
}

MyCustomerServiceList.defaultProps = {};

MyCustomerServiceList.propTypes = {};

export default observer(MyCustomerServiceList);
