import { action, computed, makeObservable, observable } from 'mobx';

export default class UserStore {
  user = {
    id: null,
    uuid: null,
    roleCode: null,
    token: null,
    email: null,
    name: null,
    grade: null,
    setting: {
      excludes: {},
    },
  };

  tried = null;

  constructor() {
    makeObservable(this, {
      user: observable,
      tried: observable,
      setUser: action,
      setUserSetting: action,
      setTried: action,
      isLogin: computed,
      grade: computed,
    });
  }

  setUser = user => {
    this.user = {
      ...user,
      setting: user.setting ? JSON.parse(user.setting) : { excludes: {} },
    };
  };

  setUserSetting = setting => {
    this.user = {
      ...this.user,
      setting,
    };
  };

  setTried = tried => {
    this.tried = tried;
  };

  get isLogin() {
    return !!this.user?.id;
  }

  get grade() {
    return this.user?.grade;
  }
}
