import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ChangePassword,
  CustomerService,
  CustomerServiceInfo,
  EditCustomerService,
  EditMy,
  Join,
  Login,
  Message,
  My,
  MyCustomerServiceList,
  OAuthFail,
  OAuthRegister,
  Privacy,
  Terms,
  UserClubSetting,
  UserGradeIntro,
  Withdrawal,
} from '@/pages';

function UsersRoutes() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="oauth/fail" element={<OAuthFail />} />
      <Route path="oauth/register" element={<OAuthRegister />} />
      <Route path="my/edit/password" element={<ChangePassword />} />
      <Route path="my/edit" element={<EditMy />} />
      <Route path="my" element={<My />} />
      <Route path="join" element={<Join />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="withdrawal" element={<Withdrawal />} />
      <Route path="clubs" element={<UserClubSetting />} />
      <Route path="cs/new" element={<EditCustomerService />} />
      <Route path="cs/my" element={<MyCustomerServiceList />} />
      <Route path="cs/:id" element={<CustomerServiceInfo />} />
      <Route path="cs" element={<CustomerService />} />
      <Route path="grade" element={<UserGradeIntro />} />
      <Route path="*" element={<Message code="404" />} />
    </Routes>
  );
}

export default UsersRoutes;
