import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, EmptyContent, Form, Page, PageContent, PageTitle, SocketClient, TextArea } from '@/components';
import { useTranslation } from 'react-i18next';
import useStores from '@/hooks/useStores';
import { observer } from 'mobx-react';
import './Talks.scss';
import TalkService from '@/services/TalkService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

const pageSize = 20;

function Talks() {
  const socket = useRef(null);
  const scroller = useRef(null);
  const { t } = useTranslation();
  const [lastId, setLastId] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [message, setMessage] = useState('');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [talks, setTalks] = useState([]);
  const [documentHeight, setDocumentHeight] = useState(1000);

  const {
    userStore: { user, isLogin },
  } = useStores();

  const scrollToBottom = () => {
    if (scroller.current) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }

    setTimeout(() => {
      if (scroller.current) {
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }, 100);
  };

  const searchTalks = id => {
    TalkService.selectTalkList(id, list => {
      const next = talks.slice(0);
      if (list && list.length >= pageSize) {
        setLastId(list[list.length - 1].id);
      } else {
        setHasNext(false);
      }

      if (scroller?.current) {
        scroller.current.style.overflow = 'hidden';
      }

      setTalks(
        list
          .sort((a, b) => {
            return moment(a.creationDate).isAfter(moment(b.creationDate)) ? 1 : -1;
          })
          .concat(next),
      );

      if (scroller?.current) {
        scroller.current.style.overflow = 'auto';
      }
    });
  };

  useEffect(() => {
    if (isLogin) {
      searchTalks(0);
      scrollToBottom();
    }

    const html = document.documentElement;
    const height = html.clientHeight;
    setDocumentHeight(height);
  }, [isLogin, date]);

  const onScroll = () => {
    if (hasNext && scroller.current.scrollTop < 100 && talks && talks?.length >= pageSize && (talks?.length || 0) % pageSize === 0) {
      searchTalks(lastId);
    }
  };

  const onScrollDebounced = React.useMemo(() => debounce(onScroll, 500), [hasNext, lastId, talks]);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.removeEventListener('scroll', onScrollDebounced);
      scroller.current.addEventListener('scroll', onScrollDebounced);
    }

    return () => {
      if (scroller.current) {
        scroller.current.removeEventListener('scroll', onScrollDebounced);
      }
    };
  }, [hasNext, talks, lastId]);

  const onSubmit = e => {
    e.preventDefault();

    TalkService.createTalk(
      {
        message,
        date,
      },
      () => {
        setMessage('');
      },
    );
  };

  const onMessage = useCallback(
    info => {
      const {
        data: { type, data },
      } = info;

      switch (type) {
        case 'TALK-MESSAGE': {
          if (date === data.talk.date) {
            const next = talks.slice(0);
            next.push(data.talk);
            setTalks(next);
            scrollToBottom();
          }

          break;
        }

        default: {
          break;
        }
      }
    },
    [talks],
  );

  return (
    <Page
      className="talks-wrapper"
      style={{
        height: `${documentHeight - 70}px`,
      }}
    >
      {isLogin && (
        <SocketClient
          topics={['/sub/talks']}
          onMessage={onMessage}
          onConnect={() => {}}
          onDisconnect={() => {}}
          setRef={client => {
            socket.current = client;
          }}
        />
      )}
      <PageTitle
        className="page-title"
        control={
          <div className="current-date">
            <div>
              <Button
                outline
                onClick={() => {
                  setDate(moment(date, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD'));
                }}
              >
                <i className="fal fa-chevron-left" />
              </Button>
            </div>
            <div className="date">{moment(date, 'YYYY-MM-DD').format('YYYY년 MM월 DD일')}</div>
            <div>
              <Button
                outline
                onClick={() => {
                  setDate(moment(date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD'));
                }}
              >
                <i className="fal fa-chevron-right" />
              </Button>
            </div>
          </div>
        }
      >
        {t('공티 톡')}
      </PageTitle>
      <PageContent padding className="content">
        {!isLogin && (
          <EmptyContent className="empty-content">
            <div>공티 톡을 사용하기 위해서는</div>
            <div>
              <Link to="/users/login">로그인</Link>이 필요합니다.
            </div>
          </EmptyContent>
        )}
        {isLogin && (
          <>
            {talks.length < 1 && (
              <EmptyContent className="empty-content">
                <div>작성된 톡이 없습니다.</div>
              </EmptyContent>
            )}
            {talks.length > 0 && (
              <div className="talk-list">
                <div>
                  <div ref={scroller}>
                    <ul>
                      {talks.map(d => {
                        return (
                          <li key={d.id} className={d.userId === user.id ? 'my' : ''}>
                            <div>
                              <div className="nickname">{d.nickname} 님</div>
                              <div className="message">{d.message}</div>
                              <div className="time">{moment(d.creationDate).format('HH시 mm분')}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <Form className="talk-message" onSubmit={onSubmit}>
              <div className="message">
                <TextArea
                  className="text-area"
                  value={message}
                  rows={2}
                  onChange={val => {
                    setMessage(val);
                  }}
                />
              </div>
              <div className="button">
                <Button type="submit">
                  <i className="fas fa-arrow-alt-up" />
                </Button>
              </div>
            </Form>
          </>
        )}
      </PageContent>
    </Page>
  );
}

export default observer(Talks);
