import React from 'react';
import PropTypes from 'prop-types';
import { RegionalSummaryListPropTypes } from '@/proptypes';
import './RegionalSummaryBarList.scss';
import { filterByTime } from '@/utils/teeUtil';

function RegionalSummaryBarList({ className, regionalSummaryList, onClick, timeCategory }) {
  return (
    <div className={`regional-summary-bar-list-wrapper ${className}`}>
      {regionalSummaryList?.length > 0 && (
        <ul>
          {regionalSummaryList.map((regionalSummary, inx) => {
            const filteredList = regionalSummary.list.filter(info => {
              const filteredTees = info.tees.filter(d => {
                return filterByTime(d, timeCategory);
              });

              return filteredTees.length > 0;
            });

            const teeCount = filteredList.reduce((prev, current) => {
              const filteredTees = current.tees.filter(d => {
                return filterByTime(d, timeCategory);
              });
              return prev + filteredTees.length;
            }, 0);

            const clubCount = filteredList.length;

            return (
              <li
                key={inx}
                onClick={() => {
                  if (clubCount > 0 && onClick) {
                    onClick(regionalSummary);
                  }
                }}
              >
                <div>
                  <div className="name">{regionalSummary.name}</div>
                  {clubCount > 0 && (
                    <div className="count">
                      <span className="tee-number">{teeCount}</span>
                      <span className="tee-text">티</span>
                      <div className="club-info">
                        <span className="number">{clubCount}</span>
                        <span className="text">클럽</span>
                      </div>
                    </div>
                  )}
                  {clubCount < 1 && <div className="count">-</div>}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

RegionalSummaryBarList.defaultProps = {
  className: '',
  regionalSummaryList: [],
  onClick: null,
};

RegionalSummaryBarList.propTypes = {
  className: PropTypes.string,
  regionalSummaryList: RegionalSummaryListPropTypes,
  onClick: PropTypes.func,
  timeCategory: PropTypes.string.isRequired,
};

export default RegionalSummaryBarList;
