import React from 'react';
import { Button, Page, PageContent, PageTitle } from '@/components';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import UserService from '@/services/UserService';
import { useNavigate } from 'react-router-dom';
import './Withdrawal.scss';
import useStores from '@/hooks/useStores';
import { observer } from 'mobx-react';

function Withdrawal() {
  const {
    userStore: { user, setUser },
  } = useStores();

  const navigate = useNavigate();
  const leaveUser = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '회원 탈퇴 확인',
      <div>
        <div>공티에 등록된 사용자의 모든 정보가 즉시 삭제되며, 되돌릴 수 없습니다.</div>
        <div>계속하시겠습니까?</div>
      </div>,
      () => {
        UserService.LeaveUserInfo(() => {
          setUser({
            id: null,
            uuid: null,
            roleCode: null,
            email: null,
            name: null,
          });
          navigate('/');
        });
      },
    );
  };

  return (
    <Page className="withdrawal-wrapper">
      <PageTitle className="page-title">회원 탈퇴</PageTitle>
      <PageContent padding>
        <div className='msg'>아래 버튼을 클릭하여, 공티에 저장된 사용자와 관련된 모든 정보를 삭제하고, 회원 탈퇴를 진행 할 수 있습니다.</div>
        <div className="buttons">
          <Button type="button" size="md" color="danger" onClick={leaveUser}>
            회원 탈퇴
          </Button>
        </div>
        {user?.vendor === 'kakao' && (
          <p className="sns-message">
            <i className="fal fa-info-circle" /> 카카오에 연결된 회원 정보는 삭제되지 않습니다. <span className="path">카카오</span>
            <span className="gt">&gt;</span>
            <span className="path">프로필 관리</span>
            <span className="gt">&gt;</span>
            <span className="path">계정 관리</span>
            <span className="gt">&gt;</span>
            <span className="path">연결된 서비스 관리</span>
            <span className="gt">&gt;</span>
            <span className="path"> 외부 서비스</span>를 통해 직접 삭제할 수 있습니다.
          </p>
        )}
      </PageContent>
    </Page>
  );
}

export default observer(Withdrawal);
