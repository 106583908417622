import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Form, Input, Label, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import dialogUtil from '@/utils/dialogUtil';
import useStores from '@/hooks/useStores';
import './OAuthRegister.scss';

const labelMinWidth = '100px';

function ChangePassword() {
  const {
    userStore: { user },
  } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userInfo, setUserInfo] = useState({
    currentPassword: '',
    password: '',
    passwordConfirm: '',
  });

  const onSubmit = e => {
    e.preventDefault();
    const next = { ...userInfo };

    if (next.password !== next.passwordConfirm) {
      dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '비밀번호 불일치', '입력하신 비밀번호가 일치하지 않습니다.');
      return;
    }

    UserService.changePassword(userInfo, () => {
      dialogUtil.setMessage(MESSAGE_CATEGORY.INFO, '비밀번호 변경', '비밀번호가 변경되었습니다.', () => {
        navigate('/users/my');
      });
    });
  };

  return (
    <Page className="oauth-register-wrapper">
      <PageTitle>비밀번호 변경</PageTitle>
      <PageContent padding>
        {!user.email && (
          <div>
            <div className="no-data">사용자 추가 정보가 등록되어 있지 않습니다.</div>
            <PageButtons
              className="page-button"
              onList={() => {
                navigate('/');
              }}
              onListText="처음으로"
            />
          </div>
        )}
        {user.email && (
          <Form className="form" onSubmit={onSubmit}>
            <div className="register-content">
              <Block className="block">
                <BlockRow>
                  <Label size="sm" minWidth={labelMinWidth}>
                    {t('현재 비밀번호')}
                  </Label>
                  <Input
                    type="password"
                    size="md"
                    required
                    placeholder="현재 비밀번호"
                    value={userInfo.currentPassword}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        currentPassword: val,
                      })
                    }
                    minLength={1}
                  />
                </BlockRow>
                <BlockRow>
                  <Label size="sm" minWidth={labelMinWidth}>
                    {t('변경할 비밀번호')}
                  </Label>
                  <Input
                    type="password"
                    size="md"
                    required
                    placeholder="변경할 비밀번호"
                    value={userInfo.password}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        password: val,
                      })
                    }
                    minLength={1}
                  />
                </BlockRow>
                <BlockRow>
                  <Label size="sm" minWidth={labelMinWidth}>
                    {t('비밀번호 확인')}
                  </Label>
                  <Input
                    type="password"
                    size="md"
                    required
                    placeholder="비밀번호 확인"
                    value={userInfo.passwordConfirm}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        passwordConfirm: val,
                      })
                    }
                    minLength={1}
                  />
                </BlockRow>
              </Block>
              <PageButtons
                className="page-button"
                onList={() => {
                  navigate('/');
                }}
                onListText="취소"
                onSubmit={() => {}}
                onSubmitText="변경"
              />
            </div>
          </Form>
        )}
      </PageContent>
    </Page>
  );
}

export default observer(ChangePassword);
