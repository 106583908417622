import React, { useEffect, useState } from 'react';
import ClubService from '@/services/ClubService';
import { ClubInfoList, Page, PageContent, PageTitle, Tabs } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './Clubs.scss';

const tabs = [
  {
    key: 'all',
    value: '전체',
  },
  {
    key: 'supported',
    value: '지원',
  },
  {
    key: 'unsupported',
    value: '미지원',
  },
  {
    key: 'unpermission',
    value: '허가일 미등록',
  },
];

function Clubs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clubs, setClubs] = useState([]);
  const [tab, setTab] = useState('all');

  useEffect(() => {
    ClubService.selectClubList(tab, list => {
      setClubs(list);
    });
  }, [tab]);

  return (
    <Page className="club-list-wrapper">
      <PageTitle className="page-title" links={[<Link to="/clubs/new">새 클럽</Link>]}>
        {t('클럽 리스트')}
      </PageTitle>
      <PageContent>
        <Tabs className="tab" tabs={tabs} value={tab} onSelect={setTab} />
        <ClubInfoList
          clubs={clubs}
          onClubClick={clubId => {
            navigate(`/clubs/${clubId}`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default Clubs;
