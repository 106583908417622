import React, { useEffect, useRef, useState } from 'react';
import {
  Block,
  BlockRow,
  Button,
  CheckBox,
  Form,
  Input,
  Label,
  Liner,
  Logo,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Page,
  PageButtons,
  PageContent,
  PrivacyContent,
  TermsContent,
  Title,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import dialogUtil from '@/utils/dialogUtil';
import useStores from '@/hooks/useStores';
import { getOption } from '@/utils/storageUtil';
import './OAuthRegister.scss';

const labelMinWidth = '100px';

function Join() {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tel2 = useRef(null);
  const tel3 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userInfo, setUserInfo] = useState({
    email: '',
    name: '',
    nickname: '',
    phone: '',
    tel1: '',
    tel2: '',
    tel3: '',
    country: 'KR',
    language: 'ko',
    password: '',
    passwordConfirm: '',
    privacy: false,
    terms: false,
    recommendationInfo: getOption('user', 'info', 'recommendation') || '',
  });

  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const onSubmit = e => {
    e.preventDefault();
    const next = { ...userInfo };

    if (next.password !== next.passwordConfirm) {
      dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '비밀번호 불일치', '입력하신 비밀번호가 일치하지 않습니다.');
      return;
    }

    if (next.tel1 || next.tel2 || next.tel3) {
      next.phone = `${next.tel1}-${next.tel2}-${next.tel3}`;
      const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      if (!regPhone.test(next.phone)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '전화번호 형식 오류', '올바른 전화번호 형식이 아닙니다.');
        return;
      }
    }

    if (!next.privacy) {
      dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '개인 정보 처리 방침 미동의', '회원 가입을 위해서는 개인 정보 처리 방침에 대한 동의가 필요합니다.');
      return;
    }

    if (!next.terms) {
      dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '이용 약관 미동의', '회원 가입을 위해서는 이용 약관에 대한 동의가 필요합니다.');
      return;
    }

    UserService.join(next, data => {
      userStore.setUser(data);
      navigate('/');
    });
  };

  return (
    <Page className="oauth-register-wrapper">
      <PageContent padding>
        <div className="logo">
          <Logo size="sm" />
        </div>
        <Form className="form" onSubmit={onSubmit}>
          <div className="register-content">
            <Title className="title" type="h2">
              회원가입
            </Title>
            <Block className="block">
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이메일')}
                </Label>
                <Input
                  type="email"
                  size="md"
                  required
                  placeholder="이메일"
                  value={userInfo.email}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      email: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('비밀번호')}
                </Label>
                <Input
                  type="password"
                  size="md"
                  required
                  placeholder="비밀번호"
                  value={userInfo.password}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      password: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('비밀번호')}
                </Label>
                <Input
                  type="password"
                  size="md"
                  required
                  placeholder="비밀번호 확인"
                  value={userInfo.passwordConfirm}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      passwordConfirm: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('닉네임')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="닉네임"
                  value={userInfo.nickname}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      nickname: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이름')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="이름"
                  value={userInfo.name}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      name: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('전화번호')}
                </Label>
                <div className="tel">
                  <Input
                    type="text"
                    className="tel-1"
                    size="md"
                    placeholder="000"
                    value={userInfo.tel1}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel1: val,
                      });

                      if (val?.length === 3 && tel2.current) {
                        tel2.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={3}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel2.current = e;
                    }}
                    type="text"
                    className="tel-2"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel2}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel2: val,
                      });

                      if (val?.length === 4 && tel3.current) {
                        tel3.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={4}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel3.current = e;
                    }}
                    type="text"
                    className="tel-3"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel3}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        tel3: val,
                      })
                    }
                    minLength={1}
                    maxLength={4}
                  />
                </div>
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('추천인 코드')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  placeholder="추천인 코드"
                  value={userInfo.recommendationInfo}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      recommendationInfo: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
            </Block>
            <Block className="agree-block first">
              <BlockRow>
                <div className="info">{t('이용 약관 동의 여부 확인')}</div>
                <div className="message">
                  <Link
                    to="/users/terms"
                    onClick={e => {
                      e.preventDefault();
                      setOpenTerms(true);
                    }}
                  >
                    이용 약관
                  </Link>
                  을 확인하고 동의하셔야 회원 가입이 가능합니다.
                </div>
                <div className="agree">
                  <CheckBox
                    size="sm"
                    type="checkbox"
                    value={userInfo.terms}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        terms: val,
                      })
                    }
                    label="동의합니다"
                  />
                </div>
              </BlockRow>
            </Block>
            <Block className="agree-block">
              <BlockRow>
                <div className="info">{t('개인 정보 처리 방침 확인')}</div>
                <div className="message">
                  <Link
                    to="/users/privacy"
                    onClick={e => {
                      e.preventDefault();
                      setOpenPrivacy(true);
                    }}
                  >
                    개인 정보 처리 방침 확인
                  </Link>
                  을 확인하고 동의하셔야 회원 가입이 가능합니다.
                </div>
                <div className="agree">
                  <CheckBox
                    size="sm"
                    type="checkbox"
                    value={userInfo.privacy}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        privacy: val,
                      })
                    }
                    label="동의합니다"
                  />
                </div>
              </BlockRow>
            </Block>
            <PageButtons
              className="page-button"
              onList={() => {
                navigate('/');
              }}
              onListText="취소"
              onSubmit={() => {}}
              onSubmitText="회원가입"
            />
          </div>
        </Form>
      </PageContent>
      <Modal isOpen={openPrivacy}>
        <ModalHeader className="text-center">개인 정보 처리 방침</ModalHeader>
        <ModalBody>
          <PrivacyContent className="agree-content" />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            size="sm"
            outline
            onClick={() => {
              setOpenPrivacy(false);
            }}
          >
            취소
          </Button>
          <Button
            size="sm"
            outline
            onClick={() => {
              setUserInfo({
                ...userInfo,
                privacy: true,
              });
              setOpenPrivacy(false);
            }}
          >
            <i className="fas fa-check-circle" /> 동의합니다
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openTerms}>
        <ModalHeader className="text-center">이용 약관</ModalHeader>
        <ModalBody>
          <TermsContent className="agree-content" />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            size="sm"
            outline
            onClick={() => {
              setOpenTerms(false);
            }}
          >
            취소
          </Button>
          <Button
            size="sm"
            outline
            onClick={() => {
              setUserInfo({
                ...userInfo,
                terms: true,
              });
              setOpenTerms(false);
            }}
          >
            <i className="fas fa-check-circle" /> 동의합니다
          </Button>
        </ModalFooter>
      </Modal>
    </Page>
  );
}

export default observer(Join);
