import React, { useEffect, useState } from 'react';
import './EditHoliday.scss';
import { Block, BlockRow, CheckBox, Form, Input, Label, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import ConfigService from '@/services/ConfigService';

const labelMinWidth = '100px';

function EditHoliday({ type }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const [holiday, setHoliday] = useState({
    regularYn: false,
    date: '',
    name: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id && type === 'edit') {
      ConfigService.getHoliday(id, info => {
        setHoliday(info);
      });
    }
  }, [type, id]);

  const onSubmit = e => {
    e.preventDefault();

    if (type === 'new') {
      ConfigService.createHoliday(holiday, () => {
        navigate('/configs/holidays');
      });
    } else if (type === 'edit') {
      ConfigService.updateHoliday(id, holiday, () => {
        navigate('/configs/holidays');
      });
    }
  };

  return (
    <Page className="edit-holiday-wrapper">
      <PageTitle>{t('새 휴일')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="block">
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth}>
                {t('정기 휴일')}
              </Label>
              <CheckBox
                size="sm"
                type="checkbox"
                value={holiday.regularYn}
                onChange={val =>
                  setHoliday({
                    ...holiday,
                    regularYn: val,
                  })
                }
              />
            </BlockRow>
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm" required>
                {t('날짜')} ({holiday.regularYn ? 'MMDD' : 'YYYYMMDD'})
              </Label>
              <Input
                type="text"
                size="md"
                placeholder={holiday.regularYn ? 'MMDD' : 'YYYYMMDD'}
                value={holiday.date}
                onChange={val =>
                  setHoliday({
                    ...holiday,
                    date: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label minWidth={labelMinWidth} size="sm">
                {t('이름')}
              </Label>
              <Input
                type="text"
                size="md"
                value={holiday.name}
                onChange={val =>
                  setHoliday({
                    ...holiday,
                    name: val,
                  })
                }
                minLength={1}
              />
            </BlockRow>
          </Block>
          <PageButtons
            onCancel={() => {
              navigate('/configs/holidays');
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditHoliday.defaultProps = {
  type: 'new',
};

EditHoliday.propTypes = {
  type: PropTypes.string,
};

export default EditHoliday;
