import React, { useEffect, useState } from 'react';
import ClubService from '@/services/ClubService';
import { Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './UserClubSetting.scss';
import useStores from '@/hooks/useStores';
import { getOption, setOption } from '@/utils/storageUtil';
import UserService from '@/services/UserService';
import { observer } from 'mobx-react';

function UserClubSetting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clubs, setClubs] = useState([]);
  const [excludes, setExcludes] = useState({});

  const {
    userStore: { user, isLogin, setUserSetting },
  } = useStores();

  useEffect(() => {
    ClubService.selectSupportedClubList(list => {
      setClubs(list);
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      setOption('user', 'info', 'excludes', user.setting?.excludes);
      setExcludes(user.setting?.excludes || {});
    } else {
      let localExcludes = getOption('user', 'info', 'excludes');
      if (!localExcludes) {
        localExcludes = {};
      }

      setExcludes(localExcludes);
    }
  }, [user]);

  const toggleExclude = id => {
    const next = { ...excludes };
    if (next[id]) {
      delete next[id];
    } else {
      next[id] = true;
    }
    setExcludes(next);
    setOption('user', 'info', 'excludes', next);
    setUserSetting({
      ...user.setting,
      excludes: next,
    });

    if (isLogin) {
      const current = { ...user.setting };
      current.excludes = next;
      UserService.updateMySetting(current);
    }
  };

  return (
    <Page className="user-club-setting-wrapper">
      <PageTitle className="page-title">{t('클럽 설정')}</PageTitle>
      <PageContent padding>
        <div className="message">클럽을 클릭하여, 검색 결과에서 제외하고 싶은 클럽을 설정할 수 있습니다.</div>
        <div>
          <ul>
            {clubs.sort((a,b) => {
              return a.name.localeCompare(b.name);
            }).map(d => {
              return (
                <li
                  key={d.id}
                  className={excludes[d.id] ? 'excluded' : ''}
                  onClick={() => {
                    toggleExclude(d.id);
                  }}
                >
                  <div>
                    <div className="checked">
                      {excludes[d.id] && <i className="fal fa-ban" />}
                      {!excludes[d.id] && <i className="fas fa-badge-check" />}
                    </div>
                    <div className="name">{d.name}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <PageButtons
          onBack={() => {
            navigate(-1);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default observer(UserClubSetting);
