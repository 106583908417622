import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Message, Talks } from '@/pages';

function TalksRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Talks />} />
      <Route path="*" element={<Message code="404" />} />
    </Routes>
  );
}

export default TalksRoutes;
