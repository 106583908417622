import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Button, Form, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import UserService from '@/services/UserService';
import useStores from '@/hooks/useStores';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import './My.scss';

const labelMinWidth = '100px';

function My() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [copyText, setCopyText] = useState('복사');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userStore: { user, isLogin },
  } = useStores();

  useEffect(() => {
    if (user.id) {
      UserService.getUserInfo(user.id, info => {
        setUserInfo({ ...info, passwordConfirm: '' });
      });
    }
  }, [user]);

  return (
    <Page className="my-wrapper">
      <PageTitle>내 정보</PageTitle>
      <PageContent padding>
        <Form className="my-info-form">
          <div className="cs-info">
            <ul>
              <li
                onClick={() => {
                  navigate('/users/cs/new');
                }}
                className="enabled"
              >
                <div className="icon">
                  <span>
                    <i className="far fa-people-arrows" />
                  </span>
                </div>
                <div className="line">
                  <div />
                </div>
                <div className="text">1:1 문의</div>
              </li>
              <li
                className={`${isLogin ? 'enabled' : 'disabled'}`}
                onClick={() => {
                  if (isLogin) {
                    navigate('/users/cs/my');
                  }
                }}
              >
                <div className="icon">
                  <span>
                    <i className="far fa-list-alt" />
                  </span>
                </div>
                <div className="line">
                  <div />
                </div>
                <div className="text">문의 내역</div>
              </li>
            </ul>
          </div>
          <div className="register-content">
            <Block className="block">
              {userInfo.vendor && (
                <BlockRow className="social">
                  <Label className="label" minWidth={labelMinWidth} size="sm">
                    {t('간편 로그인')}
                  </Label>
                  <Text className="text-data" white>
                    <span className={`vendor ${userInfo.vendor}`}>{userInfo.vendor}</span>
                    <span>{userInfo.vendorEmail}</span>
                  </Text>
                </BlockRow>
              )}
              {userInfo.vendor && (
                <BlockRow className="social">
                  <Label className="label" minWidth={labelMinWidth} size="sm">
                    {t('회원 번호')}
                  </Label>
                  <Text className="text-data" white>
                    <span>{String(userInfo.id).padStart(10, '0')}</span>
                  </Text>
                </BlockRow>
              )}
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('이메일')}
                </Label>
                {userInfo.hasEmail && (
                  <Text className="text-data" white>
                    <span>{userInfo.email}</span>
                  </Text>
                )}
                {!userInfo.hasEmail && (
                  <Text className="text-data no-email" white>
                    등록된 이메일이 없습니다.
                    <div className="desc">
                      <i className="fas fa-info-circle" /> <Link to="/users/oauth/register">추가 정보</Link>를 등록하면, 직접 로그인 할 수 있습니다.
                    </div>
                  </Text>
                )}
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('이름')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.name || '등록된 이름이 없습니다.'}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('닉네임')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.nickname || '등록된 닉네임이 없습니다.'}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('전화번호')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.phone || '등록된 전화번호가 없습니다.'}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('회원 등급')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.grade === 'SILVER' && <span>실버 회원</span>}
                  {userInfo.grade === 'GOLD' && <span>골드회원</span>}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('추천인 코드')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.recommendationInfo || '-'}
                </Text>
              </BlockRow>
              {userInfo.hasEmail && (
                <BlockRow>
                  <Label className="label" size="sm" minWidth={labelMinWidth}>
                    {t('비밀번호 변경')}
                  </Label>
                  <Text className="text-data" white>
                    <Link to="/users/my/edit/password">비밀번호 변경</Link>
                  </Text>
                </BlockRow>
              )}
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('회원 탈퇴')}
                </Label>
                <Text className="text-data" white>
                  <Link className="leave" to="/users/withdrawal">
                    회원 탈퇴
                  </Link>
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('회원 가입일')}
                </Label>
                <Text className="text-data" white>
                  {moment(userInfo.joinDate).format('YYYY-MM-DD')}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('로그인 횟수')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.loginCount}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('추천 받은 횟수')}
                </Label>
                <Text className="text-data" white>
                  {userInfo.recommendationCount || 0}
                </Text>
              </BlockRow>
              <BlockRow>
                <Label className="label" size="sm" minWidth={labelMinWidth}>
                  {t('추천 공유 링크')}
                </Label>
                <Text className="text-data" white>
                  <span
                    onClick={() => {
                      copy(`${window.location.origin}/users/join?recommendation=${user.id}`);
                    }}
                  >
                    {`${window.location.origin}/users/join?recommendation=${user.id}`}
                  </span>
                  <span className="copy">
                    <Button
                      size="sm"
                      outline
                      onClick={() => {
                        copy(`${window.location.origin}/users/join?recommendation=${user.id}`);
                        setCopyText('복사되었습니다.');
                        setTimeout(() => {
                          setCopyText('복사');
                        }, 1000);
                      }}
                    >
                      {copyText}
                    </Button>
                  </span>
                </Text>
              </BlockRow>
            </Block>
            <PageButtons
              className="page-button"
              onList={() => {
                navigate(-1);
              }}
              onEdit={() => {
                navigate('/users/my/edit');
              }}
              onListText="뒤로"
            />
          </div>
        </Form>
      </PageContent>
    </Page>
  );
}

My.defaultProps = {};

My.propTypes = {};

export default observer(My);
