import * as request from '@/utils/request';

const ClubService = {};

ClubService.selectClubList = (type, successHandler, failHandler) => {
  return request.get(
    '/api/clubs',
    { type },
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.selectClubInfo = (clubId, successHandler, failHandler) => {
  return request.get(
    `/api/clubs/${clubId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.createClub = (club, successHandler, failHandler) => {
  return request.post(
    '/api/clubs',
    club,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.updateClub = (club, successHandler, failHandler) => {
  return request.put(
    `/api/clubs/${club.id}`,
    club,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.deleteClub = (clubId, successHandler, failHandler) => {
  return request.del(
    `/api/clubs/${clubId}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.createImage = (formData, successHandler, failHandler) => {
  return request.post(
    '/api/clubs/images',
    formData,
    res => {
      successHandler(res);
    },
    failHandler,
    null,
    null,
    null,
    true,
  );
};

ClubService.selectSupportedClubList = (successHandler, failHandler) => {
  return request.get(
    '/api/clubs/supported',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ClubService.selectClubReviewList = (clubName, start, successHandler, failHandler) => {
  return request.get(
    `/api/clubs/${clubName}/review`,
    { start },
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default ClubService;
