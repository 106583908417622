import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ClubSummaryPropTypes } from '@/proptypes';
import './ClubSummaryList.scss';
import Liner from '@/components/Liner/Liner';
import { getShortPrice } from '@/utils/numberUtil';
import Img from '@/components/Img/Img';
import { getImageUrl } from '@/utils/commonUtil';
import DayWeather from '@/components/DayWeather/DayWeather';

function ClubSummaryList({ className, clubTeeList, onClick, setTeeInfo, onClubNameClick }) {
  const list = useRef(null);

  useEffect(() => {
    if (list.current) {
      list.current.scrollLeft = 0;
    }
  }, [clubTeeList]);

  return (
    <div className={`club-summary-list-wrapper ${className}`}>
      <div>
        {clubTeeList?.length < 1 && (
          <div className="message">
            <div>
              <div className="icon">
                <i className="fal fa-golf-club" />
              </div>
              <div>예약 가능한 티 정보가 없습니다.</div>
            </div>
          </div>
        )}
        {clubTeeList?.length > 0 && (
          <ul ref={list}>
            {clubTeeList.map((row, inx) => {
              const { club, tees } = row;

              return (
                <li
                  key={inx}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                  }}
                >
                  {club.weather && (
                    <div className="weather-icon">
                      <span
                        className={`${club.weather?.amRain <= 20 ? 'sunny' : ''} ${club.weather?.amRain > 20 && club.weather?.amRain < 50 ? 'cloudy' : ''} ${
                          club.weather?.amRain >= 50 ? 'rainy' : ''
                        }`}
                      >
                        {club.weather?.amRain <= 20 && <i className="fas fa-sun" />}
                        {club.weather?.amRain > 20 && club.weather?.amRain < 50 && <i className="fas fa-cloud" />}
                        {club.weather?.amRain >= 50 && <i className="fas fa-cloud-showers" />}
                      </span>
                    </div>
                  )}
                  <div className="club-info">
                    <div
                      className="club-name"
                      onClick={() => {
                        onClubNameClick(club.name);
                      }}
                    >
                      {club.name}
                    </div>
                    <Liner display="inline-block" width="4px" height="1px" color="light" margin="0 0.25rem" />
                    <div
                      className="tee-count"
                      onClick={() => {
                        setTeeInfo({
                          opened: true,
                          title: club.name,
                          date: club.date,
                          teeList: tees,
                          reservationUrl: club.reservationUrl,
                        });
                      }}
                    >
                      <span>{`${tees.length < 100 ? tees.length : '99+'}`}</span>티
                    </div>
                    <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                    <div>
                      <span
                        className="home-link"
                        onClick={() => {
                          window.open(club.homepageUrl);
                        }}
                      >
                        <i className="fas fa-home" />
                      </span>
                    </div>
                  </div>
                  <div className="image-and-other">
                    <Img className="club-image" src={getImageUrl(club.imagePath)} alt={club.name} />
                    <div className="other">
                      <div className="time-and-price">
                        <span className="icon">
                          <i className="fal fa-clock" />
                        </span>
                        <span className="min-time">
                          {tees[0]?.teeOffTime ? moment(tees[0]?.teeOffTime, 'HH:mm:ss').format('HH:mm') : ''}
                          {tees.length > 1 ? '부터' : ''}
                        </span>
                        <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        <span className="icon">
                          <i className="fal fa-won-sign" />
                        </span>
                        {club.minCost > 0 && <span>{getShortPrice(club.minCost)}</span>}
                        {(club.minCost > 0 || club.maxCost > 0) && club.minCost !== club.maxCost && <span className="wave">~</span>}
                        {club.maxCost > 0 && club.minCost !== club.maxCost && <span>{getShortPrice(club.maxCost)}</span>}
                      </div>
                      <div className="position-info">
                        <div className="address">{club.locationShortName}</div>
                        <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        <div className="position">{Math.round(club.distance * 10) / 10}km</div>
                        <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        <div className="rating">
                          <i className="fas fa-star" /> {club.rating}
                        </div>
                      </div>
                      <div className="weather-and-permission-date">
                        {club.permissionDate && <span>{moment(club.permissionDate).format('YYYY')}년 설립</span>}
                        {!club.permissionDate && <span>-</span>}
                        {club.shortWeatherList?.length === 24 && (
                          <div className="day-weather">
                            <DayWeather list={club.shortWeatherList} />
                          </div>
                        )}
                        {!(club.shortWeatherList?.length === 24) && club.weather && (
                          <span>
                            <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                            <span className="am">{club.weather?.amDesc}</span>
                            <span className="slash">/</span>
                            <span>{club.weather?.pmDesc}</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

ClubSummaryList.defaultProps = {
  className: '',
  clubTeeList: [],
  onClick: null,
  setTeeInfo: null,
  onClubNameClick: null,
};

ClubSummaryList.propTypes = {
  className: PropTypes.string,
  clubTeeList: ClubSummaryPropTypes,
  onClick: PropTypes.func,
  setTeeInfo: PropTypes.func,
  onClubNameClick: PropTypes.func,
};

export default ClubSummaryList;
