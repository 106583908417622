import React from 'react';
import PropTypes from 'prop-types';
import './TeeInfoPopup.scss';
import { ExitButton, Liner, Title } from '@/components';
import moment from 'moment';
import { TeePropTypes } from '@/proptypes';
import { getShortPrice } from '@/utils/numberUtil';

function TeeInfoPopup({ className, teeInfo, isOpen, setOpen }) {
  return (
    <div className={`tee-info-popup-wrapper ${className} ${isOpen ? 'open' : ''}`}>
      <div>
        <div className="exit-button">
          <ExitButton
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Title className="title" type="h2">
          <div
            className="icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>
              <i className="fal fa-chevron-left" />
            </span>
          </div>
          <div className="region-name">
            <div>{teeInfo.title}</div>
            <div className="region-info">
              <div className="region-date">{teeInfo.date?.format('MM월 DD일')}</div>
              <div className="slash">/</div>
              <div className="region-count">{teeInfo.teeList.length}팀</div>
            </div>
          </div>
        </Title>

        <div className="tee-info-list">
          <div>
            <div className="info-content">
              <div>
                <ul>
                  {teeInfo.teeList.map((tee, inx) => {
                    return (
                      <li key={inx}>
                        <div
                          className="tee-time"
                          onClick={() => {
                            if (teeInfo.reservationUrl.indexOf('{') > -1 && teeInfo.reservationUrl.indexOf('}') > -1) {
                              const dateFormat = teeInfo.reservationUrl.substring(teeInfo.reservationUrl.indexOf('{') + 1, teeInfo.reservationUrl.indexOf('}'));
                              const nextUrl = teeInfo.reservationUrl.replace(`{${dateFormat}}`, teeInfo.date?.format(dateFormat));
                              window.open(nextUrl);
                            } else {
                              window.open(teeInfo.reservationUrl);
                            }
                          }}
                        >
                          {moment(tee.teeOffTime, 'hh:mm:ss').format('HH:mm')}
                        </div>
                        <div>
                          <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        </div>
                        <div className="course">{tee.course}</div>
                        <div className="green-fee">
                          {tee.memberGreenFee === tee.nonMemberGreenFee && (
                            <div>
                              <span>{tee.memberGreenFee > 0 ? getShortPrice(tee.memberGreenFee) : ''}</span>
                            </div>
                          )}
                          {tee.memberGreenFee !== tee.nonMemberGreenFee && (
                            <>
                              {tee.memberGreenFee > 0 && (
                                <div>
                                  <span>{getShortPrice(tee.memberGreenFee)}</span>
                                </div>
                              )}
                              {tee.nonMemberGreenFee > 0 && (
                                <div>
                                  <span className="char non-member">
                                    <span>비</span>
                                  </span>
                                  <span>{getShortPrice(tee.nonMemberGreenFee)}</span>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div>
                          <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        </div>
                        <div className="caddy">{tee.caddyYn ? '캐디' : '노캐디'}</div>
                        <div>
                          <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        </div>
                        <div className="link">
                          <span
                            onClick={() => {
                              if (teeInfo.reservationUrl.indexOf('{') > -1 && teeInfo.reservationUrl.indexOf('}') > -1) {
                                const dateFormat = teeInfo.reservationUrl.substring(teeInfo.reservationUrl.indexOf('{') + 1, teeInfo.reservationUrl.indexOf('}'));
                                const nextUrl = teeInfo.reservationUrl.replace(`{${dateFormat}}`, teeInfo.date?.format(dateFormat));
                                window.open(nextUrl);
                              } else {
                                window.open(teeInfo.reservationUrl);
                              }
                            }}
                          >
                            <i className="fas fa-home-alt" />
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TeeInfoPopup.defaultProps = {
  className: '',
  isOpen: false,
};

TeeInfoPopup.propTypes = {
  className: PropTypes.string,
  teeInfo: PropTypes.shape({
    opened: PropTypes.bool,
    title: PropTypes.string,
    reservationUrl: PropTypes.string,
    date: PropTypes.instanceOf(moment),
    teeList: PropTypes.arrayOf(TeePropTypes),
  }).isRequired,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

export default TeeInfoPopup;
