import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Batch, Batches, BatchHistories, BatchHistory, EditBatch, Message } from '@/pages';

function BatchesRoutes() {
  return (
    <Routes>
      <Route path="/new" element={<EditBatch />} />
      <Route path="/:id" element={<Batch />} />
      <Route path="/:id/edit" element={<EditBatch type="edit" />} />
      <Route path="/" element={<Batches />} />
      <Route path="/:jobId/histories" element={<BatchHistories />} />
      <Route path="/:jobId/histories/:historyId" element={<BatchHistory />} />
      <Route path="*" element={<Message code="404" />} />
    </Routes>
  );
}

export default BatchesRoutes;
