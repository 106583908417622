import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './RegionalInfoPopup.scss';
import { ExitButton, Liner, Title } from '@/components';
import moment from 'moment';
import { ClubSummaryPropTypes } from '@/proptypes';
import { filterByTime } from '@/utils/teeUtil';
import { getShortPrice } from '@/utils/numberUtil';

function RegionalInfoPopup({ className, isOpen, setOpen, regionalInfo, timeCategory }) {
  const [tees, setTees] = useState([]);

  useEffect(() => {
    const list = [];
    regionalInfo.clubTees.forEach(clubTee => {
      clubTee.tees.forEach(tee => {
        list.push({ ...tee, clubName: clubTee.club.name, reservationUrl: clubTee.club.reservationUrl });
      });
    });

    list.sort((a, b) => {
      if (moment(a.teeOffTime, 'HH:mm:ss').isSame(moment(b.teeOffTime, 'HH:mm:ss'))) {
        return 0;
      }

      return moment(a.teeOffTime, 'HH:mm:ss').isAfter(moment(b.teeOffTime, 'HH:mm:ss')) ? 1 : -1;
    });

    setTees(
      list.filter(d => {
        return filterByTime(d, timeCategory);
      }),
    );
  }, [regionalInfo, timeCategory]);

  return (
    <div className={`regional-info-popup-wrapper ${className} ${isOpen ? 'open' : ''}`}>
      <div>
        <div className="exit-button">
          <ExitButton
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Title className="title" type="h2">
          <div
            className="icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>
              <i className="fal fa-chevron-left" />
            </span>
          </div>
          <div className="region-name">
            <div>{regionalInfo.title}</div>
            <div className="region-info">
              <div className="region-date">{regionalInfo.date?.format('MM월 DD일')}</div>
              <div className="slash">/</div>
              <div className="region-count">{tees.length}팀</div>
            </div>
          </div>
        </Title>
        <div className="tee-info-list">
          <div>
            <div className="info-content">
              <div>
                {(!tees || tees.length < 1) && (
                  <div className="message">
                    <div>
                      <div className="icon">
                        <i className="fal fa-golf-club" />
                      </div>
                      <div>예약 가능한 티 정보가 없습니다.</div>
                    </div>
                  </div>
                )}
                {tees?.length > 0 && (
                  <ul>
                    {tees.map((tee, inx) => {
                      return (
                        <li key={inx}>
                          <div className="club-info">
                            <div className="club-name">
                              <span
                                onClick={() => {
                                  if (tee.reservationUrl.indexOf('{') > -1 && tee.reservationUrl.indexOf('}') > -1) {
                                    const dateFormat = tee.reservationUrl.substring(tee.reservationUrl.indexOf('{') + 1, tee.reservationUrl.indexOf('}'));
                                    const nextUrl = tee.reservationUrl.replace(`{${dateFormat}}`, tee.date?.format(dateFormat));
                                    window.open(nextUrl);
                                  } else {
                                    window.open(tee.reservationUrl);
                                  }
                                }}
                              >
                                {tee.clubName}
                              </span>
                            </div>
                            <div className="tee-course">
                              <div className="tee-time">{moment(tee.teeOffTime, 'hh:mm:ss').format('HH:mm')}</div>
                              <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                              <div className="course">{tee.course}</div>
                            </div>
                          </div>
                          <div className="green-fee">
                            {tee.memberGreenFee === tee.nonMemberGreenFee && (
                              <div>
                                <span>{tee.memberGreenFee > 0 ? getShortPrice(tee.memberGreenFee) : ''}</span>
                              </div>
                            )}
                            {tee.memberGreenFee !== tee.nonMemberGreenFee && (
                              <>
                                {tee.memberGreenFee > 0 && (
                                  <div>
                                    <span>{getShortPrice(tee.memberGreenFee)}</span>
                                  </div>
                                )}
                                {tee.nonMemberGreenFee > 0 && (
                                  <div>
                                    <span className="char non-member">
                                      <span>비</span>
                                    </span>
                                    <span>{getShortPrice(tee.nonMemberGreenFee)}</span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div>
                            <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                          </div>
                          <div className="link">
                            <span
                              onClick={() => {
                                if (tee.reservationUrl.indexOf('{') > -1 && tee.reservationUrl.indexOf('}') > -1) {
                                  const dateFormat = tee.reservationUrl.substring(tee.reservationUrl.indexOf('{') + 1, tee.reservationUrl.indexOf('}'));
                                  const nextUrl = tee.reservationUrl.replace(`{${dateFormat}}`, tee.date?.format(dateFormat));
                                  window.open(nextUrl);
                                } else {
                                  window.open(tee.reservationUrl);
                                }
                              }}
                            >
                              <i className="fas fa-home-alt" />
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RegionalInfoPopup.defaultProps = {
  className: '',
  isOpen: false,
};

RegionalInfoPopup.propTypes = {
  className: PropTypes.string,
  regionalInfo: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.instanceOf(moment),
    clubTees: ClubSummaryPropTypes,
  }).isRequired,

  timeCategory: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

export default RegionalInfoPopup;
