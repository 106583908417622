import * as request from '@/utils/request';

const ConfigService = {};

ConfigService.getHolidays = (successHandler, failHandler) => {
  return request.get(
    '/api/configs/holidays',
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.getHoliday = (id, successHandler, failHandler) => {
  return request.get(
    `/api/configs/holidays/${id}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.deleteHoliday = (id, successHandler, failHandler) => {
  return request.del(
    `/api/configs/holidays/${id}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.createHoliday = (holiday, successHandler, failHandler) => {
  return request.post(
    '/api/configs/holidays',
    holiday,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.updateHoliday = (id, holiday, successHandler, failHandler) => {
  return request.put(
    `/api/configs/holidays/${id}`,
    holiday,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.deleteRedis = (successHandler, failHandler) => {
  return request.del(
    '/api/configs/systems/caches',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.deleteRedisTees = (successHandler, failHandler) => {
  return request.del(
    '/api/configs/systems/caches/tees',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.selectSystemInfo = (successHandler, failHandler) => {
  return request.get(
    '/api/configs/systems/info',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.selectCodeList = (type, successHandler, failHandler) => {
  return request.get(
    '/api/configs/settings/codes',
    { type },
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.selectCodeInfo = (type, code, successHandler, failHandler) => {
  return request.get(
    `/api/configs/settings/codes/${code}/types/${type}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.deleteCodeInfo = (type, code, successHandler, failHandler) => {
  return request.del(
    `/api/configs/settings/codes/${code}/types/${type}`,
    {},
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.createCodeInfo = (code, successHandler, failHandler) => {
  return request.post(
    '/api/configs/settings/codes',
    code,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.updateCodeInfo = (type, code, codeInfo, successHandler, failHandler) => {
  return request.put(
    `/api/configs/settings/codes/${code}/types/${type}`,
    codeInfo,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

ConfigService.selectSystemVersion = (successHandler, failHandler) => {
  return request.get(
    '/api/configs/systems/version',
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default ConfigService;
