import React, { useEffect } from 'react';
import { Page, PageButtons, PageContent, PageTitle, PrivacyContent } from '@/components';
import { useNavigate } from 'react-router-dom';

function Privacy() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page className="privacy-wrapper">
      <PageTitle className="page-title">개인 정보 처리 방침</PageTitle>
      <PageContent className='page-content' padding>
        <PrivacyContent />
        <PageButtons
          onList={() => {
            navigate('/');
          }}
          onListText="메인"
          onCancel={() => {
            navigate(-1);
          }}
          onCancelText="뒤로"
        />
      </PageContent>
    </Page>
  );
}

export default Privacy;
