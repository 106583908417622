import React from 'react';
import { Common, Header, Main, Message } from '@/pages';
import { Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import ClubsRoutes from '@/pages/clubs';
import ConfigsRoutes from '@/pages/configs';
import UsersRoutes from '@/pages/users';
import BatchesRoutes from '@/pages/batches';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import RouteChangeTracker from '@/components/RouteChangeTracker/RouteChangeTracker';
import './App.scss';
import { Footer, Navigator } from '@/components';
import TalksRoutes from '@/pages/talks';
import MobileMenu from '@/components/MobileMenu/MobileMenu';

function App() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://22dc925e6581436991a889111d0900ef@o518707.ingest.sentry.io/6559528',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  const location = useLocation();

  RouteChangeTracker();

  return (
    <div className={`app-wrapper ${location.pathname === '/' ? 'is-main' : ''} ${location.pathname.split('/').join('')}`}>
      <Common />
      <Header />
      <MobileMenu />
      <main className="main-content">
        <Routes>
          <Route path="/clubs/*" element={<ClubsRoutes />} />
          <Route path="/configs/*" element={<ConfigsRoutes />} />
          <Route path="/users/*" element={<UsersRoutes />} />
          <Route path="/batches/*" element={<BatchesRoutes />} />
          <Route path="/talks/*" element={<TalksRoutes />} />
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Message code="404" />} />
        </Routes>
      </main>
      <Footer className={`footer ${location.pathname === '/talks' ? 'talks-footer' : ''}`} />
      <div className="navigator">
        <Navigator />
      </div>
    </div>
  );
}

export default observer(App);
