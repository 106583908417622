import React, { useEffect, useState } from 'react';
import { Page, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './Holidays.scss';
import ConfigService from '@/services/ConfigService';
import moment from 'moment';

function Holidays() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    ConfigService.getHolidays(d => {
      setHolidays(d);
    });
  }, []);

  return (
    <Page className="holidays-wrapper">
      <PageTitle className="page-title" links={[<Link to="/configs/holidays/new">새 휴일</Link>]}>
        {t('휴일 리스트')}
      </PageTitle>
      <PageContent padding>
        <div className="content">
          <div className="title">정기휴일</div>
          <ul>
            {holidays
              .filter(d => d.regularYn)
              .map(holiday => {
                return (
                  <li
                    key={holiday.id}
                    onClick={() => {
                      navigate(`/configs/holidays/${holiday.id}`);
                    }}
                  >
                    <div className="date">{moment(holiday.date, 'MMDD').format('MM월 DD일')}</div>
                    <div className="name">{holiday.name}</div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="content">
          <div className="title second">임시휴일</div>
          <ul>
            {holidays
              .filter(d => !d.regularYn)
              .map(holiday => {
                return (
                  <li
                    key={holiday.id}
                    onClick={() => {
                      navigate(`/configs/holidays/${holiday.id}`);
                    }}
                  >
                    <div className="date">{moment(holiday.date, 'YYYYMMDD').format('YYYY년 MM월 DD일')}</div>
                    <div className="name">{holiday.name}</div>
                  </li>
                );
              })}
          </ul>
        </div>
      </PageContent>
    </Page>
  );
}

export default Holidays;
