import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {getAddressText, getPosition} from '@/utils/mapUtil';
import useStores from '@/hooks/useStores';
import {observer} from 'mobx-react';
import NaverMapService from '@/services/NaverMapService';
import {getOption} from '@/utils/storageUtil';
import './StartPosition.scss';

function StartPosition({ className, setOpenMap }) {
  const {
    conditionStore: { address, setAddress, setCoords, coords },
  } = useStores();

  const lastCoords = useRef({});

  const getAddress = (longitude, latitude) => {
    NaverMapService.getReverseGeocoding(latitude, longitude, d => {
      if (d?.results?.length > 0) {
        setAddress(d.results[0]);
      } else {
        setAddress(null);
      }
    });
  };

  useEffect(() => {
    getPosition((longitude, latitude) => {
      const storedLastCoords = getOption('map', 'position', 'last');

      if (storedLastCoords) {
        lastCoords.current = {
          latitude: storedLastCoords.latitude,
          longitude: storedLastCoords.longitude,
        };
        setCoords({
          latitude: storedLastCoords.latitude,
          longitude: storedLastCoords.longitude,
        });
        getAddress(storedLastCoords.longitude, storedLastCoords.latitude);
      } else {
        lastCoords.current = {
          latitude,
          longitude,
        };
        setCoords({
          latitude,
          longitude,
        });
        getAddress(longitude, latitude);
      }
    });
  }, []);

  useEffect(() => {
    if (coords) {
      if (coords.latitude !== lastCoords.current.latitude || coords.longitude !== lastCoords.current.longitude) {
        getAddress(coords.longitude, coords.latitude);
      }
    }
  }, [coords]);

  return (
    <div className={`start-position-wrapper ${className}`}>
      <div>
        <span
          onClick={() => {
            setOpenMap(true);
          }}
        >
          <span className="icon">
            <i className="fal fa-map-marker-alt" />
          </span>
          {address && (
            <span className="address">
              <span>{getAddressText(address, 'area1')}</span>
              <span>{getAddressText(address, 'area2')}</span>
              <span>{getAddressText(address, 'area3')}</span>
            </span>
          )}
          {!address && <span className="address">위치 정보가 없습니다</span>}
        </span>
      </div>
    </div>
  );
}

StartPosition.defaultProps = {
  className: '',
};

StartPosition.propTypes = {
  className: PropTypes.string,
  setOpenMap: PropTypes.func.isRequired,
};

export default observer(StartPosition);
