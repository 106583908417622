import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Img from '@/components/Img/Img';
import { ClubSummaryPropTypes } from '@/proptypes';
import { getImageUrl } from '@/utils/commonUtil';
import { getShortPrice } from '@/utils/numberUtil';
import Liner from '../Liner/Liner';
import './SimpleClubList.scss';

function SimpleClubList({ className, clubTeeList, onClick, setTeeInfo, order, orderDirAsc, onClubNameClick }) {
  const orderedList = React.useMemo(() => {
    if (order === 'distance') {
      return (clubTeeList || []).sort((a, b) => {
        if (orderDirAsc) {
          return a.club.distance - b.club.distance;
        }

        return b.club.distance - a.club.distance;
      });
    }

    if (order === 'name') {
      return (clubTeeList || []).sort((a, b) => {
        if (orderDirAsc) {
          return a.club.name.localeCompare(b.club.name);
        }
        return b.club.name.localeCompare(a.club.name);
      });
    }

    if (order === 'rating') {
      return (clubTeeList || []).sort((a, b) => {
        if (orderDirAsc) {
          return a.club.rating - b.club.rating;
        }

        return b.club.rating - a.club.rating;
      });
    }

    if (order === 'teeTime') {
      return (clubTeeList || []).sort((a, b) => {
        if (orderDirAsc) {
          if (moment(a.tees[0].teeOffTime, 'HH:mm:ss').isSame(moment(b.tees[0].teeOffTime, 'HH:mm:ss'))) {
            return 0;
          }

          return moment(a.tees[0].teeOffTime, 'HH:mm:ss').isAfter(moment(b.tees[0].teeOffTime, 'HH:mm:ss')) ? 1 : -1;
        }

        if (moment(b.tees[0].teeOffTime, 'HH:mm:ss').isSame(moment(a.tees[0].teeOffTime, 'HH:mm:ss'))) {
          return 0;
        }

        return moment(b.tees[0].teeOffTime, 'HH:mm:ss').isAfter(moment(a.tees[0].teeOffTime, 'HH:mm:ss')) ? 1 : -1;
      });
    }

    if (order === 'price') {
      return (clubTeeList || []).sort((a, b) => {
        if (orderDirAsc) {
          return (Math.min(a.club.minCost, a.club.maxCost) || Infinity) - (Math.min(b.club.minCost, b.club.maxCost) || Infinity);
        }

        return (Math.min(b.club.minCost, b.club.maxCost) || Infinity) - (Math.min(a.club.minCost, a.club.maxCost) || Infinity);
      });
    }

    return clubTeeList;
  }, [clubTeeList, order, orderDirAsc]);

  return (
    <div className={`simple-club-list-wrapper ${className}`}>
      <div>
        {orderedList?.length < 1 && (
          <div className="message">
            <div>
              <div className="icon">
                <i className="fal fa-golf-club" />
              </div>
              <div>예약 가능한 티 정보가 없습니다.</div>
            </div>
          </div>
        )}
        {orderedList?.length > 0 && (
          <ul>
            {orderedList.map((row, inx) => {
              const { club, tees } = row;

              return (
                <li
                  key={inx}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                  }}
                >
                  <div className="club-info">
                    <Img className="club-image" src={getImageUrl(club.imagePath)} alt={club.name} />
                    <div className="general-info">
                      <div className="club-name">
                        <div
                          className="name"
                          onClick={() => {
                            onClubNameClick(club.name);
                          }}
                        >
                          {club.name}
                        </div>
                        {(club.minCost > 0 || club.maxCost > 0) && <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />}
                        <div className="cost">
                          {club.minCost > 0 && <span>{getShortPrice(club.minCost)}</span>}
                          {club.minCost > 0 && club.maxCost > 0 && club.minCost !== club.maxCost && <span className="wave">~</span>}
                          {club.maxCost > 0 && club.minCost !== club.maxCost && <span>{getShortPrice(club.maxCost)}</span>}
                        </div>
                      </div>
                      <div className="position-info">
                        <div className="position">{Math.round(club.distance * 10) / 10}km</div>
                        <Liner display="inline-block" width="1px" height="8px" color="light" margin="0 0.5rem" />
                        <div className="tee-time">
                          {tees[0]?.teeOffTime ? moment(tees[0]?.teeOffTime, 'HH:mm:ss').format('HH:mm') : ''} {tees.length > 1 ? '부터' : ''}
                        </div>
                        <Liner display="inline-block" width="1px" height="8px" color="light" margin="0 0.5rem" />
                        <div className="rating">
                          <i className="fas fa-star" /> {club.rating}
                        </div>
                      </div>
                    </div>
                    <div className="count-info">
                      <div
                        className="count"
                        onClick={() => {
                          setTeeInfo({
                            title: club.name,
                            date: club.date,
                            teeList: tees,
                            reservationUrl: club.reservationUrl,
                          });
                        }}
                      >
                        <span>{`${tees.length < 100 ? tees.length : '99+'}`}티</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

SimpleClubList.defaultProps = {
  className: '',
  clubTeeList: [],
  onClick: null,
  setTeeInfo: null,
  order: null,
  orderDirAsc: true,
};

SimpleClubList.propTypes = {
  className: PropTypes.string,
  clubTeeList: ClubSummaryPropTypes,
  onClick: PropTypes.func,
  setTeeInfo: PropTypes.func,
  order: PropTypes.string,
  orderDirAsc: PropTypes.bool,
  onClubNameClick: PropTypes.func.isRequired,
};

export default SimpleClubList;
