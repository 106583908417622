import React from 'react';
import PropTypes from 'prop-types';
import './Logo.scss';
import logo from '@/images/gongtee2.svg';

function Logo({ className, size }) {
  return (
    <div className={`logo-wrapper ${className} size-${size}`}>
      <img src={logo} alt="gongtee" />
    </div>
  );
}

Logo.defaultProps = {
  className: '',
  size: 'md',
};

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

export default Logo;
