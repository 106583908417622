import React, { useEffect, useRef, useState } from 'react';
import { Block, BlockRow, Form, Input, Label, Liner, Page, PageButtons, PageContent, PageTitle, TextArea } from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import dialogUtil from '@/utils/dialogUtil';
import useStores from '@/hooks/useStores';
import './EditCustomerService.scss';
import CustomerInquiryService from '@/services/CustomerInquiryService';

const labelMinWidth = '100px';

function EditCustomerService() {
  const {
    userStore: { user, isLogin },
  } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tel2Element = useRef(null);
  const tel3Element = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [inquiry, setInquiry] = useState({
    subject: '',
    content: '',
    phone: '',
    email: '',
    name: '',
  });

  const [tel, setTel] = useState({
    tel1: '',
    tel2: '',
    tel3: '',
  });

  useEffect(() => {
    if (isLogin) {
      setInquiry({ ...inquiry, name: user.name, email: user.email || user.vendorEmail });
    }
  }, [user, isLogin]);

  const onSubmit = e => {
    e.preventDefault();
    const next = { ...inquiry };

    if (tel.tel1 || tel.tel2 || tel.tel3) {
      next.phone = `${tel.tel1}-${tel.tel2}-${tel.tel3}`;
      const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      if (!regPhone.test(next.phone)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '전화번호 형식 오류', '올바른 전화번호 형식이 아닙니다.');
        return;
      }
    }

    CustomerInquiryService.createCustomerInquiry(next, () => {
      navigate('/users/cs');
    });
  };

  return (
    <Page className="edit-customer-service-wrapper">
      <PageTitle>1:1 문의 등록</PageTitle>
      <PageContent padding>
        <Form className="form" onSubmit={onSubmit}>
          <div className="register-content">
            <Block className="block">
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('제목')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="제목을 입력해주세요"
                  value={inquiry.subject}
                  onChange={val =>
                    setInquiry({
                      ...inquiry,
                      subject: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('내용')}
                </Label>
                <TextArea
                  placeholder={t('문의 내용을 입력해주세요.')}
                  value={inquiry.content}
                  rows={8}
                  onChange={val => {
                    setInquiry({
                      ...inquiry,
                      content: val,
                    });
                  }}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이름')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="이름"
                  value={inquiry.name}
                  onChange={val =>
                    setInquiry({
                      ...inquiry,
                      name: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이메일')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="이메일"
                  value={inquiry.email}
                  onChange={val =>
                    setInquiry({
                      ...inquiry,
                      email: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('전화번호')}
                </Label>
                <div className="tel">
                  <Input
                    type="text"
                    className="tel-1"
                    size="md"
                    placeholder="000"
                    value={tel.tel1}
                    onChange={val => {
                      setTel({
                        ...tel,
                        tel1: val,
                      });

                      if (val?.length === 3 && tel2Element.current) {
                        tel2Element.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={3}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel2Element.current = e;
                    }}
                    type="text"
                    className="tel-2"
                    size="md"
                    placeholder="0000"
                    value={tel.tel2}
                    onChange={val => {
                      setTel({
                        ...tel,
                        tel2: val,
                      });

                      if (val?.length === 4 && tel3Element.current) {
                        tel3Element.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={4}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel3Element.current = e;
                    }}
                    type="text"
                    className="tel-3"
                    size="md"
                    placeholder="0000"
                    value={tel.tel3}
                    onChange={val =>
                      setTel({
                        ...tel,
                        tel3: val,
                      })
                    }
                    minLength={1}
                    maxLength={4}
                  />
                </div>
              </BlockRow>
            </Block>
            <PageButtons
              className="page-button"
              onList={() => {
                navigate(-1);
              }}
              onListText="취소"
              onSubmit={() => {}}
              onSubmitText="저장"
            />
          </div>
        </Form>
      </PageContent>
    </Page>
  );
}

export default observer(EditCustomerService);
