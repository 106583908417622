import React, { useEffect, useState } from 'react';
import { Page, PageContent, PageTitle, Tabs, UserInfoList } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserService from '@/services/UserService';
import './Users.scss';

const tabs = [
  {
    key: '1d',
    value: '24시간 가입',
  },
  {
    key: '7d',
    value: '7일 내 가입',
  },
  {
    key: '30d',
    value: '30일 내 가입',
  },
  {
    key: '7l',
    value: '7일 내 로그인',
  },
  {
    key: '30l',
    value: '30일 내 로그인',
  },
  {
    key: 'all',
    value: '전체',
  },
  {
    key: 'sns',
    value: 'OAUTH 가입자',
  },
  {
    key: 'email',
    value: '이메일 가입자',
  },
];

function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [params, setParams] = useSearchParams();

  const tab = params.get('tab') || '1d';

  useEffect(() => {
    UserService.getUserList(tab, list => {
      setUsers(list);
    });
  }, [tab]);

  return (
    <Page className="users-wrapper">
      <PageTitle className="page-title">
        {t('사용자 리스트')} ({users?.length}명)
      </PageTitle>
      <PageContent padding>
        <Tabs
          className="tab"
          tabs={tabs}
          value={tab}
          onSelect={val => {
            params.set('tab', val);
            setParams(params);
          }}
        />
        <UserInfoList
          users={users}
          onUserClick={userId => {
            navigate(`/configs/users/${userId}`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default Users;
