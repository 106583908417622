import React, { useEffect, useRef, useState } from 'react';
import { Block, BlockRow, Form, Input, Label, Liner, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import dialogUtil from '@/utils/dialogUtil';
import useStores from '@/hooks/useStores';
import './OAuthRegister.scss';

const labelMinWidth = '100px';

function EditMy() {
  const {
    userStore: { user },
    userStore,
  } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tel2Element = useRef(null);
  const tel3Element = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userInfo, setUserInfo] = useState({
    name: '',
    phone: '',
    tel1: '',
    tel2: '',
    tel3: '',
  });

  useEffect(() => {
    if (user.id) {
      UserService.getUserInfo(user.id, info => {
        const { phone } = info;
        if (phone) {
          const [tel1, tel2, tel3] = phone.split('-');
          setUserInfo({ ...info, tel1, tel2, tel3 });
        } else {
          setUserInfo(info);
        }
      });
    }
  }, [user]);

  const onSubmit = e => {
    e.preventDefault();
    const next = { ...userInfo };

    if (next.tel1 || next.tel2 || next.tel3) {
      next.phone = `${next.tel1}-${next.tel2}-${next.tel3}`;
      const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      if (!regPhone.test(next.phone)) {
        dialogUtil.setMessage(MESSAGE_CATEGORY.WARNING, '전화번호 형식 오류', '올바른 전화번호 형식이 아닙니다.');
        return;
      }
    }

    if (!next.tel1 && !next.tel2 && !next.tel3) {
      next.phone = '';
    }

    UserService.updateMyInfo(next, data => {
      userStore.setUser(data);
      navigate('/users/my');
    });
  };

  return (
    <Page className="oauth-register-wrapper">
      <PageTitle>내 정보 변경</PageTitle>
      <PageContent padding>
        <Form className="form" onSubmit={onSubmit}>
          <div className="register-content">
            <Block className="block">
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('이름')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="이름"
                  value={userInfo.name}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      name: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('닉네임')}
                </Label>
                <Input
                  type="text"
                  size="md"
                  required
                  placeholder="닉네임"
                  value={userInfo.nickname}
                  onChange={val =>
                    setUserInfo({
                      ...userInfo,
                      nickname: val,
                    })
                  }
                  minLength={1}
                />
              </BlockRow>
              <BlockRow>
                <Label size="sm" minWidth={labelMinWidth}>
                  {t('전화번호')}
                </Label>
                <div className="tel">
                  <Input
                    type="text"
                    className="tel-1"
                    size="md"
                    placeholder="000"
                    value={userInfo.tel1}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel1: val,
                      });

                      if (val?.length === 3 && tel2Element.current) {
                        tel2Element.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={3}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel2Element.current = e;
                    }}
                    type="text"
                    className="tel-2"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel2}
                    onChange={val => {
                      setUserInfo({
                        ...userInfo,
                        tel2: val,
                      });

                      if (val?.length === 4 && tel3Element.current) {
                        tel3Element.current.focus();
                      }
                    }}
                    minLength={1}
                    maxLength={4}
                  />
                  <Liner display="inline-block" width="10px" height="1px" color="light" margin="0 0.25rem" />
                  <Input
                    onRef={e => {
                      tel3Element.current = e;
                    }}
                    type="text"
                    className="tel-3"
                    size="md"
                    placeholder="0000"
                    value={userInfo.tel3}
                    onChange={val =>
                      setUserInfo({
                        ...userInfo,
                        tel3: val,
                      })
                    }
                    minLength={1}
                    maxLength={4}
                  />
                </div>
              </BlockRow>
            </Block>
            <PageButtons
              className="page-button"
              onList={() => {
                navigate(-1);
              }}
              onListText="취소"
              onSubmit={() => {}}
              onSubmitText="저장"
            />
          </div>
        </Form>
      </PageContent>
    </Page>
  );
}

export default observer(EditMy);
