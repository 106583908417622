import React from 'react';
import PropTypes from 'prop-types';
import { ClubInfoListPropTypes } from '@/proptypes';
import './ClubInfoList.scss';
import { getImageUrl } from '@/utils/commonUtil';
import Liner from '@/components/Liner/Liner';
import Img from '@/components/Img/Img';

function ClubInfoList({ className, clubs, onClubClick }) {
  return (
    <div className={`club-info-list-wrapper ${className}`}>
      <div>
        {clubs?.length > 0 && (
          <ul>
            {clubs.map((club, inx) => {
              return (
                <li
                  key={inx}
                  onClick={() => {
                    if (onClubClick) {
                      onClubClick(club.id);
                    }
                  }}
                >
                  <Img className="club-image" src={getImageUrl(club.imagePath)} alt={club.name} />
                  <div className="name">
                    {club.name}
                    {club.paused && <span className="paused">PAUSED</span>}
                  </div>
                  <div className="location">
                    <span className="location-short-name">{club.locationShortName}</span>
                    <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                    <span className="rating">
                      <i className="fas fa-star" /> {club.rating}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

ClubInfoList.defaultProps = {
  className: '',
  clubs: [],
  onClubClick: null,
};

ClubInfoList.propTypes = {
  className: PropTypes.string,
  clubs: ClubInfoListPropTypes,
  onClubClick: PropTypes.func,
};

export default ClubInfoList;
