import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './NaverMap.scss';
import useStores from '@/hooks/useStores';
import { observer } from 'mobx-react';
import Button from '@/components/Button/Button';
import Title from '@/components/Title';
import ExitButton from '@/components/ExitButton/ExitButton';
import { getPosition } from '@/utils/mapUtil';
import { setOption } from '@/utils/storageUtil';

function NaverMap({ className, setOpen }) {
  const {
    conditionStore: { coords, setCoords },
  } = useStores();

  const [mapCoords, setMapCoords] = useState({});

  const mapElement = useRef(null);
  const mapObject = useRef(null);
  const mapMarker = useRef(null);

  useEffect(() => {
    if (coords) {
      setMapCoords(coords);
    }
    // eslint-disable-next-line no-undef
    mapObject.current = new naver.maps.Map(mapElement.current, {
      // eslint-disable-next-line no-undef
      center: new naver.maps.LatLng(coords?.latitude || 37.5666805, coords?.longitude || 126.9784147),
      zoom: 15,
    });

    // eslint-disable-next-line no-undef
    mapMarker.current = new naver.maps.Marker({
      // eslint-disable-next-line no-undef
      position: new naver.maps.LatLng(coords?.latitude || 37.5666805, coords?.longitude || 126.9784147),
      map: mapObject.current,
    });

    // eslint-disable-next-line no-undef
    naver.maps.Event.addListener(mapObject.current, 'click', e => {
      mapMarker.current.setPosition(e.coord);
      setMapCoords({
        latitude: e.coord.y,
        longitude: e.coord.x,
      });
    });
  }, [coords]);

  const goMarkerCurrentPosition = () => {
    getPosition((longitude, latitude) => {
      mapMarker.current.setPosition({
        y: latitude,
        x: longitude,
      });

      mapObject.current.panTo({
        y: latitude,
        x: longitude,
      });

      setMapCoords({
        latitude,
        longitude,
      });
    });
  };

  return (
    <div className={`naver-map-wrapper ${className}`}>
      <div>
        <div className="title-content">
          <Title className="title" type="h2">
            출발 위치 선택
          </Title>
          <div>
            <ExitButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="map-content">
          <div ref={mapElement} />
          <span
            className="go-current-position"
            onClick={() => {
              goMarkerCurrentPosition();
            }}
          >
            현재 위치로
          </span>
        </div>
        <div className="map-button">
          <Button
            outline
            size="md"
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            outline
            size="md"
            onClick={() => {
              setOption('map', 'position', 'last', {
                latitude: mapCoords.latitude,
                longitude: mapCoords.longitude,
              });
              setCoords(mapCoords);
              setOpen(false);
            }}
          >
            확인
          </Button>
        </div>
      </div>
    </div>
  );
}

NaverMap.defaultProps = {
  className: '',
};

NaverMap.propTypes = {
  className: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
};

export default observer(NaverMap);
