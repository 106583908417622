import React from 'react';

const MENUS = [
  {
    to: '/',
    icon: <i className="fas fa-home-alt" />,
    name: '예약 정보',
    admin: false,
    pc: true,
  },
  {
    to: '/users/clubs',
    icon: <i className="fas fa-golf-club" />,
    name: '클럽 설정',
    admin: false,
    pc: true,
  },
  {
    to: '/talks',
    icon: <i className="fal fa-comment-alt-smile" />,
    name: '공티 톡',
    admin: true,
    pc: false,
  },
  {
    to: '/users/cs',
    icon: <i className="fal fa-bell-on" />,
    name: '고객 센터',
    admin: false,
    pc: true,
  },
  {
    to: '/users/login',
    icon: <i className="fal fa-door-closed" />,
    name: '로그인',
    admin: false,
    login: false,
  },
  {
    to: '/users/my',
    icon: <i className="fal fa-smile" />,
    name: '내 정보',
    admin: false,
    login: true,
    pc: true,
  },
  {
    to: '/clubs',
    icon: <i className="fal fa-hotel" />,
    name: '클럽 관리',
    admin: true,
    login: true,
    pc: true,
  },
  {
    to: '/configs/users',
    icon: <i className="fal fa-chess" />,
    name: '사용자 관리',
    admin: true,
    login: true,
    pc: true,
  },
  {
    to: '/batches',
    icon: <i className="fal fa-clock" />,
    name: '배치 관리',
    admin: true,
    login: true,
    pc: true,
  },
  {
    to: '/configs',
    icon: <i className="fal fa-cog" />,
    name: '설정',
    admin: true,
    login: true,
    pc: true,
  },
];

const DUMMY = {};

export { MENUS, DUMMY };
