import React from 'react';
import PropTypes from 'prop-types';
import { RegionalSummaryListPropTypes } from '@/proptypes';
import './RegionalSummaryList.scss';
import { filterByTime } from '@/utils/teeUtil';

function RegionalSummaryList({ className, regionalSummaryList, onClick, timeCategory }) {
  return (
    <div className={`regional-summary-list-wrapper ${className}`}>
      <div>
        {regionalSummaryList?.length > 0 && (
          <ul>
            {regionalSummaryList.map((regionalSummary, inx) => {
              const filteredList = regionalSummary.list.filter(info => {
                const filteredTees = info.tees.filter(d => {
                  return filterByTime(d, timeCategory);
                });

                return filteredTees.length > 0;
              });

              const teeCount = filteredList.reduce((prev, current) => {
                const filteredTees = current.tees.filter(d => {
                  return filterByTime(d, timeCategory);
                });
                return prev + filteredTees.length;
              }, 0);

              const clubCount = filteredList.length;

              return (
                <li
                  key={inx}
                  onClick={() => {
                    if (onClick) {
                      onClick(regionalSummary);
                    }
                  }}
                >
                  {clubCount > 0 && (
                    <div className="count">
                      <span className="number">{clubCount}</span>
                      <span className="text">클럽</span>
                      <span className="wave">/</span>
                      <span className="number">{teeCount}</span>
                      <span className="text">티</span>
                    </div>
                  )}
                  {clubCount < 1 && <div className="count">-</div>}
                  <div className="name">{regionalSummary.name}</div>
                  <div className="map">
                    <div className="rect">
                      <div className="jeju" />
                      <div className={`position region-${regionalSummary.code}`} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

RegionalSummaryList.defaultProps = {
  className: '',
  regionalSummaryList: [],
  onClick: null,
};

RegionalSummaryList.propTypes = {
  className: PropTypes.string,
  regionalSummaryList: RegionalSummaryListPropTypes,
  onClick: PropTypes.func,
  timeCategory: PropTypes.string.isRequired,
};

export default RegionalSummaryList;
