import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import moment from 'moment';
import BoardService from '@/services/BoardService';
import './Board.scss';

const labelMinWidth = '100px';

function Board() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [board, setBoard] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    BoardService.getBoard(id, info => {
      setBoard(info);
    });
  }, []);

  const deleteBoard = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '게시물 데이터 삭제',
      <div>
        <div>
          <span className="g-tag">{board.subject}</span>
        </div>
        <div>게시물 정보를 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        BoardService.deleteBoard(id, () => {
          navigate('/configs/boards');
        });
      },
    );
  };

  return (
    <Page className="board-wrapper">
      <PageTitle className="page-title" links={[<Link to="/configs/boards">게시물 목록</Link>]}>
        {board.subject}
      </PageTitle>
      <PageContent padding>
        <Block>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('제목')}
            </Label>
            <Text>{board.subject}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('내용')}
            </Label>
            <Text whiteSpace="pre-wrap">{board.content}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('마감일')}
            </Label>
            <Text>{board.closingDate && moment(board.closingDate, 'YYYY-MM-DD').format('YYYY년 MM월 DD일')}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('활성화')}
            </Label>
            <Text>{board.enabled ? 'Y' : 'N'}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('공지')}
            </Label>
            <Text>{board.noticeYn ? 'Y' : 'N'}</Text>
          </BlockRow>
        </Block>
      </PageContent>
      <PageButtons
        onDelete={deleteBoard}
        onList={() => {
          navigate('/configs/boards');
        }}
        onEdit={() => {
          navigate(`/configs/boards/${id}/edit`);
        }}
      />
    </Page>
  );
}

export default Board;
