import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ExitButton from '@/components/ExitButton/ExitButton';
import { Link } from 'react-router-dom';
import ClubService from '@/services/ClubService';
import moment from 'moment';
import Button from '@/components/Button/Button';
import './ClubReview.scss';

function ClubReview({ className, setOpen, isOpen, name }) {
  const list = useRef(null);

  const [pageInfo, setPageInfo] = useState({
    total: 0,
    start: 0,
    items: [],
  });

  const getClubReview = clubName => {
    ClubService.selectClubReviewList(clubName, null, d => {
      setPageInfo({
        total: d.total,
        start: d.start,
        items: d.items,
      });

      if (list.current) {
        list.current.scrollTop = 0;
      }
    });
  };

  useEffect(() => {
    if (name) {
      getClubReview(name);
    }
  }, [name]);

  const onChangePaging = start => {
    ClubService.selectClubReviewList(name, start, d => {
      setPageInfo({
        total: d.total,
        start: d.start,
        items: d.items,
      });

      if (list.current) {
        list.current.scrollTop = 0;
      }
    });
  };

  return (
    <div className={`club-review-wrapper ${className} ${isOpen ? 'open' : ''}`}>
      <div>
        <div className="popup-title">
          <div
            className="icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>
              <i className="fal fa-chevron-left" />
            </span>
          </div>
          <div className="text">{name} 리뷰</div>
          <div className='close-button'>
            <ExitButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="popup-content">
          <div ref={list}>
            <ul>
              {pageInfo.items.map((d, inx) => {
                return (
                  <li key={inx}>
                    <div className="title">
                      <Link
                        to={d.link}
                        dangerouslySetInnerHTML={{ __html: d.title }}
                        onClick={e => {
                          e.preventDefault();
                          window.open(d.link);
                        }}
                      />
                    </div>
                    <div className="info">
                      <span className="blogger">{d.bloggername}</span>
                      <span className="date">{moment(d.postdate, 'YYYYMMDD').format('YYYY년 MM월 DD일')}</span>
                    </div>
                    <div className="description" dangerouslySetInnerHTML={{ __html: d.description }} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="paging">
          <Button
            size="sm"
            color="white"
            disabled={pageInfo?.start <= 1}
            outline
            onClick={() => {
              let nextStart = pageInfo.start - 10;
              if (nextStart < 1) {
                nextStart = 1;
              }

              onChangePaging(nextStart);
            }}
          >
            이전
          </Button>
          <Button
            size="sm"
            color="white"
            disabled={pageInfo.total <= pageInfo.start + pageInfo.items.length || pageInfo.items.length < 10}
            outline
            onClick={() => {
              onChangePaging(pageInfo.start + pageInfo.items.length);
            }}
          >
            다음
          </Button>
        </div>
      </div>
    </div>
  );
}

ClubReview.defaultProps = {
  className: '',
  setOpen: null,
  isOpen: false,
};

ClubReview.propTypes = {
  className: PropTypes.string,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default ClubReview;
