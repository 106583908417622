import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '@/hooks/useStores';
import PropTypes from 'prop-types';
import './ScrollCalendar.scss';
import moment from 'moment';
import { getDaysOfWeek } from '@/utils/dateUtil';
import { throttle } from 'lodash';
import { useSearchParams } from 'react-router-dom';

function ScrollCalendar({ className, onMonthClick }) {
  const {
    conditionStore: { date, setDate },
    configStore: { holidays },
    controlStore: { hideHeader },
  } = useStores();

  const [navDate, setNavDate] = useState(moment(date));

  const lastDayElements = useRef({});

  const dateListElements = useRef(null);

  const [params, setParams] = useSearchParams();

  const onChangeDate = val => {
    params.set('day', moment(val).format('YYYY-MM-DD'));
    setParams(params);

    setDate(val);
  };

  const days = useRef(
    (() => {
      const list = [];
      const start = moment().hour(0).minute(0).second(0).millisecond(0);
      const end = moment(start).add(30, 'day').endOf('day');

      const cursor = moment(start);
      while (cursor.isBefore(end)) {
        const lastDay = moment(cursor).endOf('month');
        if (cursor.format('YYYY-MM-DD') === lastDay.format('YYYY-MM-DD')) {
          lastDayElements.current[cursor.format('M')] = {
            date: moment(cursor),
            element: null,
          };
        }

        list.push(moment(cursor));
        cursor.add(1, 'day');
      }

      return list;
    })(),
  );

  const onScroll = e => {
    const monthList = Object.keys(lastDayElements.current);

    for (let i = monthList.length - 1; i >= 0; i -= 1) {
      const item = lastDayElements.current[monthList[i]].element;
      const lastDayLeft = Number(item?.offsetLeft) + Number(item?.offsetWidth);
      if (e.target.scrollLeft > lastDayLeft) {
        if (Number(navDate.format('M')) !== Number(monthList[i]) + 1) {
          setNavDate(moment(navDate).month(Number(monthList[i])));
        }
        return;
      }
    }

    if (Number(navDate.format('M')) !== Number(monthList[0])) {
      setNavDate(moment(navDate).month(Number(monthList[0] - 1)));
    }
  };

  useEffect(() => {
    if (dateListElements.current) {
      const target = document.querySelector(`.scroll-calendar-wrapper .day-selector-list .day-${date.format('YYYY-MM-DD')}`);

      if (target) {
        if (target.offsetLeft < dateListElements.current.scrollLeft || target.offsetLeft + target.offsetWidth > dateListElements.current.scrollLeft + dateListElements.current.offsetWidth) {
          dateListElements.current.scrollLeft = target.offsetLeft;
        }
      }
    }
  }, [date]);

  const onScrollThrottle = React.useMemo(() => throttle(onScroll, 100), [navDate]);

  return (
    <header className={`${className} scroll-calendar-wrapper ${hideHeader ? 'move-up' : ''}`}>
      <div>
        <div className="calendars">
          <div
            className="month"
            onClick={() => {
              onMonthClick(true);
            }}
          >
            <div>
              <span>{navDate.format('M월')}</span>
              <span className="icon">
                <i className="fal fa-angle-down" />
              </span>
            </div>
          </div>
          <div className="days">
            <div>
              <ul className="day-selector-list" onScroll={onScrollThrottle} ref={dateListElements}>
                {days.current.map(day => {
                  const isFirstDayOfMonth = day.isSame(moment(day).startOf('month'));
                  const isHoliday = day.days() === 0 || holidays.includes(day.format('MMDD')) || holidays.includes(day.format('YYYYMMDD'));
                  return (
                    <li
                      key={day}
                      className={`${date.isSame(day) ? 'selected' : ''} day-${day.format('YYYY-MM-DD')}`}
                      onClick={() => {
                        // setDate(day);
                        onChangeDate(day);
                      }}
                      ref={e => {
                        const lastDay = moment(day).endOf('month');
                        if (day.format('YYYY-MM-DD') === lastDay.format('YYYY-MM-DD')) {
                          lastDayElements.current[day.format('M')].element = e;
                        }
                      }}
                    >
                      <div className={`${isHoliday ? 'holiday' : ''} day-${day.days()}`}>
                        <div className="days-of-week">
                          <span>{getDaysOfWeek(day)}</span>
                        </div>
                        <div className="days-of-month">
                          {isFirstDayOfMonth && <span>{day.format('M/D')}</span>}
                          {!isFirstDayOfMonth && <span>{day.format('D')}</span>}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

ScrollCalendar.defaultProps = {
  className: '',
};

ScrollCalendar.propTypes = {
  className: PropTypes.string,
  onMonthClick: PropTypes.func.isRequired,
};

export default observer(ScrollCalendar);
