import { getBaseURL } from '@/utils/configUtil';

function getImageUrl(imagePath) {
  if (process.env.NODE_ENV === 'production') {
    return `${getBaseURL()}/images${encodeURI(imagePath)}`;
  }

  return `${getBaseURL()}/api/clubs/images?path=${encodeURI(imagePath)}`;
}

function dummy() {
  return null;
}

export { getImageUrl, dummy };
