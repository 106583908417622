import React from 'react';
import { observer } from 'mobx-react';
import useStores from '@/hooks/useStores';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import logo from '@/images/gongtee2.svg';
import Button from '@/components/Button/Button';
import { MENUS } from '@/constants/menu';
import './MobileMenu.scss';
import { setOption } from '@/utils/storageUtil';

function MobileMenu({ className }) {
  const {
    userStore: { user, isLogin, setUser },
    controlStore: { mobileMenuOpened, setMobileMenuOpen },
  } = useStores();

  const navigate = useNavigate();

  const logout = e => {
    e.preventDefault();
    e.stopPropagation();
    setMobileMenuOpen(false);
    setOption('user', 'info', 'uuid', '');
    UserService.logout(
      info => {
        setUser({
          ...info,
          id: null,
          uuid: null,
          roleCode: null,
          email: null,
          name: null,
        });

        navigate('/');
      },
      () => {
        setUser({
          id: null,
          uuid: null,
          roleCode: null,
          email: null,
          name: null,
        });

        navigate('/');
      },
    );
  };

  return (
    <div
      className={`mobile-menu-wrapper ${className} ${mobileMenuOpened ? 'open' : ''}`}
      onClick={() => {
        setMobileMenuOpen(false);
      }}
    >
      <div className={mobileMenuOpened ? 'open' : ''} onClick={e => e.stopPropagation()}>
        <div className="menu-top-button">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="gongtee" />
              <div className="gongtee">
                <div>
                  <div className="name">GONGTEE</div>
                  <div className="slogan">퍼블릭 골프장 빈 티를 한번에!</div>
                </div>
              </div>
            </Link>
          </div>
          <Button
            size="sm"
            color="white"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpened);
            }}
          >
            <i className="fal fa-bars" />
          </Button>
        </div>
        <div className="menu-list">
          <ul>
            {MENUS.filter(d => !d.admin)
              .filter(d => d.login === undefined || d.login === isLogin)
              .map((d, inx) => {
                return (
                  <li key={inx}>
                    <Link
                      to={d.to}
                      onClick={() => {
                        setMobileMenuOpen(false);
                      }}
                    >
                      <span className="icon">{d.icon}</span>
                      <span className="text">{d.name}</span>
                      <span>
                        <i className="fal fa-chevron-right" />
                      </span>
                    </Link>
                  </li>
                );
              })}

            {isLogin && (
              <li className="logout">
                <span className="text" onClick={logout}>
                  로그아웃
                </span>
              </li>
            )}
            {user?.roleCode === 'ADMIN' && (
              <>
                <li className="admin-label">
                  <span>ADMIN</span>
                </li>
                {MENUS.filter(d => d.admin)
                  .filter(d => d.login === undefined || d.login === isLogin)
                  .map((d, inx) => {
                    return (
                      <li key={inx}>
                        <Link
                          to={d.to}
                          onClick={() => {
                            setMobileMenuOpen(false);
                          }}
                        >
                          <span className="icon">{d.icon}</span>
                          <span className="text">{d.name}</span>
                          <span>
                            <i className="fal fa-chevron-right" />
                          </span>
                        </Link>
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        </div>
        <div className="bottom-menu">
          <ul>
            {isLogin && (
              <li>
                <Link
                  to="/users/withdrawal"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  회원 탈퇴
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/users/privacy"
                onClick={() => {
                  setMobileMenuOpen(false);
                }}
              >
                개인 정보 처리 방침
              </Link>
            </li>
            <li>
              <Link
                to="/users/terms"
                onClick={() => {
                  setMobileMenuOpen(false);
                }}
              >
                이용 약관
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

MobileMenu.defaultProps = {
  className: '',
};

MobileMenu.propTypes = {
  className: PropTypes.string,
};

export default observer(MobileMenu);
