import React from 'react';
import PropTypes from 'prop-types';
import zzulhub from '@/images/zzulhub.png';
import './index.scss';

function ADBlock({ className }) {
  return (
    <div
      className={`ad-wrapper ${className}`}
      onClick={() => {
        window.open('https://www.zzulhub.com/');
      }}
    >
      <div>
        <div className="image">
          <img src={zzulhub} alt="zzulhub" />
        </div>
        <div className="ad">
          <span>AD</span>
        </div>
        <div className="text">
          <div>
            <div className="line1">실시간 이슈를 모아서, 한번에!</div>
            <div className="line2">세상의 모든 이슈, 쩔헙</div>
          </div>
        </div>
      </div>
    </div>
  );
}

ADBlock.defaultProps = {
  className: '',
};

ADBlock.propTypes = {
  className: PropTypes.string,
};

export default ADBlock;
