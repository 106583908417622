import React from 'react';
import {Button, Title} from '@/components';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

function NotImplemented() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="g-simple-message">
      <div>
        <Title className="text-center" type="h1">
          NOT IMPLEMENTED
        </Title>
        <div className="content">
          <p className="desc">{t('메세지.아직 구현되지 않은 페이지 입니다.')}</p>
        </div>
        <div className="bottom">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('뒤로')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotImplemented;
