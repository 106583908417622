import React, { useEffect, useState } from 'react';
import { Button, Info, Page, PageButtons, PageContent, PageTitle, TextArea } from '@/components';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import CustomerInquiryService from '@/services/CustomerInquiryService';
import { useParams } from 'react-router';
import './CustomerServiceInfo.scss';
import moment from 'moment';
import useStores from '@/hooks/useStores';
import { INQUIRY_TYPES } from '@/constants/constants';

function CustomerServiceInfo() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    userStore: { user, isLogin },
  } = useStores();

  const [customerInquiry, setCustomerInquiry] = useState({});

  const [inquiry, setInquiry] = useState('');

  const [answer, setAnswer] = useState('');

  const getCustomerInquiry = inquiryId => {
    CustomerInquiryService.selectCustomerInquiryInfo(inquiryId, info => {
      setCustomerInquiry(info);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCustomerInquiry(id);
  }, [id]);

  const createAnswer = () => {
    CustomerInquiryService.createCustomerInquiryAnswer(
      id,
      {
        customerInquiryId: id,
        content: answer,
      },
      () => {
        setAnswer('');
        getCustomerInquiry(id);
      },
    );
  };

  const createAdditionalInquiry = () => {
    CustomerInquiryService.createAdditionalCustomerInquiry(
      id,
      {
        customerInquiryId: id,
        content: inquiry,
      },
      () => {
        setInquiry('');
        getCustomerInquiry(id);
      },
    );
  };

  const deleteCustomerInquiry = () => {
    CustomerInquiryService.deleteCustomerInquiry(id, () => {
      navigate(-1);
    });
  };

  return (
    <Page className="customer-service-info-wrapper">
      <PageTitle>1:1 문의 정보</PageTitle>
      <PageContent className="page-content" padding>
        <div className="title">{customerInquiry.subject}</div>
        <div className="other">
          <div className="icon">
            <span>
              <i className="fal fa-street-view" />
            </span>
          </div>
          <div className="user-info">
            <div>
              <div className="name">
                <span className="label">작성자</span>
                <span>{customerInquiry.name}</span>
                <span className="email">{customerInquiry.email}</span>
                {customerInquiry.phone && <span className="phone">{customerInquiry.phone}</span>}
              </div>
              <div className="creation-time">
                <span className="label">작성일</span>
                <span>{moment(customerInquiry.creationDate).format('YYYY년 MM월 DD일')}</span>
              </div>
            </div>
          </div>
        </div>
        {customerInquiry.contents?.map(d => {
          return (
            <div className="content" key={d.id}>
              <div className="inquiry-info">
                <div>
                  <span className={`inquiry-type ${d.type}`}>{INQUIRY_TYPES[d.type]}</span>
                </div>
                <div className="time">{moment(d.creationDate).format('YYYY년 MM월 DD일 HH시 mm분')}</div>
              </div>
              <div className="content-text">{d.content}</div>
            </div>
          );
        })}
        {customerInquiry?.contents?.filter(d => d.type === 'ANSWER').length < 1 && (
          <Info className="info" outline>
            아직 등록된 답변이 없습니다.
          </Info>
        )}
        {user?.roleCode === 'ADMIN' && (
          <div className="content reply">
            <div className="inquiry-info">
              <div>
                <span className="inquiry-type ANSWER">{INQUIRY_TYPES.ANSWER}</span>
              </div>
            </div>
            <div className="content-text">
              <TextArea className="text-area" placeholder="답변을 입력해주세요" value={answer} rows={6} onChange={setAnswer} />
            </div>
            <div className="content-button">
              <Button outline size="md" onClick={createAnswer}>
                등록
              </Button>
            </div>
          </div>
        )}
        {user?.roleCode !== 'ADMIN' && isLogin && (
          <div className="content reply">
            <div className="inquiry-info">
              <div>
                <span className="inquiry-type INQUIRY">{INQUIRY_TYPES.INQUIRY}</span>
              </div>
            </div>
            <div className="content-text">
              <TextArea className="text-area" placeholder="추가 문의 내용을 입력해주세요" value={inquiry} rows={6} onChange={setInquiry} />
            </div>
            <div className="content-button">
              <Button outline size="md" onClick={createAdditionalInquiry}>
                등록
              </Button>
            </div>
          </div>
        )}
        <PageButtons
          className="page-button"
          onDelete={user?.roleCode === 'ADMIN' ? deleteCustomerInquiry : null}
          onBack={() => {
            navigate(-1);
          }}
          onList={() => {
            navigate('/users/cs/my');
          }}
        />
      </PageContent>
    </Page>
  );
}

export default observer(CustomerServiceInfo);
