import React, { useEffect, useState } from 'react';
import { Block, BlockRow, Button, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import BatchService from '@/services/BatchService';

const labelMinWidth = '100px';

function Batch() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [batch, setBatch] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    BatchService.selectJobInfo(id, info => {
      setBatch(info);
    });
  }, []);

  const deleteBatch = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '배치 잡 삭제',
      <div>
        <div>
          <span className="g-tag">{batch.batchJobName}</span>
        </div>
        <div>배치 잡 및 배치 히스토리를 모두 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        BatchService.deleteJobInfo(id, () => {
          navigate('/batches');
        });
      },
    );
  };

  const executeBatch = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.INFO,
      '배치 실행',
      <div>
        <div>
          <span className="g-tag">{batch.batchJobName}</span>
        </div>
        <div>배치를 지금 실행합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        BatchService.executeJobInfo(id, () => {
          dialogUtil.setMessage(MESSAGE_CATEGORY.INFO, '배치 실행', '배치가 실행되었습니다.');
        });
      },
    );
  };

  return (
    <Page className="batch-wrapper">
      <PageTitle className="page-title">{batch.batchJobName}</PageTitle>
      <PageContent padding>
        <Block>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 명')}
            </Label>
            <Text>{batch.batchJobName}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 잡 클래스')}
            </Label>
            <Text whiteSpace="pre-wrap">{batch.batchJobClass}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('배치 잡 패키지')}
            </Label>
            <Text whiteSpace="pre-wrap">{batch.batchJobPackage}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('CRON')}
            </Label>
            <Text whiteSpace="pre-wrap">{batch.cronExpression}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('설명')}
            </Label>
            <Text>{batch.batchJobDescription}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('사용 여부')}
            </Label>
            <Text>{batch.useYn ? 'Y' : 'N'}</Text>
          </BlockRow>
          <BlockRow className="exec">
            <Label className="label" minWidth={labelMinWidth} size="sm">
              관리
            </Label>
            <div>
              <Button outline onClick={executeBatch}>
                배치 실행
              </Button>
            </div>
          </BlockRow>
        </Block>
        <PageButtons
          onDelete={deleteBatch}
          onList={() => {
            navigate('/batches');
          }}
          onEdit={() => {
            navigate(`/batches/${id}/edit`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default Batch;
