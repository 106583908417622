import React, { useEffect, useState } from 'react';
import UserService from '@/services/UserService';
import useStores from '@/hooks/useStores';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import { observer } from 'mobx-react';
import ConfirmDialog from '@/pages/common/ConfirmDialog';
import MessageDialog from '@/pages/common/MessageDialog';
import ErrorDialog from '@/pages/common/ErrorDialog';
import { debounce } from 'lodash';
import GolfBall from '@/components/GolfBall/GolfBall';
import './Common.scss';
import ConfigService from '@/services/ConfigService';
import BoardService from '@/services/BoardService';
import NoticePopup from '@/pages/common/NoticePopup';
import { getOption, setOption } from '@/utils/storageUtil';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

function Common() {
  const {
    userStore,
    configStore: { setHolidays, setVersion },
    controlStore,
  } = useStores();

  const [params] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [notices, setNotices] = useState([]);

  const [openNotice, setNoticeOpen] = useState(false);

  const closeNoticePopup = (value, ignore) => {
    if (ignore) {
      setOption('user', 'notice', 'last', {
        lastId: notices?.length > 0 ? notices[0].id : null,
      });
    }

    setNoticeOpen(value);
  };

  const getUserProfile = () => {
    UserService.getMyInfo(
      info => {
        if (!info.id) {
          let localExcludes = getOption('user', 'info', 'excludes');
          if (!localExcludes) {
            localExcludes = {
              excludes: {},
            };
          }

          userStore.setUser({
            ...info,
            setting: JSON.stringify({
              excludes: localExcludes,
            }),
          });
        } else {
          userStore.setUser(info);
        }
      },
      () => {
        userStore.setTried(true);
        let localExcludes = getOption('user', 'info', 'excludes');
        if (!localExcludes) {
          localExcludes = {
            excludes: {},
          };
        }
        userStore.setUserSetting(localExcludes);
        return true;
      },
    );

    ConfigService.getHolidays(d => {
      const list = [];
      d.forEach(i => {
        list.push(i.date);
      });
      setHolidays(list);
    });

    ConfigService.selectSystemVersion(version => {
      setVersion(version);
    });

    const lastSeen = getOption('user', 'notice', 'last') || {};
    lastSeen.closingDate = moment().format('YYYY-MM-DD');

    BoardService.getNotices(lastSeen, d => {
      setNotices(d);
      if (d?.length > 0) {
        setNoticeOpen(true);
      }
    });
  };

  const getRecommendationCode = () => {
    const recommendation = params.get('recommendation');
    if (recommendation) {
      setOption('user', 'info', 'recommendation', recommendation);
    }
  };

  const setAutoLogin = () => {
    const autoLogin = getOption('user', 'info', 'autoLogin');
    if (autoLogin) {
      UserService.setAutoLogin(info => {
        setOption('user', 'info', 'uuid', info.uuid);
        setOption('user', 'info', 'autoLogin', '');
      });
    }
  };

  useEffect(() => {
    getUserProfile();
    getRecommendationCode();
    setAutoLogin();
  }, []);

  const setLoadingDebounce = React.useMemo(
    () =>
      debounce(val => {
        setLoading(val);
      }, 200),
    [setLoading],
  );

  useEffect(() => {
    if (controlStore.requestLoading) {
      setLoading(true);
    } else {
      setLoadingDebounce(false);
    }
  }, [controlStore.requestLoading]);

  return (
    <div>
      {!loading && controlStore.confirm?.message && (
        <ConfirmDialog
          category={controlStore.confirm?.category || ''}
          title={controlStore.confirm?.title || ''}
          message={controlStore.confirm?.message || ''}
          okHandler={controlStore.confirm?.okHandler}
          noHandler={controlStore.confirm?.noHandler}
          okText={controlStore.confirm?.okText || ''}
          noText={controlStore.confirm?.noText || ''}
        />
      )}
      {!loading && controlStore.message?.message && (
        <MessageDialog
          category={controlStore.message?.category || ''}
          title={controlStore.message?.title || ''}
          message={controlStore.message?.message || ''}
          okHandler={controlStore.message?.okHandler}
          okText={controlStore.message?.okText || ''}
        />
      )}
      {!loading && controlStore.error?.message && <ErrorDialog category={MESSAGE_CATEGORY.ERROR} title={controlStore.error?.code || ''} message={controlStore.error?.message || ''} />}
      {loading && (
        <div className="request-loading">
          <div>
            <GolfBall />
          </div>
        </div>
      )}
      {openNotice && notices?.length > 0 && <NoticePopup notices={notices} setOpen={closeNoticePopup} />}
    </div>
  );
}

export default observer(Common);
