import React from 'react';
import PropTypes from 'prop-types';
import './ExitButton.scss';

function ExitButton({ className, size, color, onClick }) {
  return (
    <div
      className={`${className} color-${color} exit-button-wrapper`}
      style={{
        width: size,
        height: size,
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div
        className="x-1"
        style={{
          borderColor: color,
        }}
      />
      <div
        className="x-2"
        style={{
          borderColor: color,
        }}
      />
    </div>
  );
}

ExitButton.defaultProps = {
  className: '',
  size: '14px',
  color: 'black',
  onClick: null,
};

ExitButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default ExitButton;
