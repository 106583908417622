import React from 'react';
import './Footer.scss';
import useStores from '@/hooks/useStores';
import logo from '@/images/gongtee2.svg';
import Liner from '@/components/Liner/Liner';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import UserService from '@/services/UserService';
import PropTypes from 'prop-types';

function Footer({ className }) {
  const {
    configStore: { version },
    userStore: { isLogin },
  } = useStores();

  return (
    <div className={`footer-wrapper ${className}`}>
      <div className="max-content">
        <div className="logo">
          <img src={logo} alt="gongtee" />
        </div>
        <div className="version">
          <div className="product">공티(GONGTEE)</div>
          <div className="app">
            {process.env.REACT_APP_NAME}-{process.env.REACT_APP_VERSION}
          </div>
          <div className="api">
            {version?.name}-{version?.version}
          </div>
          <div className="others">
            <Link
              to="/"
              onClick={e => {
                e.preventDefault();
                localStorage.clear();
                if (isLogin) {
                  UserService.updateMySetting(
                    { excludes: {} },
                    () => {
                      window.location.reload();
                    },
                    () => {
                      window.location.reload();
                    },
                  );
                }
              }}
            >
              설정 초기화
            </Link>
            <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
            <Link to="/users/privacy">개인정보처리방침</Link>
            <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
            <Link to="/users/terms">이용약관</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default observer(Footer);
