import * as request from '@/utils/request';

const NaverMapService = {};

NaverMapService.getReverseGeocoding = (latitude, longitude, successHandler, failHandler) => {
  return request.get(
    `/api/common/geolocations/address?latitude=${latitude}&longitude=${longitude}`,
    null,
    res => {
      successHandler(res);
    },
    failHandler,
  );
};

export default NaverMapService;
