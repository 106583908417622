import React from 'react';
import PropTypes from 'prop-types';
import { BatchHistoryInfoPropTypes } from '@/proptypes';
import './BatchHistoryList.scss';
import moment from 'moment';
import Liner from '@/components/Liner/Liner';

function BatchHistoryList({ className, batchHistories, onClick }) {
  return (
    <div className={`batch-history-list-wrapper ${className}`}>
      <div>
        {batchHistories?.length > 0 && (
          <ul>
            {batchHistories.map((batchHistory, inx) => {
              return (
                <li
                  key={inx}
                  onClick={() => {
                    if (onClick) {
                      onClick(batchHistory.id);
                    }
                  }}
                >
                  <div className="info">
                    <div className={`status ${batchHistory.batchStatus}`}>{batchHistory.batchStatus}</div>
                    <div className="duration">
                      <span className="start-time">{moment(batchHistory.batchStartTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                      <span className="wave">-</span>
                      <span className="end-time">{moment(batchHistory.batchEndTime).format('HH:mm:ss')}</span>
                      <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                      <span>{batchHistory.duration}s</span>
                    </div>
                  </div>
                  <div className="count">
                    <div>
                      <span>{batchHistory.batchCount}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

BatchHistoryList.defaultProps = {
  className: '',
  batchHistories: [],
  onClick: null,
};

BatchHistoryList.propTypes = {
  className: PropTypes.string,
  batchHistories: PropTypes.arrayOf(BatchHistoryInfoPropTypes),
  onClick: PropTypes.func,
};

export default BatchHistoryList;
