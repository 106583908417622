import React, { useEffect, useState } from 'react';
import { EmptyContent, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './CSList.scss';
import CustomerInquiryService from '@/services/CustomerInquiryService';

function Boards() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [customerInquiries, setCustomerInquiries] = useState([]);

  useEffect(() => {
    CustomerInquiryService.selectAllCustomerInquiryList(d => {
      setCustomerInquiries(d);
    });
  }, []);

  return (
    <Page className="cs-list-wrapper">
      <PageTitle className="page-title">{t('문의 목록')}</PageTitle>
      <PageContent padding>
        {customerInquiries?.length < 1 && <EmptyContent>문의 이력이 없습니다.</EmptyContent>}
        {customerInquiries?.length > 0 && (
          <ul>
            {customerInquiries.map(customerInquiry => {
              return (
                <li key={customerInquiry.id}>
                  <div className="subject">
                    <Link to={`/configs/cs/${customerInquiry.id}`}>{customerInquiry.subject}</Link>
                  </div>
                  <div className="info">
                    <span className={customerInquiry.answered ? 'answered' : ''}>{customerInquiry.answered ? '답변 완료' : '미답변'}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        <PageButtons
          onList={() => {
            navigate('/');
          }}
          onListText="메인"
          onCancel={() => {
            navigate(-1);
          }}
          onCancelText="뒤로"
        />
      </PageContent>
    </Page>
  );
}

export default Boards;
