import React from 'react';
import PropTypes from 'prop-types';
import { BatchInfoPropTypes } from '@/proptypes';
import './BatchJobList.scss';
import Liner from '@/components/Liner/Liner';
import { useNavigate } from 'react-router-dom';

function BatchJobList({ className, batches, onClick }) {
  const navigate = useNavigate();

  return (
    <div className={`batch-job-list-wrapper ${className}`}>
      <div>
        {batches?.length > 0 && (
          <ul>
            {batches
              .sort((a, b) => a.batchJobName.localeCompare(b.batchJobName))
              .map((batch, inx) => {
                return (
                  <li
                    key={inx}
                    onClick={() => {
                      if (onClick) {
                        onClick(batch.id);
                      }
                    }}
                  >
                    <div className="info">
                      <div className="name">{batch.batchJobName}</div>
                      <div className="class-name">{batch.batchJobClass}</div>
                    </div>
                    <div className="cron">
                      <div className="expression">{batch.cronExpression}</div>
                      <div>
                        <span className={`status ${batch.useYn ? 'active' : 'disabled'}`}>{batch.useYn ? 'active' : 'disabled'}</span>
                      </div>
                    </div>
                    <div className="separator">
                      <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                    </div>
                    <div className="history">
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          navigate(`/batches/${batch.id}/histories`);
                        }}
                      >
                        이력 조회
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
}

BatchJobList.defaultProps = {
  className: '',
  batches: [],
  onClick: null,
};

BatchJobList.propTypes = {
  className: PropTypes.string,
  batches: PropTypes.arrayOf(BatchInfoPropTypes),
  onClick: PropTypes.func,
};

export default BatchJobList;
