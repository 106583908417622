import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button/Button';
import './ImageUploader.scss';

class ImageUploader extends React.Component {
  fileInput = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  onDragOver = e => {
    const { dragging } = this.state;
    e.stopPropagation();
    e.preventDefault();

    if (!dragging) {
      this.setState({
        dragging: true,
      });
    }
  };

  onDragLeave = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      dragging: false,
    });
  };

  onDrop = e => {
    e.stopPropagation();
    e.preventDefault();

    const { onChangeImage } = this.props;
    if (e.dataTransfer.files.length > 0) {
      onChangeImage(e.dataTransfer.files[0], e);
    }

    this.setState({
      dragging: false,
    });
  };

  render() {
    const { className, onChangeImage } = this.props;
    const { dragging } = this.state;

    return (
      <div className={`image-upload-wrapper ${className}`}>
        <div>
          <div className={`image-selector ${dragging ? 'dragging' : ''}`} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} onDrop={this.onDrop}>
            <div>
              <input
                ref={this.fileInput}
                type="file"
                className="file-input"
                onChange={(e) => {
                  if (this.fileInput.current.files.length > 0) {
                    onChangeImage(this.fileInput.current.files[0], e);
                  }
                }}
              />
              <Button
                size="sm"
                color="white"
                outline
                onClick={() => {
                  this.fileInput.current.click();
                }}
              >
                파일 선택
              </Button>
              <div className="message">버튼을 클릭하여 이미지 파일을 선택하거나, 파일을 드래그해주세요</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImageUploader.defaultProps = {
  className: '',
  onChangeImage: null,
};

ImageUploader.propTypes = {
  className: PropTypes.string,
  onChangeImage: PropTypes.func,
};

export default ImageUploader;
