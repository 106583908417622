import React, { useEffect } from 'react';
import { Page, PageButtons, PageContent, PageTitle } from '@/components';
import { Link, useNavigate } from 'react-router-dom';
import './UserGradeIntro.scss';

function UserGradeIntro() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page className="user-grade-intro-wrapper">
      <PageTitle className="page-title">공티 회원 등급 안내</PageTitle>
      <PageContent className="page-content" padding>
        <p className="first">
          공티는 현재 지속적으로 기능을 업데이트하고 있습니다. 이를 바탕으로 골프가 취미인 모든 사람들이 쉽게 퍼블릭 골프장의 예약 정보를 확인하고, 더불어 골프와 관련된 다양한 정보를 제공하고자 합니다.
        </p>
        <p>
          이를 위해 공티에 더 많은 사용자가 참여할 수 있도록, 회원 등급에 따라 조회되는 클럽 데이터의 업데이트 주기를 다르게 가져가는 정책을 사용하고 있습니다. (데이터 갱신 시기의 차이가 있을뿐 보여지는
          데이터는 모든 회원에게 동일하게 제공됩니다.)
        </p>
        <p>공티의 회원 등급 및 조건은 아래와 같습니다.</p>
        <ul className="info">
          <li>
            <div className="grade-info">미가입 유저</div>
            <div className="condition">
              <span className="tag">조건</span>
              <span className="text">회원 가입이 되지 않았거나, 로그인 하지 않은 회원</span>
            </div>
            <div className="description">
              <span className="tag">설명</span>
              <span className="text">예약 가능한 티 정보를 최대 10분 지연된 데이터로 확인할 수 있습니다.</span>
            </div>
          </li>
          <li>
            <div className="grade-info">실버 회원</div>
            <div className="condition">
              <span className="tag">조건</span>
              <span className="text">
                공티 <Link to="/users/join">회원 가입</Link> 후, <Link to="/users/login">로그인</Link> 상태의 사용자
              </span>
            </div>
            <div className="description">
              <span className="tag">설명</span>
              <span className="text">예약 가능한 티 정보를 최대 5분 지연된 데이터로 확인할 수 있습니다.</span>
            </div>
          </li>
          <li>
            <div className="grade-info">골드 회원</div>
            <div className="condition">
              <span className="tag">조건</span>
              <span className="text">
                <ul>
                  <li>
                    공티 <Link to="/users/join">회원 가입</Link> 후 30일이 지나고, 15회 이상(1일 1회) <Link to="/users/login">로그인</Link> 한 회원
                  </li>
                  <li>
                    <div>또는 다른 회원으로부터 추천인으로 3회 이상 등록된 회원</div>
                    <div className="warning">(비정상적인 추천인을 통한 등업은 회원 자격이 정지될 수 있습니다.)</div>
                  </li>
                </ul>
              </span>
            </div>
            <div className="description">
              <span className="tag">설명</span>
              <span className="text">예약 가능한 티 정보를 실시간 (최대 1분 지연) 데이터로 확인할 수 있습니다.</span>
            </div>
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th className="grade">회원 등급</th>
              <th className="condition">등급 조건</th>
              <th className="description">설명</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>미가입 유저</td>
              <td>회원 가입이 되지 않았거나, 로그인 하지 않은 회원</td>
              <td>
                <ul>
                  <li>예약 가능한 티 정보를 최대 10분 지연된 데이터로 확인할 수 있습니다.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>실버 회원</td>
              <td>
                <ul>
                  <li>
                    공티 <Link to="/users/join">회원 가입</Link> 후, <Link to="/users/login">로그인</Link> 상태의 사용자
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>예약 가능한 티 정보를 최대 5분 지연된 데이터로 확인할 수 있습니다.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>골드 회원</td>
              <td>
                <ul>
                  <li>
                    공티 <Link to="/users/join">회원 가입</Link> 후 30일이 지나고, 15회 이상(1일 1회) <Link to="/users/login">로그인</Link> 한 회원
                  </li>
                  <li>
                    <div>또는 다른 회원으로부터 추천인으로 3회 이상 등록된 회원</div>
                    <div className="warning">(비정상적인 추천인을 통한 등업은 회원 자격이 정지될 수 있습니다.)</div>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>예약 가능한 티 정보를 실시간 (최대 1분 지연) 데이터로 확인할 수 있습니다.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          회원 등급 및 공티에 관련된 어떠한 문의나 불편 사항 등은 <Link to="/users/cs/new">1:1 문의</Link>를 통해 알려주시면 성실하게 답변 드리겠습니다.{' '}
        </p>
        <PageButtons
          onList={() => {
            navigate('/');
          }}
          onListText="메인"
          onCancel={() => {
            navigate(-1);
          }}
          onCancelText="뒤로"
        />
      </PageContent>
    </Page>
  );
}

export default UserGradeIntro;
