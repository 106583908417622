import React, { useEffect, useState } from 'react';
import ClubService from '@/services/ClubService';
import { Block, BlockRow, Img, Label, Page, PageButtons, PageContent, PageTitle, Text } from '@/components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import './Club.scss';
import dialogUtil from '@/utils/dialogUtil';
import { MESSAGE_CATEGORY } from '@/constants/constants';
import { getImageUrl } from '@/utils/commonUtil';
import ConfigService from '@/services/ConfigService';
import moment from 'moment';

const labelMinWidth = '100px';

function Club() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [club, setClub] = useState([]);
  const [weatherCodeList, setWeatherCodeList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ConfigService.selectCodeList('WEATHER_LOCATION', list => {
      setWeatherCodeList(list);
    });

    ClubService.selectClubInfo(id, info => {
      setClub(info);
    });
  }, []);

  const deleteClub = () => {
    dialogUtil.setConfirm(
      MESSAGE_CATEGORY.WARNING,
      '클럽 데이터 삭제',
      <div>
        <div>
          <span className="g-tag">{club.name}</span>
        </div>
        <div>클럽 정보 및 모든 관련 정보를 삭제합니다.</div>
        <div>계속 하시겠습니까?</div>
      </div>,
      () => {
        ClubService.deleteClub(id, () => {
          navigate('/clubs');
        });
      },
      () => {},
    );
  };

  return (
    <Page className="club-wrapper">
      <PageTitle className="page-title" links={[<Link to="/clubs">클럽 목록</Link>]}>
        {club.name}
      </PageTitle>
      <PageContent padding>
        <Block>
          {!club.imagePath && (
            <BlockRow className="club-icon">
              <div>
                <div className="icon">
                  <i className="fal fa-camera" />
                </div>
                <div>NO IMAGE</div>
              </div>
            </BlockRow>
          )}
          {club.imagePath && (
            <BlockRow className="club-image">
              <div>
                <Img src={getImageUrl(club.imagePath)} alt={club.name} />
              </div>
            </BlockRow>
          )}
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('클럽 이름')}
            </Label>
            <Text>{club.name}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('주소')}
            </Label>
            <Text>{club.location}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('위도')}
            </Label>
            <Text>{club.latitude}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('경도')}
            </Label>
            <Text>{club.longitude}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('지역')}
            </Label>
            <Text>{club.locationShortName}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('평점')}
            </Label>
            <Text>{club.rating}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('네이버 플레이스 ID')}
            </Label>
            <Text>{club.naverPlaceId}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('홈페이지')}
            </Label>
            <Text>{club.homepageUrl}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('예약 페이지')}
            </Label>
            <Text>{club.reservationUrl}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('토큰')}
            </Label>
            <Text>{club.token}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('최저 가격')}
            </Label>
            <Text>{club.minCost}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('최대 가격')}
            </Label>
            <Text>{club.maxCost}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('스캔 서비스 키')}
            </Label>
            <Text>{club.scanServiceKey}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('허가일자')}
            </Label>
            <Text>{club.permissionDate}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('날씨 지역 코드')}
            </Label>
            <Text>{club.weatherCode ? (weatherCodeList.find(d => d.code === club.weatherCode) || {}).value : ''}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('활성화')}
            </Label>
            <Text>{club.enabled ? 'Y' : 'N'}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('조회 중지')}
            </Label>
            <Text>{club.paused ? <span className="warn">PAUSED</span> : ''}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('연속 중지 카운트')}
            </Label>
            <Text>
              <span className="warn">{club.pausedCount}</span>
            </Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('마지막 중지 시간')}
            </Label>
            <Text>
              <span className="warn">{club.lastPauseDate ? moment(club.lastPauseDate).format('YYYY년 MM월 DD일 HH시 mm분') : ''}</span>
            </Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('타임아웃')}
            </Label>
            <Text>{club.connectionTimeOut}</Text>
          </BlockRow>
          <BlockRow>
            <Label className="label" minWidth={labelMinWidth} size="sm">
              {t('노트')}
            </Label>
            <Text whiteSpace="pre-wrap">{club.note}</Text>
          </BlockRow>
        </Block>
        <PageButtons
          onDelete={deleteClub}
          onList={() => {
            navigate('/clubs');
          }}
          onEdit={() => {
            navigate(`/clubs/${id}/edit`);
          }}
        />
      </PageContent>
    </Page>
  );
}

export default Club;
