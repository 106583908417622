import Kakao from '@/vendor/kakao.min';

const clientIds = {
  google: '',
  naver: 'ecR3gqnQnEopGx9DdEZX',
  facebook: '',
  kakao: 'f9a5bcdd377c0db3d8f53858a14a3aba',
};

function socialLogin(vendor) {
  const redirectUri = window.location.port === '3000' ? `https://${window.location.hostname}:8080/api/oauth/${vendor}/token` : `https://gongtee.com/api/oauth/${vendor}/token`;

  switch (vendor) {
    case 'kakao':
      if (Kakao.isInitialized()) {
        Kakao.Auth.authorize({
          redirectUri,
          scope: 'account_email',
        });
      }

      break;

    case 'google': {
      window.location.href = '';
      break;
    }

    case 'naver': {
      window.location.href = `https://nid.naver.com/oauth2.0/authorize?client_id=${clientIds[vendor]}&redirect_uri=${redirectUri}&response_type=code`;
      break;
    }

    case 'facebook': {
      window.location.href = '';
      break;
    }

    default:
      break;
  }
}

function isIOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

function getClientId(vendor) {
  return clientIds[vendor];
}

const login = {
  socialLogin,
  isIOS,
  getClientId,
};

export default login;
