import * as request from '@/utils/request';

const TeeService = {};

TeeService.selectTeeList = (date, successHandler, failHandler) => {
  return request.get(
    '/api/tees',
    { date },
    res => {
      successHandler(res);
    },
    failHandler,
    null,
    null,
    false,
  );
};

TeeService.selectDailyTeeList = (start, end, successHandler, failHandler) => {
  return request.get(
    '/api/tees/daily',
    { start, end },
    res => {
      successHandler(res);
    },
    failHandler,
    null,
    null,
    true,
  );
};

export default TeeService;
