import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ReactGa from 'react-ga';

function RouteChangeTracker() {
  const location = useLocation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      ReactGa.pageview(location.pathname + location.search);
    }
  }, [init, location]);
}

export default RouteChangeTracker;
