import React, { useEffect, useState } from 'react';

import { Block, BlockRow, Form, Input, Label, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import ConfigService from '@/services/ConfigService';

const labelMinWidth = '100px';

function EditCode({ type }) {
  const { t } = useTranslation();
  const { code, type: codeType } = useParams();

  const navigate = useNavigate();

  const [codeInfo, setCodeInfo] = useState({
    type: '',
    code: '',
    label: '',
    value: '',
    description: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (code && codeType && type === 'edit') {
      ConfigService.selectCodeInfo(codeType, code, info => {
        setCodeInfo(info);
      });
    }
  }, [codeType, code]);

  const onSubmit = e => {
    e.preventDefault();

    if (type === 'new') {
      ConfigService.createCodeInfo(codeInfo, () => {
        navigate('/configs/system');
      });
    } else if (type === 'edit') {
      ConfigService.updateCodeInfo(codeType, code, codeInfo, () => {
        navigate('/configs/system');
      });
    }
  };

  return (
    <Page className="edit-holiday-wrapper">
      <PageTitle>{type === 'new' ? t('새 코드') : t('코드 변경')}</PageTitle>
      <PageContent padding>
        <Form onSubmit={onSubmit}>
          <Block className="block">
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth} required>
                {t('타입')}
              </Label>
              <Input
                type="text"
                size="md"
                value={codeInfo.type}
                onChange={val =>
                  setCodeInfo({
                    ...codeInfo,
                    type: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth} required>
                {t('코드')}
              </Label>
              <Input
                type="text"
                size="md"
                value={codeInfo.code}
                onChange={val =>
                  setCodeInfo({
                    ...codeInfo,
                    code: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
            <BlockRow>
              <Label size="sm" minWidth={labelMinWidth} required>
                {t('라벨')}
              </Label>
              <Input
                type="text"
                size="md"
                value={codeInfo.label}
                onChange={val =>
                  setCodeInfo({
                    ...codeInfo,
                    label: val,
                  })
                }
                required
                minLength={1}
              />
            </BlockRow>
          </Block>
          <BlockRow>
            <Label size="sm" minWidth={labelMinWidth} required>
              {t('값')}
            </Label>
            <Input
              type="text"
              size="md"
              value={codeInfo.value}
              onChange={val =>
                setCodeInfo({
                  ...codeInfo,
                  value: val,
                })
              }
              required
              minLength={1}
            />
          </BlockRow>
          <BlockRow>
            <Label size="sm" minWidth={labelMinWidth}>
              {t('설명')}
            </Label>
            <Input
              type="text"
              size="md"
              value={codeInfo.description}
              onChange={val =>
                setCodeInfo({
                  ...codeInfo,
                  description: val,
                })
              }
            />
          </BlockRow>
          <PageButtons
            onCancel={() => {
              navigate('/configs/system');
            }}
            onSubmit={() => {}}
            onSubmitText="저장"
            onCancelIcon=""
          />
        </Form>
      </PageContent>
    </Page>
  );
}

EditCode.defaultProps = {
  type: 'new',
};

EditCode.propTypes = {
  type: PropTypes.string,
};

export default EditCode;
