import React from 'react';
import PropTypes from 'prop-types';
import './Info.scss';

function Info({ className, color, children, icon, outline, size }) {
  return (
    <div className={`info-wrapper ${className} color-${color} size-${size} ${outline ? 'outline' : ''}`}>
      {icon && <div className="info-icon">{icon}</div>}
      <div className="info-text">{children}</div>
    </div>
  );
}

Info.defaultProps = {
  className: '',
  color: 'white',
  children: '',
  icon: <i className="fal fa-info-circle" />,
  outline: false,
  size: 'md',
};

Info.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  outline: PropTypes.bool,
  size: PropTypes.string,
};

export default Info;
