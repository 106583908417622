import React, { useEffect } from 'react';
import { Info, Page, PageButtons, PageContent, PageTitle } from '@/components';
import { observer } from 'mobx-react';
import useStores from '@/hooks/useStores';
import { useNavigate } from 'react-router-dom';
import './CustomerService.scss';

function CustomerService() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userStore: { isLogin },
  } = useStores();

  return (
    <Page className="customer-service-wrapper" fill>
      <PageTitle>고객 센터</PageTitle>
      <PageContent className="page-content" padding>
        {isLogin && <Info>공티와 관련된 좋은 아이디어 혹은 궁금하신 내용을 문의하실 수 있습니다.</Info>}
        {!isLogin && <Info>로그인을 하시면, 지난 문의 내역을 관리하고, 답변 내용을 고객 센터를 통해 확인할 수 있습니다.</Info>}
        <ul>
          <li
            onClick={() => {
              navigate('/users/cs/new');
            }}
            className="enabled"
          >
            <div className="icon">
              <span>
                <i className="far fa-people-arrows" />
              </span>
            </div>
            <div className="line">
              <div />
            </div>
            <div className="text">1:1 문의</div>
          </li>
          <li
            className={`${isLogin ? 'enabled' : 'disabled'}`}
            onClick={() => {
              if (isLogin) {
                navigate('/users/cs/my');
              }
            }}
          >
            <div className="icon">
              <span>
                <i className="far fa-list-alt" />
              </span>
            </div>
            <div className="line">
              <div />
            </div>
            <div className="text">문의 내역</div>
          </li>
        </ul>
        <PageButtons
          onBack={() => {
            navigate(-1);
          }}
        />
      </PageContent>
    </Page>
  );
}

CustomerService.defaultProps = {};

CustomerService.propTypes = {};

export default observer(CustomerService);
