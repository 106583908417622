import moment from 'moment';

function filterByTime(d, timeCategory) {
  if (timeCategory === 'dawn') {
    return moment(d.teeOffTime, 'HH:mm:ss').isBefore(moment('07:00:00', 'HH:mm:ss'));
  }

  if (timeCategory === 'am') {
    return moment(d.teeOffTime, 'HH:mm:ss').isSameOrAfter(moment('07:00:00', 'HH:mm:ss')) && moment(d.teeOffTime, 'HH:mm:ss').isBefore(moment('12:00:00', 'HH:mm:ss'));
  }

  if (timeCategory === 'pm') {
    return moment(d.teeOffTime, 'HH:mm:ss').isSameOrAfter(moment('12:00:00', 'HH:mm:ss')) && moment(d.teeOffTime, 'HH:mm:ss').isBefore(moment('17:00:00', 'HH:mm:ss'));
  }

  if (timeCategory === 'night') {
    return moment(d.teeOffTime, 'HH:mm:ss').isSameOrAfter(moment('17:00:00', 'HH:mm:ss'));
  }

  return true;
}

function dummy() {
  return null;
}

export { filterByTime, dummy };
