import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '@/hooks/useStores';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserService from '@/services/UserService';
import logo from '@/images/gongtee2.svg';
import { Button, Liner } from '@/components';
import { MENUS } from '@/constants/menu';
import './Header.scss';
import { setOption } from '@/utils/storageUtil';

function Header({ className }) {
  const {
    userStore: { user, isLogin, setUser, grade },
    controlStore: { hideHeader, mobileMenuOpened, setMobileMenuOpen },
  } = useStores();

  const navigate = useNavigate();

  const location = useLocation();

  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const [adminMenuOpen, setAdminMenuOpen] = useState(false);

  const logout = e => {
    e.preventDefault();
    e.stopPropagation();
    setMobileMenuOpen(false);
    setUserMenuOpen(false);
    setOption('user', 'info', 'uuid', '');
    UserService.logout(
      info => {
        setUser({
          ...info,
          id: null,
          uuid: null,
          roleCode: null,
          email: null,
          name: null,
        });

        navigate('/');
      },
      () => {
        setUser({
          id: null,
          uuid: null,
          roleCode: null,
          email: null,
          name: null,
        });

        navigate('/');
      },
    );
  };

  return (
    <header className={`${className} header-wrapper ${hideHeader ? 'hide' : ''} ${location.pathname === '/' ? 'is-main' : ''}`}>
      <div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="gongtee" />
            <div className="gongtee">
              <div>
                <div className="name">GONGTEE</div>
                <div className="slogan">퍼블릭 골프장 빈 티를 한번에!</div>
              </div>
            </div>
          </Link>
        </div>
        <div className="reserved" />
        <div className="top-menu">
          <div>
            <ul>
              {MENUS.filter(d => d.pc)
                .filter(d => !d.admin)
                .filter(d => d.login === undefined || d.login === isLogin)
                .map((d, inx) => {
                  return (
                    <React.Fragment key={inx}>
                      <li className={location.pathname === d.to ? 'selected' : ''}>
                        <Link
                          to={d.to}
                          onClick={() => {
                            setMobileMenuOpen(false);
                          }}
                        >
                          <div>
                            <div className="icon">
                              <span>{d.icon}</span>
                            </div>
                            <div className="text">{d.name}</div>
                          </div>
                        </Link>
                        <div className="cursor">
                          <div />
                        </div>
                      </li>
                      <li className="separator">
                        <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                      </li>
                    </React.Fragment>
                  );
                })}
            </ul>
            {user?.roleCode === 'ADMIN' && (
              <div className="admin-label">
                <span>ADMIN</span>
              </div>
            )}
            {user?.roleCode === 'ADMIN' && (
              <div
                className="open-admin-menu"
                onClick={() => {
                  setAdminMenuOpen(!adminMenuOpen);
                }}
              >
                <div>
                  <i className="fal fa-chevron-down" />
                </div>
              </div>
            )}
            {user?.roleCode === 'ADMIN' && (
              <ul className={`admin-menu-list ${adminMenuOpen ? 'open' : ''}`}>
                {MENUS.filter(d => d.pc)
                  .filter(d => d.admin)
                  .filter(d => d.login === undefined || d.login === isLogin)
                  .map((d, inx) => {
                    return (
                      <React.Fragment key={inx}>
                        <li className={location.pathname === d.to ? 'selected' : ''}>
                          <Link
                            to={d.to}
                            onClick={() => {
                              setMobileMenuOpen(false);
                            }}
                          >
                            <div>
                              <div className="icon">
                                <span>{d.icon}</span>
                              </div>
                              <div className="text">{d.name}</div>
                            </div>
                          </Link>
                          <div className="cursor">
                            <div />
                          </div>
                        </li>
                        <li className="separator">
                          <Liner display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
                        </li>
                      </React.Fragment>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
        <div className="right-buttons">
          <div className="short-cuts-button">
            {isLogin && (
              <div className="grade-info">
                {grade && grade === 'SILVER' && (
                  <div className="user-grade SILVER">
                    <Link to="/users/grade">
                      <span>실버 회원</span>
                    </Link>
                  </div>
                )}
                {grade && grade === 'GOLD' && (
                  <div className="user-grade GOLD">
                    <Link to="/users/grade">
                      <span>골드 회원</span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {isLogin && (
              <Link
                className="login-icon"
                to="/users/my"
                onClick={e => {
                  e.preventDefault();
                  setUserMenuOpen(true);
                }}
              >
                <i className="fal fa-meh-blank" />
              </Link>
            )}
            {!isLogin && (
              <>
                <Link to="/users/login">로그인</Link>
                <Liner className="login-separator" display="inline-block" width="1px" height="10px" color="light" margin="0 0.5rem" />
              </>
            )}
          </div>
        </div>
        <div className="menu-button">
          <Button
            size="sm"
            color="white"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpened);
            }}
          >
            <i className="fal fa-bars" />
          </Button>
        </div>

        {userMenuOpen && (
          <div
            className="my-info-menu"
            onClick={() => {
              setUserMenuOpen(false);
            }}
          >
            <div>
              <div>
                <div className="arrow">
                  <div />
                </div>
                <ul>
                  <li>
                    <Link
                      to="/users/my"
                      onClick={e => {
                        e.stopPropagation();
                        setUserMenuOpen(false);
                      }}
                    >
                      내 정보
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={logout}>
                      로그아웃
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

Header.defaultProps = {
  className: '',
};

Header.propTypes = {
  className: PropTypes.string,
};

export default observer(Header);
