import React from 'react';
import PropTypes from 'prop-types';
import './GolfBall.scss';

function GolfBall({ className }) {
  return (
    <div className={`golf-ball-wrapper ${className}`}>
      <div className="dot dot-1" />
      <div className="dot dot-2" />
      <div className="dot dot-3" />
    </div>
  );
}

GolfBall.defaultProps = {
  className: '',
};

GolfBall.propTypes = {
  className: PropTypes.string,
};

export default GolfBall;
